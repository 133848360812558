import styled, { css } from 'styled-components/macro';

import theme from 'app/theme';

export const StyledCheckbox = styled.div<{
  $checked?: boolean;
  $disabled?: boolean;
  $onlyLabelAndInput?: boolean;
}>`
  & input {
    display: none;
  }

  input:checked + label::after {
    background-color: ${theme.color.primary};
    border-color: ${theme.color.primary};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 15 16'%3E%3Cpath fill='white' d='M6.607 10.514L12.22 3.5l.781.625-6.388 7.985-3.744-4.63.777-.629 2.962 3.663h-.001z'/%3E%3C/svg%3E");
    background-size: cover;
    border: none;
  }

  label::after {
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    border: 2px solid ${theme.color.black};
    border-radius: 2px;
    position: absolute;
    right: 1rem;
    top: 18px;

    ${(props) =>
      props.$disabled &&
      css`
        display: none;
      `}
  }

  label {
    ${(props) =>
      !props.$onlyLabelAndInput &&
      css`
        background: ${props.$checked ? theme.color.primary5 : theme.color.gray};
        border: ${props.$checked
          ? `1.5px solid ${theme.color.primary}`
          : `1.5px solid ${theme.color.white}`};
        border-radius: 12px;
        padding: 0.5rem 1rem;
      `}

    height: 56px;
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  width: 100%;
  display: flex;
  justify-content: space-between;
`;
