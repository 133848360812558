import { useMemo } from 'react';
import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import useDateRangeSchema from 'core/hooks/useDateRangeSchema';
import useSettingsSchema from 'planning/components/SettingsTab/useSettingsSchema';

const tabMeta = getTabMeta<'detail' | 'settings'>();

const useEventSchema = () => {
  const dateRangeSchema = useDateRangeSchema();
  const settingsSchema = useSettingsSchema('settings');

  return useMemo(
    () =>
      Yup.object({
        assigneesId: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        name: Yup.string().nullable().max(200).required().meta(tabMeta('detail')).default(null),
        description: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        federationsName: Yup.string().nullable().meta(tabMeta('detail')).default(null),
        federationsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        sportsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        testAuthority: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        resultAuthority: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
        sampleAuthoritiesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        initiatorAuthoritiesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        externalId: Yup.string().nullable().max(50).meta(tabMeta('detail')).default(null),
        dateRange: dateRangeSchema.meta(tabMeta('detail')).required(),
      }).concat(settingsSchema),
    [dateRangeSchema, settingsSchema]
  );
};

export type EventFormData = Yup.TypeOf<ReturnType<typeof useEventSchema>>;
export type ValidEventFormData = Yup.Asserts<ReturnType<typeof useEventSchema>>;

export default useEventSchema;
