import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import { stringify } from 'core/functions/qs';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useGetPermission } from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import ControlReport from 'planning/models/ControlReport';
import UAReport from 'planning/models/UAReport';

import { PaperTableTabsOptions } from './PaperTableTabs';

interface Props {
  missionsId: number | undefined;
}

const useReportsTabs = ({ missionsId }: Props) => {
  const { t } = useTranslation();
  const getPermission = useGetPermission();
  const canFindTestReports = getPermission('testReports:find');
  const canFindDcoReports = getPermission('testDcoReports:find');
  const canFindUaReports = getPermission('testUaReports:find');
  const enableReports = useBoolClientOption('enableDcr');
  const enableDcoReports = useBoolClientOption('enableDcoReport');

  const reportsDisabled =
    !(enableReports && canFindTestReports) && !(enableDcoReports && canFindDcoReports);

  const { data: reports, isLoading: reportsLoading } = useResources<ControlReport>(
    'missionReports',
    {
      url: getResourceUrl(`missions/${missionsId}/reports`, { order: 'createdAt', sort: 'desc' }),
      autoload: !reportsDisabled,
    }
  );

  const { data: uaReports, isLoading: uaReportsLoading } = useResources<UAReport>(
    'missionUaReports',
    {
      url: getResourceUrl(`missions/${missionsId}/ua`, {
        order: 'createdAt',
        sort: 'desc',
      }),
    }
  );

  return useMemo<PaperTableTabsOptions>(
    () => [
      {
        title: t('Control Reports'),
        icons: [
          {
            icon: 'link',
            to: `${entities.testDcoReport.urls(-1).list}?${stringify({
              filters: { mission: missionsId?.toString(), archived: 'any' },
            })}`,
            disabled: !reports?.length,
          },
        ],
        data: reports,
        dataLoading: reportsLoading,
        type: 'reports',
        hidden: reportsDisabled,
      },
      {
        title: t('UA Reports'),
        icons: [
          {
            icon: 'link',
            to: `${entities.testUaReport.urls(-1).list}?${stringify({
              filters: { mission: missionsId?.toString(), archived: 'any' },
            })}`,
            disabled: !reports?.length,
          },
        ],
        data: uaReports,
        dataLoading: uaReportsLoading,
        type: 'uaReports',
        hidden: !canFindUaReports,
      },
    ],
    [
      canFindUaReports,
      missionsId,
      reports,
      reportsDisabled,
      reportsLoading,
      t,
      uaReports,
      uaReportsLoading,
    ]
  );
};

export default useReportsTabs;
