import { FC, useMemo } from 'react';

import DropDown, { Option } from 'core/components/DropDown';
import { Cell } from 'core/components/Grid';
import ResourceDropdown from 'core/components/ResourceDropdown';
import {
  Filter,
  FilterValue,
  DropDownFilter,
  FilterDelimiter,
  RenderedCustomFilter,
  ResourceDropDownFilter,
} from 'core/containers/DataGrid';
import useAppSelector from 'core/hooks/useAppSelector';
import { groupSelection } from 'core/hooks/useDropDownSelectionGrouping';
import { t } from 'core/i18n';
import ActiveModel from 'core/models/Active';
import BaseModel from 'core/models/Base';

import { ModalSubtitle } from '../../../styled';

interface Props {
  filters: { [id: string]: Filter };
  filterValues: { [key: string]: FilterValue };
  defaultFilterValues: { [key: string]: FilterValue };
  onFilterValueChange: (id: string, val: FilterValue, autoCommit?: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
}

const prepareOptionsFromResource = (
  resource: BaseModel[] | undefined,
  customReducer: ((resource: BaseModel[], value: FilterValue) => Option[]) | undefined,
  value: FilterValue,
  empty = true,
  anyAndNoInOptions = true
): Option[] | undefined => {
  if (!resource) return undefined;

  const defaultReducer = () =>
    resource
      .filter((item: BaseModel | ActiveModel) =>
        'active' in item
          ? item.active === true ||
            (Array.isArray(value) ? value.includes(item.id) : item.id === value)
          : true
      )
      .map((option: BaseModel & { name?: string; fullName?: string; shortName?: string }) => ({
        id: option.id,
        name: option.name || option.shortName || option.fullName || `#${option.id}`,
      }));

  return [
    ...((anyAndNoInOptions
      ? [
          {
            id: 'any',
            name: t('Any'),
          },
          ...(empty
            ? [
                {
                  id: 'noValue',
                  name: t('No value'),
                },
              ]
            : []),
          'SEPARATOR',
        ]
      : []) as Option[]),
    ...(customReducer ? customReducer(resource, value) : defaultReducer()),
  ];
};

const Filters: FC<Props> = ({
  filterValues,
  filters,
  defaultFilterValues,
  onFilterValueChange,
  isModalOpen,
  onConfirm,
}) => {
  const openFilterId = useAppSelector(({ core }) => core.openDropdownId);

  const renderedFilters = Object.entries(filters).filter(
    ([, filter]) => filter.type !== 'custom'
  ) as Array<
    [string, ResourceDropDownFilter | DropDownFilter | RenderedCustomFilter | FilterDelimiter]
  >;

  const filterElements = useMemo(
    () =>
      renderedFilters.map(([id, filter]) => {
        const currentValue =
          filterValues[id] !== undefined ? filterValues[id] : defaultFilterValues[id];
        const filterId = `filter-${id}`;

        if (filter.type === 'delimiter') {
          return (
            <Cell key={id} columns={12}>
              <ModalSubtitle>{filter.heading}</ModalSubtitle>
            </Cell>
          );
        }

        if (filter.type === 'customRendered') {
          return (
            <Cell key={id} tabletColumns={8} columns={6}>
              {filter.component(
                (value) => onFilterValueChange(id, value, filterId === openFilterId),
                currentValue
              )}
            </Cell>
          );
        }

        const options =
          'options' in filter
            ? typeof filter.options === 'function'
              ? filter.options(currentValue, { ...defaultFilterValues, ...filterValues })
              : filter.options
            : [];

        const defaultOnChange =
          'options' in filter
            ? (value: FilterValue, autocommit = false) => onFilterValueChange(id, value, autocommit)
            : (value: FilterValue, autocommit = false) => {
                if (filter.single !== false) return onFilterValueChange(id, value, autocommit);

                // Can't be empty - defaults to 'Any'
                if (!Array.isArray(value) || (Array.isArray(value) && value.length === 0))
                  return onFilterValueChange(id, ['any'], autocommit);

                const prevValue = currentValue;
                if (
                  value.includes('any') &&
                  (!Array.isArray(prevValue) || !prevValue.includes('any'))
                ) {
                  // When we didn't have 'any' previously and selected it - we select only that (and unselect others)
                  return onFilterValueChange(id, ['any'], autocommit);
                } else if (
                  value.includes('any') &&
                  value.length > 1 &&
                  Array.isArray(prevValue) &&
                  prevValue.includes('any')
                ) {
                  // When we did have 'any' and we selected more, we unselected 'any'
                  return onFilterValueChange(
                    id,
                    value.filter((id) => id !== 'any'),
                    autocommit
                  );
                } else {
                  // We are just selecting values
                  onFilterValueChange(id, value, autocommit);
                }
              };

        // DataGrid filters have to follow the pattern of meta options delimited with delimiter
        const groupedOptions = options
          ? groupSelection(options, currentValue, {
              getPosition: (options) => {
                const separatorIndex = options.findIndex((o) => o === 'SEPARATOR');
                return separatorIndex !== -1 ? separatorIndex + 1 : 0;
              },
              getIgnored: (options) => {
                const separatorIndex = options.findIndex((o) => o === 'SEPARATOR');
                return separatorIndex !== -1
                  ? Array(separatorIndex)
                      .fill(0)
                      .map((_, i) => i)
                  : [];
              },
            })
          : [];

        const isResource = 'resource' in filter;
        const Component = isResource ? ResourceDropdown : DropDown;

        return (
          <Cell key={id} phoneColumns={4} tabletColumns={8} columns={4}>
            <Component
              id={filterId}
              name={filterId}
              disabled={!isResource && !options}
              label={filter.label}
              options={isResource ? [] : groupedOptions}
              value={currentValue}
              resource={('resource' in filter && filter.resource) || ''}
              onChange={(newValue) => {
                if ('onChange' in filter && filter.onChange) {
                  filter.onChange(newValue, currentValue, (newOnChangeValue: FilterValue) => {
                    onFilterValueChange(
                      id,
                      newOnChangeValue,
                      filter?.single && filterId === openFilterId
                    );
                  });
                } else {
                  defaultOnChange(newValue, filter?.single && filterId === openFilterId);
                }
              }}
              single={'single' in filter ? filter.single : true}
              resourceReducer={
                isResource
                  ? (list: any[]) => {
                      return (
                        prepareOptionsFromResource(
                          list as BaseModel[] | undefined,
                          (filter as ResourceDropDownFilter).reducer,
                          currentValue,
                          (filter as ResourceDropDownFilter).canBeEmpty,
                          (filter as ResourceDropDownFilter).anyAndNoInOptions
                        ) || []
                      );
                    }
                  : undefined
              }
              required
              onConfirm={() => {
                if (filterId === openFilterId) {
                  onConfirm();
                }
              }}
              suppressFetchIfNotOpened={!isModalOpen}
            />
          </Cell>
        );
      }),
    [
      renderedFilters,
      filterValues,
      defaultFilterValues,
      isModalOpen,
      onFilterValueChange,
      openFilterId,
      onConfirm,
    ]
  );

  return <>{filterElements}</>;
};

export default Filters;
