import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getTestSituationName, TestSituation } from 'planning/models/Test';

const useTestSituationOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      { name: t(getTestSituationName(TestSituation.OC)), id: TestSituation.OC },
      { name: t(getTestSituationName(TestSituation.TC)), id: TestSituation.TC },
      { name: t(getTestSituationName(TestSituation.IC)), id: TestSituation.IC },
    ],
    [t]
  );
};

export default useTestSituationOptions;
