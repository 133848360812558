import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from 'core/components/IconButton';
import { useFormatRelativeDateTime } from 'core/i18n/useFormatDateTime';
import { getCustomUserAvatarUrl } from 'core/models/CoreProfile';

import { Avatar, AvatarLetters } from '../../../styled';
import { TeamMemberData } from '../../../useTeamInputMapping';
import useActions from '../../useActions';

import { ColoredIconButton, NameCell, PersonalInfo } from './styled';

interface Props {
  user: TeamMemberData & { id: number };
  availabilities: { [usersId: number]: string };
  triggers: ReturnType<typeof useActions>['1'];
  viewMode: boolean;
  disableInvitations: boolean;
  actionsDisabled: boolean;
}

enum InvitationStatus {
  NONE = 0,
  SENT = 1,
  SENDING = 2,
}

const BasicInfoCell: FC<Props> = ({
  user,
  availabilities,
  triggers,
  viewMode,
  disableInvitations,
  actionsDisabled,
}) => {
  const { t } = useTranslation();
  const formatRelativeDateTime = useFormatRelativeDateTime();

  const profileUrl =
    user.avatarStorageKey && getCustomUserAvatarUrl(user.id, user.avatarStorageKey);
  const initials =
    user.firstName && user.lastName
      ? user.firstName.charAt(0) + user.lastName.charAt(0)
      : user.fullName?.substring(0, 2) || '-';

  let invitationStatus = InvitationStatus.NONE;
  const invitations =
    user.invitations.length > 0
      ? user.invitations.map((i) => {
          invitationStatus = Math.max(
            invitationStatus,
            i.createdAt ? InvitationStatus.SENT : InvitationStatus.SENDING
          );
          const when = i.sentAt ? formatRelativeDateTime(i.sentAt) : t('N/A');

          const key = i.createdAt + i.type;
          if (i.sentType === i.type) {
            return (
              <div key={key}>
                {t('Sent {{when}} via {{realMeans}}', {
                  when,
                  realMeans: i.sentType,
                })}
              </div>
            );
          } else if (i.sentType) {
            return (
              <div key={key}>
                {t('Planned via {{means}} - sent {{when}} via {{realMeans}}', {
                  means: i.type,
                  when,
                  realMeans: i.sentType,
                })}
              </div>
            );
          } else if (i.createdAt) {
            return <div key={key}>{t('Sending via {{means}}', { means: i.type })}</div>;
          } else {
            return (
              <div key={key}>{t('Will be sent via {{means}} on save', { means: i.type })}</div>
            );
          }
        })
      : t('Not invited');

  const info = availabilities[user.usersId] ? [availabilities[user.usersId]] : [];
  info.push(...user.tags);

  return (
    <NameCell flex={6} block minWidth="18rem">
      {profileUrl ? (
        <Avatar src={profileUrl} alt={user.fullName || t('Avatar')} />
      ) : (
        <AvatarLetters>{initials}</AvatarLetters>
      )}
      <PersonalInfo>
        <h3>{user.fullName}</h3>
        <span>{info.join(', ') || '-'}</span>
      </PersonalInfo>

      <ColoredIconButton
        icon="send"
        tooltip={invitations}
        disabled={disableInvitations || viewMode}
        onClick={() => triggers.onToggleInvitations(user)}
        color={
          // @ts-ignore TS thinks there is no overlap although this value is mutated
          invitationStatus === InvitationStatus.SENDING
            ? 'warning'
            : // @ts-ignore TS thinks there is no overlap although this value is mutated
              invitationStatus === InvitationStatus.SENT
              ? 'success'
              : 'gray300'
        }
      />
      <IconButton
        icon={user.internalComment ? 'speaker_notes' : 'chat_bubble_outline'}
        tooltip={user.internalComment || undefined}
        disabled={actionsDisabled}
        onClick={() => triggers.onEditComments(user)}
      />
    </NameCell>
  );
};

export default BasicInfoCell;
