import MaterialIconButton from '@material/react-icon-button';
import { ComponentProps, forwardRef, MouseEvent, ReactNode, Ref } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';
import Tooltip from 'core/components/Tooltip';

import { Badge, IconWrapper, StyledLink } from './styled';

type Props = Omit<
  ComponentProps<typeof MaterialIconButton> & {
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    type?: string;
    form?: string;
    icon?: string;
    to?: string;
    disabled?: boolean;
    className?: string;
    dense?: boolean;
    badge?: ReactNode;
    tooltip?: ReactNode;
    children?: ReactNode;
    color?: string;
    tooltipsAppendTo?: 'parent' | Element | ((ref: Element) => Element);
  },
  // Tooltip should be always preferred.
  'title'
>;

const IconButton = forwardRef<HTMLElement, Props>(
  (
    {
      onClick,
      disabled,
      icon,
      form,
      type = form ? 'submit' : 'button',
      to,
      className,
      dense,
      badge,
      tooltip,
      children,
      color,
      tooltipsAppendTo,
      ...rest
    },
    ref
  ) => {
    if (!children && !icon) {
      throw new Error('Icon Button has no content to display');
    }

    let el = (
      // IconButton from material components doesn't forward our ref properly
      <IconWrapper ref={ref as Ref<HTMLDivElement>} dense={dense} color={color}>
        <MaterialIconButton
          className={className}
          onClick={onClick}
          type={type}
          form={form}
          disabled={disabled}
          {...rest}
        >
          {badge && <Badge>{badge}</Badge>}
          {icon ? <MaterialIcon icon={icon} /> : children}
        </MaterialIconButton>
      </IconWrapper>
    );

    if (tooltip) {
      el = (
        <Tooltip content={tooltip} appendTo={tooltipsAppendTo}>
          {el}
        </Tooltip>
      );
    }

    return to ? <StyledLink to={`/${to}`.replace(/\/+/g, '/')}>{el}</StyledLink> : el;
  }
);

export default IconButton;
