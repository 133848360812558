import merge from 'lodash/merge';
// eslint-disable-next-line no-restricted-imports
import qs, { IParseOptions, IStringifyOptions } from 'qs';

export const stringify = (obj: any, options?: IStringifyOptions) => {
  const opt = {
    arrayFormat: 'indices',
    ...options,
  } satisfies IStringifyOptions;

  return qs.stringify(obj, opt);
};

/**
 * Merge URL with params (overrides)
 *
 * @param url Starting URL
 * @param overrides params that should override existing ones
 * @param options.parsing Options to use for parsing
 * @param options.shallowMerge Should merge be just shallow (one level deep) and not deep (default)
 */
export const mergeUrl = (
  url: undefined | string,
  overrides?: Record<string, unknown>,
  options?: { parsing?: IStringifyOptions; shallowMerge?: boolean }
): string => {
  const [root, search] = (url || '').split('?');

  const defaults = parse(search || '', options?.parsing);
  const params: Record<string, unknown> = options?.shallowMerge
    ? { ...defaults, ...overrides }
    : merge(defaults || {}, overrides || {});

  const query = stringify(params);
  return `${root}${query ? '?' + query : ''}`;
};

export const parse = (str: string, options?: IParseOptions) => {
  return qs.parse(str, { ignoreQueryPrefix: true, ...options });
};
