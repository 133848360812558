import { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import IconButton from 'core/components/IconButton';
import MaterialIcon from 'core/components/MaterialIcon';
import { COUNTS_PORTAL_ID } from 'core/containers/DataGrid/Toolbar';

import HelpModal from './HelpModal';
import { Wrapper, Content, Input, Form } from './styled';

/**
 * @param onSubmit - submit callback
 * @param initialValue - initialization value for input field
 * @param expanded - render in expanded mode only
 * @param portalId - ref to primary content node (e.g. when u use portal)
 */
export interface Props {
  onSubmit: (val: null | undefined | string) => void;
  value?: null | string;
  /** force open */
  expanded?: boolean;
  portalId?: string;
  helpDisabled?: boolean;
}

const SearchInput: FC<Props & RouteComponentProps> = ({
  expanded = false,
  helpDisabled = false,
  value,
  portalId,
  onSubmit,
}) => {
  const [open, setOpen] = useState(expanded || typeof value === 'string');
  const [inputValue, setInputValue] = useState(value || '');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [helpOpen, setHelpOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setInputValue(value || '');
    setOpen(expanded || typeof value === 'string');
  }, [value, expanded]);

  const submit = (e?: SyntheticEvent<HTMLElement>) => {
    e && e.preventDefault();
    onSubmit && onSubmit(inputValue || null);
  };

  const handleChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      setInputValue(e.currentTarget.value);
    },
    [setInputValue]
  );

  const [target, setTarget] = useState<null | Element>(
    (portalId && document.getElementById(portalId)) || null
  );

  // We need to run this effect on every re-render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (portalId) {
      const targetNode = document.getElementById(portalId);
      setTarget(targetNode);
    }
  });

  const component = (
    <Wrapper open={expanded || open}>
      {expanded === false && (
        <IconButton
          icon="arrow_back"
          onClick={() => {
            onSubmit(undefined);
          }}
        />
      )}

      <Content>
        <MaterialIcon icon="search" />
        <Form id="search-input" onSubmit={submit}>
          <Input
            placeholder={t('Type search term and press enter...')}
            onChange={handleChange}
            value={inputValue || ''}
            autoComplete="off"
            name="searchInput"
            ref={inputRef}
            tabIndex={0}
            autoFocus
            size={5}
          />
          <button type="submit" />
        </Form>
        <span id={COUNTS_PORTAL_ID} />
        {!helpDisabled && <IconButton onClick={() => setHelpOpen(true)} icon="help_outline" />}
      </Content>
    </Wrapper>
  );

  if (portalId) {
    return (
      <>
        {target ? createPortal(component, target) : null}
        <HelpModal open={helpOpen} onClose={() => setHelpOpen(false)} />
      </>
    );
  }

  return (
    <>
      {component}
      <HelpModal open={helpOpen} onClose={() => setHelpOpen(false)} />
    </>
  );
};

export default withRouter(SearchInput);
