import { Options } from '@popperjs/core';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import { FC, useMemo, useRef } from 'react';
import { Instance, Placement } from 'tippy.js';

import IconButton from 'core/components/IconButton';
import Menu, { Item } from 'core/components/Menu';
import { PopoverWrapper } from 'core/containers/DataGrid/CellRenderers/RowActionsCellRenderer/styled';

import { applyMaxSizeModifier, maxSizeModifier } from './sizing';

interface Props {
  menuItems: Array<Item | 'SEPARATOR'>;
  menuId: string;
  visible?: boolean;
  onToggle?: () => void;
  onClickOutside?: () => void;
  placement?: Placement;
  scroll?: boolean;
  children?: TippyProps['children'];
}

const ContextMenu: FC<Props & TippyProps> = ({
  menuItems,
  menuId,
  onToggle,
  onClickOutside,
  visible,
  placement = 'bottom-start',
  scroll = false,
  children,
  ...props
}) => {
  const tippyRef = useRef<Instance>();

  const decoratedItems = menuItems.map((it) => {
    if (it === 'SEPARATOR') {
      return it;
    }
    return {
      ...it,
      onClick: (item: Item) => {
        it.onClick && it.onClick(item);
        tippyRef.current && tippyRef.current.hide();
        onToggle && onToggle();
      },
    };
  });

  const popperOptions = useMemo(
    () =>
      scroll
        ? ({
            modifiers: [maxSizeModifier, applyMaxSizeModifier],
          } as Options)
        : undefined,
    [scroll]
  );

  return (
    <Tippy
      interactive
      trigger={typeof visible === 'boolean' ? undefined : 'click'}
      onCreate={(instance) => {
        tippyRef.current = instance;
      }}
      placement={placement}
      appendTo={document.body}
      popperOptions={popperOptions}
      visible={visible}
      onClickOutside={() => {
        onToggle && onToggle();
        onClickOutside && onClickOutside();
      }}
      render={(attrs) => (
        <PopoverWrapper {...attrs}>
          <Menu id={`contextMenu-${menuId}`} items={decoratedItems} />
        </PopoverWrapper>
      )}
      {...props}
    >
      <span>
        {children || (
          <IconButton onClick={() => (onToggle ? onToggle() : undefined)} icon="more_horiz" />
        )}
      </span>
    </Tippy>
  );
};

export default ContextMenu;
