import User from 'app/models/User';
import Base from 'core/models/Base';

import { AccreditationsRole } from '../../app/models/Accreditation';

export enum TeamMemberStatus {
  SELECTED = 0,
  AWAITING = 1,
  ACCEPTED = 2,
  DECLINED = 3,
  CONFIRMED = 4,
}

export interface TeamMemberInvitation {
  type: 'sms' | 'email';

  /**
   * Added once the invitation is sent - controlled by the backend
   *
   * Due to fallback, type does not have to match sentType if sms was scheduled but only email could be delivered
   */
  sentType?: 'sms' | 'email';

  /**
   * Added once the invitation is sent - controlled by the backend
   */
  sentAt?: Date;

  /**
   * Added once the invitation is scheduled - controlled by the backend
   */
  createdAt?: Date;
}

export interface MissionTeamMember extends TeamMember {
  missionsId: number;
}

export interface TestTeamMember extends TeamMember {
  testsId: number;
}

export interface MemberAccreditation extends Base {
  accreditationsId: number;
}

export default interface TeamMember extends Base {
  usersId: number;
  status: TeamMemberStatus;
  internalComment: string | null;
  statement: string | null;
  invitations: TeamMemberInvitation[];
  user: User;
  roles: AccreditationsRole[];
}
