import { TippyProps } from '@tippyjs/react';
import { ICellRendererParams } from 'ag-grid-community';
import { PropsWithChildren, ReactNode, useMemo } from 'react';

import Tooltip from 'core/components/Tooltip';

import { TooltipWrapper } from './styled';

type Props<T, V> = Omit<TippyProps, 'content'> & {
  content: (data?: T) => ReactNode | string | null;
  params: ICellRendererParams<T, V>;
  children?: ReactNode;
};

const TooltipCellRenderer = <T, V>({
  content,
  params,
  children,
  ...props
}: PropsWithChildren<Props<T, V>>) => {
  const tooltipContent = useMemo(
    () => (typeof content === 'function' ? content(params.data) : content),
    [content, params.data]
  );

  return (
    <TooltipWrapper>
      <Tooltip content={tooltipContent} offset={[0, 0]} {...props}>
        <span>{children || (params.value as any)}</span>
      </Tooltip>
    </TooltipWrapper>
  );
};

export default TooltipCellRenderer;
