import { IHeaderParams } from 'ag-grid-community';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setOpenDropdownId } from 'core/actions';
import MaterialIcon from 'core/components/MaterialIcon';
import { useQueryParam } from 'core/hooks/useQueryParams';

import { ColumnDef, HandleSortCallback } from '../props';

import { Wrapper } from './styled';

interface Props<T> {
  headerParams: IHeaderParams<T>;
  columnDef: ColumnDef<T>;
  handleSort: HandleSortCallback;
  sortable?: boolean;
}

const ColumnHeaderComponent = <T,>({
  headerParams,
  columnDef,
  handleSort,
  sortable = false,
}: Props<T>) => {
  const dispatch = useDispatch();
  const filters = useQueryParam('filters');
  const [showActions, setShowActions] = useState<boolean>(false);
  const sortState = useMemo(() => headerParams.column.getSort(), [headerParams.column]);

  const showFilterAction = useMemo(() => {
    if (!columnDef.customFilter) return false;
    if (showActions) return true;
    return filters && typeof filters === 'object' && columnDef.customFilter?.filterKey in filters;
  }, [columnDef.customFilter, filters, showActions]);

  return (
    <Wrapper
      onClick={() => sortable && handleSort(headerParams.column, columnDef)}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <span className="column-header-display-name">{headerParams.displayName}</span>
      {sortable && (sortState === 'asc' || sortState === 'desc') && (
        <span data-cy="eSortOrder">
          <MaterialIcon icon={sortState === 'asc' ? 'arrow_upward' : 'arrow_downward'} />
        </span>
      )}
      {showFilterAction && (
        <MaterialIcon
          icon="filter_list"
          onClick={(e) => {
            dispatch(setOpenDropdownId(`filter-${columnDef.customFilter?.filterKey}`));
            e.stopPropagation();
          }}
        />
      )}
    </Wrapper>
  );
};

export default ColumnHeaderComponent;
