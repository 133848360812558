import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

export const StyledMenuItem = styled.div<{ active?: boolean; hasHoverIcon?: boolean }>`
  display: flex;
  font-size: 0.875rem;
  position: relative;
  text-align: center;
  padding: 0.5rem 0.7rem;
  height: 2.1875rem;
  align-items: center;
  cursor: pointer;
  max-width: 300px;

  .menuSubItemHoverIcon {
    display: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);

    ${(p) =>
      p.hasHoverIcon &&
      css`
        .menuSubItemIcon {
          display: none;
        }
      `}

    .menuSubItemHoverIcon {
      display: flex;
    }
  }
  ${(p) =>
    p.active &&
    css`
      background: rgba(0, 0, 0, 0.1);
    `}
`;

export const StyledMenuItemDisabled = styled(StyledMenuItem)`
  opacity: 0.6;

  .menuSubItemHoverIcon {
    display: none;
  }

  &:hover {
    cursor: default;
    background: inherit;
  }
`;

export const StyledMenuText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
  text-overflow: ellipsis;
`;

export const StyledIcon = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .material-icons {
    width: 0.9375rem;
    height: 0.9375rem;
    font-size: 0.9375rem;
  }
`;
export const StyledLink = styled(Link)<{ disabled?: boolean }>`
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
`;

export const StyledHref = styled.a<{ disabled?: boolean }>`
  &:hover {
    text-decoration: none;
  }
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
`;
