import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import {
  apiCall,
  DeleteViewAction,
  FavoriteViewAction,
  PREFIX_DELETE_VIEW,
  PREFIX_SAVE_VIEW,
  SaveViewAction,
  TYPE_FAVORITE_VIEW,
} from 'core/actions';
import { t } from 'core/i18n';
import { UserView } from 'core/models/UserView';

export function* saveView(action: SaveViewAction) {
  const { data, previousData, id } = action.payload;

  yield put(
    apiCall(
      PREFIX_SAVE_VIEW,
      previousData ? 'PATCH' : 'POST',
      `/users/views/${id || ''}`,
      data,
      undefined,
      true,
      previousData
    )
  );

  const { success } = yield race({
    success: take(`${PREFIX_SAVE_VIEW}_SUCCESS`),
    error: take(`${PREFIX_SAVE_VIEW}_ERROR`),
  });

  if (success) {
    toast.success(t('View was successfully saved.'));
    action.payload.successCallback &&
      action.payload.successCallback(success.payload?.response?.data as UserView);
  }
}

export function* favoriteView(action: FavoriteViewAction) {
  const { successCallback, viewsId, favorite } = action.payload;

  yield put(apiCall(TYPE_FAVORITE_VIEW, 'POST', `/users/views/${viewsId}/favorite`, { favorite }));

  const { success } = yield race({
    success: take(`${TYPE_FAVORITE_VIEW}_SUCCESS`),
    error: take(`${TYPE_FAVORITE_VIEW}_ERROR`),
  });

  if (success) {
    successCallback && successCallback();
  }
}

export function* deleteView(action: DeleteViewAction) {
  yield put(apiCall(PREFIX_DELETE_VIEW, 'DELETE', `/users/views/${action.payload.id}`));

  const { error } = yield race({
    success: take(`${PREFIX_DELETE_VIEW}_SUCCESS`),
    error: take(`${PREFIX_DELETE_VIEW}_ERROR`),
  });

  if (error) {
    return;
  }

  toast.success(t('View was successfully deleted.'));
  action.payload.successCallback();
}
