import classnames from 'classnames';
import { forwardRef, MouseEvent } from 'react';

import Tooltip, { TooltipSingleton } from 'core/components/Tooltip';

import StyledMaterialIcon, { StyledMaterialIconProps } from './styled';

export interface MaterialIconProps extends StyledMaterialIconProps {
  icon: string;
  tooltip?: string;
  tooltipSingleton?: TooltipSingleton;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

/**
 * Effective material icons wrapper with support for tooltips, color, and size
 */
const MaterialIcon = forwardRef<HTMLSpanElement, MaterialIconProps>(
  ({ icon, tooltip, tooltipSingleton, className, color, size, onClick }, ref) => {
    const iconEl = (
      <StyledMaterialIcon
        ref={ref}
        className={classnames('material-icons', `icon-${icon}`, className)}
        color={color}
        size={size}
        onClick={onClick}
      >
        <span aria-hidden="true">{icon}</span>
      </StyledMaterialIcon>
    );

    return tooltip ? (
      <Tooltip singleton={tooltipSingleton} content={tooltip}>
        {iconEl}
      </Tooltip>
    ) : (
      iconEl
    );
  }
);

export default MaterialIcon;
