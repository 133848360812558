import { RowClickedEvent } from 'ag-grid-community';
import { FC } from 'react';
import { useHistory } from 'react-router';

import { entities } from 'app/entity';
import { Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import 'core/containers/DataGrid/styles.scss';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import { stringify } from 'core/functions/qs';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useGetPermission } from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import { ApiQueryStatus } from 'core/reducer';
import useMissionTests from 'planning/hooks/useMissionTests';
import Coc from 'planning/models/Coc';
import ControlReport from 'planning/models/ControlReport';
import Sample from 'planning/models/Sample';
import Test from 'planning/models/Test';
import UAReport from 'planning/models/UAReport';

import PaperTable from './PaperTable';
import PaperTableTabs from './PaperTableTabs';
import useReportsTabs from './useReportsTabs';

const ContentsTab: FC = () => {
  const { id: missionsId, mode } = useDetail();
  const history = useHistory();
  const isEdit = mode === 'edit';

  const getPermission = useGetPermission();
  const canFindSamples = getPermission('samples:find');
  const canFindTests = getPermission('tests:find');
  const canFindCocs = getPermission('cocs:find');
  const canGetTestReportDetail = getPermission('testReports:get');
  const canGetDcoReportDetail = getPermission('testDcoReports:get');
  const canGetUaReportDetail = getPermission('testUaReports:get');
  const enableReports = useBoolClientOption('enableDcr');
  const enableDcoReports = useBoolClientOption('enableDcoReport');

  const navigate = (url: string) => history.push(url);

  const { data: samples, isLoading: samplesLoading } = useResources<Sample>('missionSamples', {
    url: getResourceUrl(`missions/${missionsId}/samples`, { order: 'createdAt', sort: 'desc' }),
    autoload: canFindSamples,
  });

  const { data: tests, status: testsStatus } = useMissionTests(missionsId);
  const testsLoading = testsStatus === ApiQueryStatus.LOADING;

  const { data: cocs, isLoading: cocsLoading } = useResources<Coc>('missionCocs', {
    url: getResourceUrl(`missions/${missionsId}/cocs`, {
      order: 'createdAt',
      sort: 'desc',
    }),
    autoload: canFindCocs,
  });

  const reportTabs = useReportsTabs({ missionsId });

  return (
    <Row $fullHeight>
      <HalfRowCell>
        <Row $fullHeight>
          {canFindTests && (
            <FullRowCell>
              <PaperTable
                type="tests"
                onSelect={(event: RowClickedEvent) => {
                  if (isEdit) {
                    const test: Test = event.data;
                    navigate(entities.test.urls().detail(test.id));
                  }
                }}
                data={tests || []}
                loading={testsLoading}
                icons={[
                  {
                    icon: 'link',
                    to: `${entities.test.urls().list}?${stringify({
                      filters: { mission: missionsId?.toString(), archived: 'any' },
                    })}`,
                    disabled: !tests?.length,
                  },
                ]}
              />
            </FullRowCell>
          )}

          {canFindCocs && (
            <FullRowCell>
              <PaperTable
                data={cocs || []}
                type="cocs"
                onSelect={(event: RowClickedEvent) => {
                  if (isEdit) {
                    const coc: Coc = event.data;
                    navigate(entities.coc.urls().detail(coc.id));
                  }
                }}
                loading={cocsLoading}
                icons={[
                  {
                    icon: 'link',
                    to: `${entities.coc.urls().list}?${stringify({
                      filters: { mission: missionsId?.toString() },
                    })}`,
                    disabled: !cocs?.length,
                  },
                ]}
              />
            </FullRowCell>
          )}
        </Row>
      </HalfRowCell>

      <HalfRowCell>
        <Row $fullHeight>
          <FullRowCell>
            <PaperTableTabs
              tabs={reportTabs}
              onSelect={(event, type) => {
                if (!isEdit) return;
                if (
                  type === 'reports' &&
                  ((canGetTestReportDetail && enableReports) ||
                    (canGetDcoReportDetail && enableDcoReports))
                ) {
                  const report: ControlReport = event.data;
                  navigate(entities.testDcoReport.urls(report.testsId).detail(report.id));
                } else if (canGetUaReportDetail) {
                  const report: UAReport = event.data;
                  navigate(entities.testUaReport.urls(report.testsId).detail(report.id));
                }
              }}
            />
          </FullRowCell>

          {canFindSamples && (
            <FullRowCell>
              <PaperTable
                data={samples || []}
                type="samples"
                onSelect={(event: RowClickedEvent) => {
                  if (isEdit) {
                    const sample: Sample = event.data;
                    navigate(entities.sample.urls().detail(sample.id));
                  }
                }}
                loading={samplesLoading}
                icons={[
                  {
                    icon: 'link',
                    to: `${entities.sample.urls().list}?${stringify({
                      filters: { mission: missionsId?.toString(), volume: 'any' },
                    })}`,
                    disabled: !samples?.length,
                  },
                ]}
              />
            </FullRowCell>
          )}
        </Row>
      </HalfRowCell>
    </Row>
  );
};

export default ContentsTab;
