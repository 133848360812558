import BaseModel from 'core/models/Base';
import { TestSituation } from 'planning/models/Test';

import { PaymentItemType, PaymentPlan } from './PaymentPlan';

export enum DCOInvoiceStatus {
  DRAFT = 0,
  SUBMITTED = 1,
  ACCEPTED = 2,
  DECLINED = 3,
}

export interface UserInvoiceDataInput {
  iban: null | string;
  vatPayer?: null | boolean;
  vatNumber?: null | string;
  taxNumber?: null | string;
  bic?: null | string;
  accountOwnerName?: null | string;
  bankName?: null | string;
}

export interface UserInvoiceData extends BaseModel, UserInvoiceDataInput {}

// Should extend BaseModel but its extremely annoying
export interface BaseDCOInvoiceItem {
  name?: string;
  type?: PaymentItemType;
  share?: number;
  qty?: number;
  amount?: number;
  vat?: number;
  kind: InvoiceTypeKind;
  paymentPlanItemsId: number | null;
}

export enum InvoiceTypeKind {
  TESTS = 'tests',
  ADDITIONAL = 'additional',
  PERIOD = 'period',
  DISTANCE = 'distance',
  BCO_FACTOR = 'bcoFactor',
}

export enum SampleType {
  URINE = 'urine',
  BLOOD = 'blood',
  DBS = 'dbs',
}
export interface TestsInvoiceItem extends BaseDCOInvoiceItem {
  kind: InvoiceTypeKind.TESTS;
  missionsId: number;
  missionCode: string;
  testsId: number;
  sampleTypes: SampleType[];
  blood?: boolean;
  location?: string;
  urine?: boolean;
}

export interface AdditionalInvoiceItem extends BaseDCOInvoiceItem {
  kind: InvoiceTypeKind.ADDITIONAL;
  countriesId: null | number;
  receiptDate: null | Date;
}
export interface PeriodInvoiceItem extends BaseDCOInvoiceItem {
  kind: InvoiceTypeKind.PERIOD;
}
export interface DistanceInvoiceItem extends BaseDCOInvoiceItem {
  kind: InvoiceTypeKind.DISTANCE;
}

export type DCOInvoiceItem =
  | TestsInvoiceItem
  | AdditionalInvoiceItem
  | PeriodInvoiceItem
  | DistanceInvoiceItem;

// should represent Input Request model but it does not extends BaseModel so its same ...
export type DCOInvoiceItemInput = DCOInvoiceItem;

interface UserAddressInput {
  // TODO: do we need this in Invoice?
  type: 'main' | 'delivery' | 'residence';
  address: string | null;
  street?: null | string;
  city?: null | string;
  zip?: null | string;
  state?: null | string;
  countriesId: null | number;
}

export interface DcoInvoiceTest {
  testsId: number;
  missionsId: number;
  missionCode: string;
  isBco: boolean;
  blood: boolean;
  urine: boolean;
  dbs: boolean;
  testUaReportsId: number | null;
  testSituationsId: TestSituation;
  participants?: null | string;
  location: string;
  doneDate: Date;
}

export interface DCOInvoice extends BaseModel {
  readonly status: DCOInvoiceStatus;
  readonly paymentMethod?: string | null;
  readonly currency?: null | string;
  readonly dco: null | string;

  paymentPlansId?: number;
  /** Readonly Include based on paymentPlansId */
  paymentPlan?: null | PaymentPlan;
  currenciesId?: number;
  /** DCO / Owner */
  usersId?: number;
  userAddress?: UserAddressInput;
  userInvoiceData?: UserInvoiceData;
  invoiceNumber?: null | string;
  invoiceDate?: null | Date;
  dueDate?: null | Date;
  invoiceStorageKey?: string | null;
  invoiceHeader?: Record<string, any>;
  timeZonesId: number;
  paymentRules?: Record<string, any>[];
  participants?: null | string;
  destinations?: null | string;
  totalGross?: number;
  totalNet?: number;

  urine?: boolean;
  blood?: boolean;
  items: DCOInvoiceItem[];
}

export interface DatagridDCOInvoice extends BaseModel {
  avatarStorageKey: null | string;
  usersId: number;
  blood: boolean;
  currenciesId: number;
  currencyCode: string;
  currencyName: string;
  destinations: string;
  dueDate: null | Date;
  invoiceDate: Date;
  invoiceNumber: string | null;
  participants: string;
  paymentMethodsId: number;
  paymentMethodName: string;
  paymentPlanName: string;
  paymentPlansId: number;
  status: DCOInvoiceStatus;
  totalGross: string;
  totalNet: string;
  urine: boolean;
  userFullName: string;
}

/**
 * Create / Update request specs
 */
export interface DCOInvoiceInput
  extends Omit<
    DCOInvoice,
    | keyof BaseModel
    //    | 'status' // TODO: FIX its not possible to put computed property
    | 'blood'
    | 'urine'
    | 'dco'
    | 'invoiceDate'
    | 'userInvoiceData'
    | 'paymentPlan'
    | 'userAddress'
  > {
  blood: boolean;
  urine: boolean;
  invoiceDate: string | null;
  userInvoiceData: null | Omit<UserInvoiceData, keyof BaseModel>;
  invoiceHeader: Record<string, any>;
  destinations: string;
  userAddress: null | DCOInvoice['userAddress'];
}
