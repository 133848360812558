import { ChangeEvent, FC, memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { entities } from 'app/entity';
import Alert from 'core/components/Alert';
import Checkbox from 'core/components/Checkbox';
import { FlexColumn, FlexRow } from 'core/components/FlexUtils';
import FormDropDown, { Props as FormDropDownProps } from 'core/components/FormDropDown';
import { FullRowCell } from 'core/components/GridCell';
import Modal from 'core/components/Modal';
import { Spacer } from 'core/components/Spacer/styled';
import {
  STATUS_TYPE_NORMAL,
  STATUS_TYPE_WARNING,
  StatusMessage,
} from 'core/components/StatusMessage';
import useResource, { useResources } from 'core/hooks/useResource';
import Country from 'core/models/Country';
import Pool from 'lists/models/Pool';
import Sport from 'lists/models/Sport';
import SportDiscipline from 'lists/models/SportDiscipline';
import Athlete, { ATHLETES_RESOURCE } from 'personnel/models/Athlete';
import useTestSchema from 'planning/containers/TestDetailPage/hooks/useTestSchema';
import Test from 'planning/models/Test';

import { ContentFullItem, ContentHalfItems } from './RightSideContent/ContentItems';
import EmptyContent from './RightSideContent/EmptyContent';
import { Header } from './RightSideContent/Header';
import { RightContent } from './RightSideContent/styles';

type SelectedItemsProp = {
  internalComments: boolean;
  poolsId: boolean;
  zip: boolean;
  countriesId: boolean;
  street: boolean;
  address: boolean;
  email: boolean;
  phone: boolean;
  city: boolean;
  state: boolean;
  // The format is 'sportDiscipline.id-sportId.id'
  sportDisciplinesId?: string;
  // The format is 'sportId.id'
  sportsId?: string;
};

const defaultSelectedItems: SelectedItemsProp = {
  internalComments: false,
  poolsId: false,
  zip: false,
  countriesId: false,
  street: false,
  address: false,
  email: false,
  phone: false,
  city: false,
  state: false,
  sportDisciplinesId: undefined,
  sportsId: undefined,
} as const;

const AthletePickerField: FC<FormDropDownProps> = ({ form, options, ...props }) => {
  const athleteId = props.field.value ? +props.field.value : null;
  const [confirmPrefill, setConfirmPrefill] = useState<number | null>(null);
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const { data: athletes } = useResources<Athlete>(ATHLETES_RESOURCE, {
    autoload: !!confirmPrefill || openedModal,
  });
  const { data: pools } = useResources<Pool>('pools', { autoload: !!confirmPrefill });
  const { data: sports } = useResources<Sport>('sports', { autoload: !!confirmPrefill });
  const { data: countries } = useResources<Country>('countries', { autoload: !!confirmPrefill });
  const { data: sportsDisciplines } = useResources<SportDiscipline>('sports/disciplines', {
    autoload: !!confirmPrefill,
  });
  const [confirmClear, setConfirmClear] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();
  const testResult = location.pathname.includes(
    entities.testResult.urls(athleteId || 0).list.split('/')[1]
  );
  const [address, setAddress] = useState<boolean>(false);
  const { setValues, values, setFieldValue } = form;
  const schema = useTestSchema();
  const [selectedItems, setSelectedItems] = useState<SelectedItemsProp>(defaultSelectedItems);

  const { data: athleteDetail } = useResource<Athlete>(`athletes/${confirmPrefill}`, {
    autoload: !!confirmPrefill,
  });

  // The comparison is enabled only in the Test detail page, in the Test result detail page the comparison is disabled
  const originalData = useRef<Test>(values);

  const changeValues = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let athleteWithSelection = {} as Test;
      const newAthletesData = (confirmClear && ({} as Athlete)) || athleteDetail;

      Object.keys({ ...selectedItems }).forEach((key) => {
        if (selectedItems[key as keyof SelectedItemsProp]) {
          // @ts-ignore
          athleteWithSelection[key] = newAthletesData?.[key];
          if (key === 'sportDisciplinesId' || key === 'sportsId') {
            const sports = selectedItems?.sportDisciplinesId?.split('-') || null;
            athleteWithSelection['sportDisciplinesId'] = sports ? Number(sports[0]) : null;
            athleteWithSelection['sportsId'] = sports ? Number(sports[1]) : null;
          }
        }
      });

      if (event.target.name === 'sportDisciplinesId') {
        const sports = event.target.value.split('-');
        athleteWithSelection['sportDisciplinesId'] = Number(sports[0]);
        athleteWithSelection['sportsId'] = Number(sports[1]);
      } else {
        //@ts-ignore
        athleteWithSelection[event.target.name] = event.target.checked
          ? //@ts-ignore
            newAthletesData?.[event.target.name]
          : //@ts-ignore
            originalData.current?.[event.target.name];
      }

      const schemaValues = {
        ...values,
        ...athleteWithSelection,
        athletesId: confirmPrefill,
      };
      setValues({ ...schema.cast(schemaValues, { stripUnknown: true }) });
    },
    [
      selectedItems,
      confirmClear,
      athleteDetail,
      originalData,
      setValues,
      schema,
      confirmPrefill,
      values,
    ]
  );

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedItems({ ...selectedItems, [event.target.name]: event.target.checked });
      changeValues(event);
    },
    [selectedItems, changeValues]
  );

  const handleRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedItems({
        ...selectedItems,
        sportsId: event.target.checked ? event.target.value : undefined,
        sportDisciplinesId: event.target.checked ? event.target.value : undefined,
      });

      changeValues(event);
    },
    [selectedItems, changeValues]
  );

  const handleChangeData = useCallback(() => {
    const newAthletesData = (confirmClear && ({} as Athlete)) || athleteDetail;
    Object.keys(athleteDetail || {}).forEach((key) => {
      setFieldValue(
        `athlete.profile.${key}`,
        newAthletesData && newAthletesData?.[key as keyof Athlete]
          ? newAthletesData?.[key as keyof Athlete]
          : null
      );
    });
  }, [confirmClear, athleteDetail, setFieldValue]);

  const handlePrefillChange = useCallback(() => {
    if (testResult) {
      handleChangeData();
    } else {
      setValues({ ...values });
    }

    setConfirmClear(false);
  }, [values, setValues, handleChangeData, testResult]);

  const sportsOptions =
    athleteDetail && athleteDetail?.sportDisciplines
      ? athleteDetail.sportDisciplines?.map((sportDiscipline: SportDiscipline) => {
          return [
            {
              label: t('Sport'),
              description:
                sportDiscipline && sportDiscipline?.sportsName ? sportDiscipline?.sportsName : null,
              name: 'sportsId',
              currentValue: originalData.current?.sportsId || '',
              value: sportDiscipline.sportsId,
              disabled: testResult,
            },
            {
              label: t('Sport Discipline'),
              description: sportDiscipline && sportDiscipline?.name ? sportDiscipline?.name : null,
              name: 'sportDisciplinesId',
              checked:
                selectedItems['sportDisciplinesId'] ===
                sportDiscipline.id + '-' + sportDiscipline.sportsId,
              onChange: handleRadioChange,
              currentValue: originalData.current?.sportDisciplinesId && '',
              value: sportDiscipline.id + '-' + sportDiscipline.sportsId,
              disabled: testResult,
            },
          ];
        })
      : null;

  /**
   * Function to check or uncheck all checkboxes
   * @param items Items which are in the group which should be checked or unchecked
   * @param value Value of the parent checkbox
   * @param setValue Function to set the value of the parent checkbox
   */

  const checkAll = (
    items: Array<keyof Omit<SelectedItemsProp, 'sportsId' | 'sportDisciplinesId'>>,
    value: boolean,
    setValue: () => void
  ) => {
    const res = items.reduce(
      (acc, curr) => {
        acc[curr] = !value;
        return acc;
      },
      {} as Record<keyof Omit<SelectedItemsProp, 'sportsId' | 'sportDisciplinesId'>, boolean>
    );

    const newData = items.reduce(
      (acc, curr) => {
        // @ts-ignore
        acc[curr] = !value ? athleteDetail?.[curr] : originalData.current?.[curr];
        return acc;
      },
      {} as Record<keyof Omit<SelectedItemsProp, 'sportsId' | 'sportDisciplinesId'>, boolean>
    );
    setValue();
    setSelectedItems({ ...selectedItems, ...res });
    setValues({ ...values, ...newData, athletesId: confirmPrefill });
  };

  return (
    <>
      <FormDropDown
        {...props}
        onChange={(id) => {
          if (testResult) {
            setFieldValue(props.field.name, id);
            handleChangeData();
          } else {
            let originalValues = { ...values };
            Object.keys({ ...selectedItems }).forEach((key) => {
              if (selectedItems[key as keyof SelectedItemsProp]) {
                // @ts-ignore
                originalValues[key] = originalData.current?.[key];
              }
            });
            setValues({ ...originalValues, athletesId: id });
          }
          id ? setConfirmPrefill(id as number | null) : setConfirmClear(true);
          id && setSelectedItems(defaultSelectedItems);
          setAddress(false);
        }}
        loading={!athletes}
        options={options}
        form={form}
        onConfirm={handlePrefillChange}
        modalSize={{
          height: '800px',
          width: '1200px',
        }}
        onOpen={() => {
          setOpenedModal(true);
          setConfirmPrefill(athleteId);
        }}
        noAutomaticCloseSingleMode={true}
        rightContent={
          athleteId && athleteDetail ? (
            <RightContent>
              <Header athleteDetail={{ ...athleteDetail }} />
              <FullRowCell>
                <Alert />
              </FullRowCell>
              <Spacer $marginTop="1.5rem" />
              <Checkbox
                label={t('Internal comments')}
                description={athleteDetail.internalComments ? athleteDetail.internalComments : ''}
                checked={selectedItems['internalComments']}
                name="internalComments"
                onChange={(event) => handleCheckboxChange(event)}
                disabled={testResult}
              />
              {!testResult ? (
                <StatusMessage
                  label={t('Currently')}
                  value={
                    originalData.current?.internalComments
                      ? originalData.current.internalComments
                      : ''
                  }
                  type={
                    selectedItems['internalComments'] ? STATUS_TYPE_WARNING : STATUS_TYPE_NORMAL
                  }
                />
              ) : null}
              <Spacer $marginTop=".75rem" />
              <Checkbox
                label={t('Pool')}
                description={
                  athleteDetail?.pool && athleteDetail?.pool?.name ? athleteDetail?.pool?.name : ''
                }
                checked={selectedItems['poolsId']}
                name="poolsId"
                onChange={(event) => handleCheckboxChange(event)}
                disabled={testResult}
              />
              {!testResult ? (
                <StatusMessage
                  label={t('Currently')}
                  value={
                    pools?.find((item: Pool) => originalData.current?.poolsId === item.id)?.name ||
                    ''
                  }
                  type={selectedItems['poolsId'] ? STATUS_TYPE_WARNING : STATUS_TYPE_NORMAL}
                />
              ) : null}
              <ContentHalfItems
                athleteDetail={athleteDetail}
                title={t('Sports')}
                // @ts-ignore
                options={
                  sportsOptions !== null && sportsOptions && sportsOptions.flat().length > 0
                    ? sportsOptions.flat()
                    : [
                        {
                          label: t('Sport'),
                          name: 'sportsId',
                          value: '0',
                        },
                        {
                          label: t('Sport Discipline'),
                          name: 'sportDisciplinesId',
                          checked: selectedItems['sportDisciplinesId'] === '0-0',
                          onChange: handleRadioChange,
                          value: '0-0',
                          disabled: testResult,
                        },
                      ]
                }
              >
                {!testResult ? (
                  <FlexRow fullWidth spacing="0" marginTop="0.2rem">
                    <FlexColumn
                      spacing="0rem"
                      marginRight="0.5rem"
                      width="0"
                      verticalAlign="baseline"
                    >
                      <StatusMessage
                        label={t('Currently')}
                        value={
                          sports?.find((item: Sport) => originalData.current?.sportsId === item.id)
                            ?.name || ''
                        }
                        type={
                          selectedItems['sportDisciplinesId']
                            ? STATUS_TYPE_WARNING
                            : STATUS_TYPE_NORMAL
                        }
                      />
                    </FlexColumn>
                    <FlexColumn spacing="0rem" width="0" verticalAlign="baseline">
                      <StatusMessage
                        label={t('Currently')}
                        value={
                          sportsDisciplines?.find(
                            (item: SportDiscipline) =>
                              originalData.current?.sportDisciplinesId === item.id
                          )?.name || ''
                        }
                        type={
                          selectedItems['sportDisciplinesId']
                            ? STATUS_TYPE_WARNING
                            : STATUS_TYPE_NORMAL
                        }
                      />
                    </FlexColumn>
                  </FlexRow>
                ) : null}
              </ContentHalfItems>

              <ContentFullItem
                title={t('Address')}
                onClick={() =>
                  checkAll(
                    ['countriesId', 'street', 'address', 'zip', 'city', 'state'],
                    address,
                    () => setAddress(!address)
                  )
                }
                disabled={testResult}
                checkedAll={address}
                options={[
                  {
                    label: t('Country'),
                    description:
                      athleteDetail &&
                      (athleteDetail?.country as Country) &&
                      (athleteDetail?.country as Country).name
                        ? (athleteDetail?.country as Country).name
                        : null,
                    name: 'countriesId',
                    checked: selectedItems['countriesId'],
                    onChange: handleCheckboxChange,
                    currentValue:
                      countries?.find(
                        (item: Country) => originalData.current?.countriesId === item.id
                      )?.name || '',
                    disabled: testResult,
                    showStatus: !testResult,
                  },
                  {
                    label: t('Street and Number'),
                    description: athleteDetail?.street,
                    name: 'street',
                    checked: selectedItems['street'],
                    onChange: handleCheckboxChange,
                    currentValue: originalData.current?.street,
                    disabled: testResult,
                    showStatus: !testResult,
                  },
                  {
                    label: t('Additional Address Information'),
                    description: athleteDetail?.address,
                    name: 'address',
                    checked: selectedItems['address'],
                    onChange: handleCheckboxChange,
                    currentValue: originalData.current?.address,
                    disabled: testResult,
                    showStatus: !testResult,
                  },
                  {
                    label: t('ZIP Code'),
                    description: athleteDetail?.zip,
                    name: 'zip',
                    checked: selectedItems['zip'],
                    onChange: handleCheckboxChange,
                    currentValue: originalData.current?.zip,
                    disabled: testResult,
                    showStatus: !testResult,
                  },
                  {
                    label: t('City'),
                    description: athleteDetail?.city,
                    name: 'city',
                    checked: selectedItems['city'],
                    onChange: handleCheckboxChange,
                    currentValue: originalData.current?.city,
                    disabled: testResult,
                    showStatus: !testResult,
                  },
                  {
                    label: t('Region/State'),
                    description: athleteDetail?.state,
                    name: 'state',
                    checked: selectedItems['state'],
                    onChange: handleCheckboxChange,
                    currentValue: originalData.current?.state,
                    disabled: testResult,
                    showStatus: !testResult,
                  },
                ]}
              />
              <ContentFullItem
                title={t('Contact')}
                options={[
                  {
                    label: t('Phone'),
                    description: athleteDetail?.phone,
                    name: 'phone',
                    checked: selectedItems['phone'],
                    onChange: handleCheckboxChange,
                    disabled: true,
                    showStatus: false,
                    currentValue:
                      originalData.current?.athlete && originalData.current?.athlete?.phone
                        ? originalData.current?.athlete?.phone
                        : '',
                  },
                  {
                    label: t('Email'),
                    description: athleteDetail?.email,
                    name: 'email',
                    checked: selectedItems['email'],
                    onChange: handleCheckboxChange,
                    disabled: true,
                    showStatus: false,
                    currentValue:
                      originalData.current?.athlete && originalData.current?.athlete?.email
                        ? originalData.current?.athlete?.email
                        : '',
                  },
                ]}
              />
            </RightContent>
          ) : (
            <RightContent>
              <FlexRow fullWidth height="100%" horizontalAlign="center">
                <EmptyContent />
              </FlexRow>
            </RightContent>
          )
        }
      />
      <Modal
        onClose={() => {
          setConfirmClear(false);
        }}
        open={confirmClear}
        ariaLabel={t('Confirm clear')}
        onConfirm={handlePrefillChange}
      >
        {t("Would you like to also clear athlete's details?")}
      </Modal>
    </>
  );
};

export default memo(AthletePickerField);
