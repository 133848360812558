import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'core/components/Modal';
import Switch from 'core/components/Switch';

import { NotificationType, TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';

import { InvitationTogglesWrapper } from './styled';

interface Props {
  type: TeamMemberType;
  members?: TeamMemberData[];
  onClose: () => void;
}

const InvitationsModal: FC<Props> = ({ type, members, onClose }) => {
  const { t } = useTranslation();
  const [viaEmail, setViaEmail] = useState(false);
  const [viaSms, setViaSms] = useState(false);

  useEffect(() => {
    // We can pre-fill current values only if one member is being edited
    if (members?.length === 1) {
      setViaEmail(
        members[0].invitations.some((i) => i.type === NotificationType.EMAIL && !i.createdAt)
      );
      setViaSms(
        members[0].invitations.some((i) => i.type === NotificationType.SMS && !i.createdAt)
      );
    }
  }, [members]);

  const dataKey = type === TeamMemberType.INVITED ? 'invitedMembers' : 'assignedMembers';
  const formik = useFormikContext<TeamFormData>();

  const change = useCallback(() => {
    const memberIds = new Set(members?.map((m) => m.usersId) || []);
    formik.setValues({
      ...formik.values,
      [dataKey]: formik.values[dataKey].map((member) => {
        if (!memberIds.has(member.usersId)) {
          return member;
        }

        const invitations = member.invitations.filter((i) => !!i.createdAt);

        if (viaEmail) {
          invitations.push({ type: NotificationType.EMAIL });
        }
        if (viaSms) {
          invitations.push({ type: NotificationType.SMS });
        }

        return {
          ...member,
          invitations,
        };
      }),
    });
  }, [dataKey, formik, members, viaEmail, viaSms]);

  return (
    <Modal
      ariaLabel={t('Team Member invitation editing dialog')}
      title={t('Planned Invitations')}
      open={!!members}
      onClose={() => {
        setViaEmail(false);
        setViaSms(false);
        onClose();
      }}
      onConfirm={() => change()}
      onClear={() => {
        setViaEmail(false);
        setViaSms(false);
      }}
    >
      <InvitationTogglesWrapper>
        <p>
          {t(
            'If the invitee does not have a phone number, an email is sent instead. Invitations are sent after saving.'
          )}
        </p>

        <Switch
          id="invitation-email"
          name="invitation-email"
          label={t('Email')}
          value={viaEmail}
          onChange={setViaEmail}
        />

        <Switch
          id="invitation-sms"
          name="invitation-sms"
          label={t('SMS')}
          value={viaSms}
          onChange={setViaSms}
        />
      </InvitationTogglesWrapper>
    </Modal>
  );
};

export default InvitationsModal;
