import { push } from 'connected-react-router';
import { useFormikContext } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { entities } from 'app/entity';
import DummyField from 'core/components/DummyField';
import { FormContext } from 'core/components/Form';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import PaperContainer, { Icon } from 'core/components/PaperContainer';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import Cost from 'finance/models/Cost';
import { DatagridDCOInvoice } from 'finance/models/DCOInvoice';

import LinkCostsModal from '../LinkCostsModal';

import InvoicesTable from './InvoicesTable';
import InvoicingCodes from './InvoicingCodes';

interface RequiredFormData {
  costsId: number | null;
}

interface Props {
  createNewCostLink?: string;
  viewMode?: boolean;
  showMissionLink?: boolean;
  dcoInvoices?: DatagridDCOInvoice[];
  icons?: Icon[];
  showInvoicingCode?: boolean;
}

/**
 * Reusable paper for Mission, Test or other detail screens that are linked to finance modules like Costs
 *
 * Needs to be used as child of <Form> with field costsId.
 */
const LinkedFinancesPaper = <FormData extends RequiredFormData>({
  createNewCostLink,
  viewMode,
  showInvoicingCode,
  showMissionLink,
  dcoInvoices,
  icons,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [costAssigningOpen, setCostAssigningOpen] = useState(false);

  const costsEnabled = useBoolClientOption('enableCosts');
  const invoicesEnabled = useBoolClientOption('enableDcoInvoices');

  const canSeeInvoices = usePermission('dcoInvoices:find');
  const canSeeCosts = usePermission('costs:find');

  const costsAccessible = costsEnabled && canSeeCosts;
  const invoicesAccessible = invoicesEnabled && canSeeInvoices;

  const anyAccessible = costsAccessible || invoicesAccessible;

  const costInclude = useIncludeOptions('costsId') as Cost[];
  const includedCost: Cost | undefined = Array.isArray(costInclude) ? costInclude[0] : costInclude;

  const { values, setFieldValue } = useFormikContext<FormData>();
  const costsId =
    includedCost?.deleted && includedCost.id === values?.costsId ? null : values?.costsId;

  const { isFieldInViewMode, diffMode: isBulkEdit } = useContext(FormContext);
  const costsIdViewMode = viewMode !== undefined ? viewMode : isFieldInViewMode('costsId');

  if (!anyAccessible) return null;

  return (
    <FullRowCell>
      <PaperContainer icons={icons} title={t('Finance')}>
        <Row>
          {costsAccessible && (
            <FullRowCell>
              <DummyField
                label={t('Costs')}
                value={costsId ? t('Costs #{{id}}', { id: costsId }) : t('Not Linked')}
                endAdornment={
                  <>
                    {costsId && (
                      <IconButton
                        icon="link"
                        tooltip={t('Open Linked Costs')}
                        onClick={() => dispatch(push(entities.cost.urls().detail(costsId)))}
                      />
                    )}
                    {!costsIdViewMode && (
                      <IconButton
                        icon="edit"
                        tooltip={t('Edit Linked Costs')}
                        onClick={() => {
                          setCostAssigningOpen(true);
                        }}
                      />
                    )}
                  </>
                }
              />
            </FullRowCell>
          )}

          {!isBulkEdit && invoicesAccessible && (
            <FullRowCell>
              <InvoicesTable invoices={dcoInvoices || []} />
            </FullRowCell>
          )}

          {showInvoicingCode && invoicesAccessible && (
            <FullRowCell>
              <InvoicingCodes />
            </FullRowCell>
          )}
        </Row>
      </PaperContainer>

      {costsAccessible && (
        <LinkCostsModal
          open={costAssigningOpen}
          setOpen={setCostAssigningOpen}
          createNewCostLink={createNewCostLink}
          onPickedCost={(id) => setFieldValue('costsId', id)}
          costsId={costsId}
          showMissionLink={showMissionLink}
        />
      )}
    </FullRowCell>
  );
};

export default LinkedFinancesPaper;
