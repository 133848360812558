import classNames from 'classnames';
import { FC, ReactNode, useState } from 'react';

import IconButton from 'core/components/IconButton';
import MaterialIcon from 'core/components/MaterialIcon';
import StyledIcon from 'core/components/StyledIcon';

import Collapse from '../Collapse';
import { StyledTabIcon } from '../StyledTabIcon';
import Tooltip from '../Tooltip';

import {
  BadgePrimary,
  CardInnerSection,
  CollapseIconWrapper,
  Content,
  FirstLevelTitle,
  Headline,
  IconsContainer,
  InfoIcon,
  StyledCard,
  ToggleContainer,
  ToggleTitle,
} from './styled';

export interface Icon {
  onClick?: () => void;
  disabled?: boolean;
  title?: string;
  type?: string;
  icon: string;
  to?: string;
}

export interface Props {
  /** Control collapse state from outside */
  onCollapseRequest?: () => void;
  customTitleAction?: ReactNode;
  titleActionPosition?: 'left' | 'right';
  badgePrimary?: string;
  collapsible?: boolean;
  collapse?: boolean;
  hasError?: boolean;
  title?: ReactNode;
  icons?: Icon[];
  fullHeight?: boolean;
  headerInfo?: ReactNode;
  className?: string;
  children?: ReactNode;
  overflowX?: string;
  overflowY?: string;
  iconBottom?: Icon;
  maxWidth?: string;
  maxHeight?: string;
  marginBottom?: string;
  leftIcon?: ReactNode;
  tooltipsAppendTo?: 'parent' | Element | ((ref: Element) => Element);
}

const PaperContainer: FC<Props> = ({
  collapsible = false,
  onCollapseRequest,
  customTitleAction,
  titleActionPosition,
  hasError = false,
  collapse = true,
  fullHeight = false,
  leftIcon,
  badgePrimary,
  className,
  headerInfo,
  children,
  title,
  icons,
  overflowX,
  overflowY,
  iconBottom,
  maxWidth,
  maxHeight,
  marginBottom,
  tooltipsAppendTo,
}) => {
  const [collapsed, setCollapsed] = useState(!!collapse);
  const isCollapsed = onCollapseRequest ? collapse : collapsed;

  const iconsContainer = (icons?: Icon[]) => {
    return (
      icons && (
        <IconsContainer>
          {icons.map((icon) => (
            <IconButton
              key={icon.icon}
              onClick={icon.onClick}
              disabled={icon.disabled}
              type={icon.type || 'button'}
              icon={icon.icon}
              to={icon.to}
              tooltip={icon.title}
            />
          ))}
        </IconsContainer>
      )
    );
  };

  const headlineContent = (
    <>
      {collapsible && title && (
        <ToggleTitle $hasError={hasError} $fullWidth={titleActionPosition === 'right'}>
          {leftIcon}
          {title}

          {headerInfo && (
            <Tooltip content={headerInfo} appendTo={tooltipsAppendTo}>
              <MaterialIcon icon="info_outline" />
            </Tooltip>
          )}
        </ToggleTitle>
      )}
      {!collapsible && title && (
        <FirstLevelTitle $hasError={hasError} $fullWidth={titleActionPosition === 'right'}>
          {leftIcon}
          {title}

          {headerInfo && (
            <Tooltip content={headerInfo} appendTo={tooltipsAppendTo}>
              <InfoIcon />
            </Tooltip>
          )}
        </FirstLevelTitle>
      )}
      {badgePrimary && <BadgePrimary>{badgePrimary}</BadgePrimary>}

      {hasError && <StyledTabIcon color="error" icon="warning" />}

      {collapsible && (
        <CollapseIconWrapper $right={!icons || icons.length === 0}>
          <StyledIcon right isOpen={!isCollapsed}>
            <MaterialIcon icon="expand_more" />
          </StyledIcon>
        </CollapseIconWrapper>
      )}
    </>
  );

  return (
    <StyledCard
      className={classNames(className, 'paper-container', {
        'full-height-card': fullHeight,
      })}
      $overflowX={overflowX}
      $overflowY={overflowY}
      $maxWidth={maxWidth}
      $maxHeight={maxHeight}
      $marginBottom={marginBottom}
    >
      <CardInnerSection $fullHeight={fullHeight}>
        {(headlineContent || icons) && (
          <Headline>
            {collapsible ? (
              <ToggleContainer
                onClick={() =>
                  onCollapseRequest ? onCollapseRequest() : setCollapsed(!isCollapsed)
                }
              >
                {headlineContent}
              </ToggleContainer>
            ) : (
              headlineContent
            )}

            {iconsContainer(icons)}
            {customTitleAction}
          </Headline>
        )}

        {collapsible ? (
          <Collapse isOpen={!collapsible || !isCollapsed}>
            <Content $fullHeight={fullHeight} $paddingTop={Boolean(title || icons)}>
              {children}
            </Content>
          </Collapse>
        ) : (
          <Content $fullHeight={fullHeight} $paddingTop={Boolean(title || icons)}>
            {children}
          </Content>
        )}
        {iconBottom && (
          <IconButton
            key={iconBottom.icon}
            onClick={iconBottom.onClick}
            disabled={iconBottom.disabled}
            type={iconBottom.type || 'button'}
            icon={iconBottom.icon}
            to={iconBottom.to}
            tooltip={iconBottom.title}
            tooltipsAppendTo={tooltipsAppendTo}
          />
        )}
      </CardInnerSection>
    </StyledCard>
  );
};

export default PaperContainer;
