import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { CustomFilter, Filter, FilterDelimiter } from '../props';

const getDefaultValue = (filter: Exclude<Filter, FilterDelimiter>) => {
  // But fall back to predefined default so there is always a value
  return filter.defaultValue;
};

export const useDefaultFilterValues = (filters?: Record<string, Filter>) =>
  useMemo(
    () =>
      Object.fromEntries(
        Object.entries(filters || {})
          .filter(([, filter]) => filter.type !== 'delimiter')
          .map(([id, filter]) => [id, getDefaultValue(filter as CustomFilter)])
      ),
    [filters]
  );

const useDefaultGridConfiguration = (originalColumnDefs: ColDef<any>[]) => {
  const originalColumnDefsString = JSON.stringify(originalColumnDefs);

  return useMemo(() => {
    const columnsWithField = JSON.parse(originalColumnDefsString).filter(
      (c: ColDef<any>) => !!c.field && !c.lockPinned
    );
    const hide: { [colId: string]: boolean } = {};
    const position: { [colId: string]: number } = {};

    let positionIndex = 0;
    for (const c of columnsWithField) {
      hide[c.field!] = ('hide' in c && c.hide === true) || false;
      position[c.field!] = positionIndex;
      positionIndex += 1;
    }

    return {
      position,
      hide,
      width: {},
      pinned: {},
      duplicity: undefined,
    };
  }, [originalColumnDefsString]);
};

export default useDefaultGridConfiguration;
