import { FormikContextType, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import { Option } from 'core/components/DropDown';
import DummyField from 'core/components/DummyField';
import { FlexCell } from 'core/components/FlexUtils';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';
import useActions from '../useActions';

import BasicInfoCell from './BasicInfoCell';
import useGetStatusName from './useGetStatusName';

interface Props {
  type: TeamMemberType;
  user: TeamMemberData & { id: number };
  index: number;
  roles?: Option[];
  availabilities: { [usersId: number]: string };
  triggers: ReturnType<typeof useActions>['1'];
  formik: FormikContextType<TeamFormData & { dateRange: { from: Date; to: Date } | null }>;
  isViewMode: boolean;
  limitedAssignmentAllowed: boolean;
  actionsDisabled: boolean;
}

const MembersCardItem: FC<Props> = ({
  type,
  user,
  index,
  roles,
  availabilities,
  triggers,
  formik,
  isViewMode,
  limitedAssignmentAllowed,
  actionsDisabled,
}) => {
  const { t } = useTranslation();
  const getStatusName = useGetStatusName();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    // When field value is set by setFielValue, it doesn't trigger touched event
    // https://github.com/jaredpalmer/formik/issues/1833
    setFieldTouched(`${type}Members[${index}].roles`, true);
  }, [index, setFieldTouched, type]);

  return (
    <>
      <BasicInfoCell
        user={user}
        availabilities={availabilities}
        triggers={triggers}
        viewMode={isViewMode}
        disableInvitations={type !== TeamMemberType.INVITED}
        actionsDisabled={actionsDisabled}
      />

      <FlexCell flex={2} block minWidth="6rem">
        <DummyField
          label={t('Status')}
          value={
            type === TeamMemberType.INVITED
              ? getStatusName(user.status)
              : getStatusName(TeamMemberStatus.CONFIRMED)
          }
        />
      </FlexCell>

      <FlexCell flex={6} block minWidth="8rem">
        <Field
          component={FormTextfield}
          label={t('Statement')}
          name={`${type}Members[${index}].statement`}
          viewMode
          enableVerticalScroll
        />
      </FlexCell>

      <FlexCell flex={2} block minWidth="8rem">
        <Field
          component={FormDropDown}
          label={t('Roles')}
          name={`${type}Members[${index}].roles`}
          fast={false}
          onChange={(selection: AccreditationsRole[]) => {
            const currentTeamMembersData = (
              formik.values[`${type}Members` as keyof TeamFormData] as TeamMemberData[]
            )[index!];

            if (
              !limitedAssignmentAllowed ||
              !currentTeamMembersData.alreadySavedRoles ||
              (currentTeamMembersData.alreadySavedRoles &&
                currentTeamMembersData.alreadySavedRoles.every((it) => selection.includes(it)))
            ) {
              setFieldValue(
                `${type}Members[${index}].roles`,
                // Lead DCO also has to be a DCO
                selection.includes(AccreditationsRole.LEAD_DCO) &&
                  !selection.includes(AccreditationsRole.DCO)
                  ? [...selection, AccreditationsRole.DCO]
                  : (selection as string[])
              );

              // When field value is set by setFielValue, it doesn't trigger touched event
              // https://github.com/jaredpalmer/formik/issues/1833
              setTimeout(() => setFieldTouched(`${type}Members[${index}].roles`, true), 100);
            }
          }}
          viewMode={limitedAssignmentAllowed ? false : undefined}
          options={
            roles?.filter((role) => {
              if (role === 'SEPARATOR') return 'SEPARATOR';
              return user.allowedRoles?.includes(role.id as AccreditationsRole);
            }) || []
          }
        />
      </FlexCell>
    </>
  );
};

export default MembersCardItem;
