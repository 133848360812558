import Tippy, { TippyProps } from '@tippyjs/react';
import { ICellRendererParams } from 'ag-grid-community';
import { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import 'tippy.js/themes/light.css';

import theme from 'app/theme';
import { FlexColumn, FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';

import {
  PopoverContent,
  PopoverDescriptionItem,
  PopoverIcon,
  PopoverTarget,
  PopoverTitle,
} from './styled';

type Props<T, V> = Omit<TippyProps, 'content'> & {
  options?: {
    items?: PopoverBlockType[];
  } & PopoverHeaderType;
  content?: (data?: T) => ReactNode;
  params?: ICellRendererParams<T, V>;
  children?: ReactNode;
};
export type PopoverHeaderType = {
  title?: string;
  link?: string;
  customIcon?: ReactNode;
  $titleColor?: string;
};
export type PopoverBlockType = {
  label: string;
  value: string;
  link?: string;
  customIcon?: ReactNode;
};

export const defaultTippyOptions = {
  arrow: false,
  placement: 'right',
  interactiveBorder: 0,
  animation: 'scale-subtle',
  theme: 'light',
} as const;

const PopoverCellRenderer = <T, V>({
  children,
  content,
  options,
  params,
  ...props
}: PropsWithChildren<Props<T, V>>) => {
  return (
    <Tippy
      interactive
      appendTo={document.body}
      content={
        options?.title || options?.items ? (
          <PopoverContent>
            <PopoverHeader
              title={options.title}
              link={options.link}
              customIcon={options.customIcon}
              $titleColor={options.$titleColor}
            />
            {options && options?.items && options?.items.length > 0 && (
              <>{options?.items?.map((item, index) => <PopoverBlock key={index} {...item} />)}</>
            )}
          </PopoverContent>
        ) : content ? (
          content(params?.data)
        ) : null
      }
      {...defaultTippyOptions}
      {...props}
    >
      <PopoverTarget>{children}</PopoverTarget>
    </Tippy>
  );
};

export const PopoverBlock = (props: PopoverBlockType) => {
  if (!props.value) return null;

  return (
    <FlexColumn verticalAlign="start" spacing="0" marginTop="0.5rem">
      <FlexRow spacing="0rem">
        <PopoverTitle $color={theme.color.textSecondary}>{props.label}</PopoverTitle>
      </FlexRow>
      <FlexRow horizontalAlign="space-between" fullWidth>
        <PopoverDescriptionItem $color={theme.color.baseFontColor}>
          {props.value}
        </PopoverDescriptionItem>
        {(props.link || props.customIcon) && (
          <PopoverIcon>
            {props.link ? (
              <Link to={props.link} target="_blank" rel="noreferrer">
                <MaterialIcon icon="launch" />
              </Link>
            ) : null}
            {props.customIcon ? props.customIcon : null}
          </PopoverIcon>
        )}
      </FlexRow>
    </FlexColumn>
  );
};

export const PopoverHeader = (props: PopoverHeaderType) => {
  return props.title ? (
    <FlexRow horizontalAlign="space-between" spacing="0rem">
      <PopoverTitle $color={props.$titleColor} $fontSize="1rem">
        {props.title}
      </PopoverTitle>
      <PopoverIcon>
        {props.link ? (
          <Link to={props.link} target="_blank" rel="noreferrer">
            <MaterialIcon icon="launch" />
          </Link>
        ) : null}
        {props.customIcon ? props.customIcon : null}
      </PopoverIcon>
    </FlexRow>
  ) : null;
};

export default PopoverCellRenderer;
