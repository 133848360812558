import { FC, memo, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from 'core/components/IconButton';
import Media from 'core/components/Media';
import WarnIcon from 'core/components/WarnIcon';
import useAppSelector from 'core/hooks/useAppSelector';

import Hamburger from './Hamburger';
import Navigation, { MainControl, SecondaryControl } from './Navigation';
import { HeaderWrapper, Primary, StyledHeading } from './styled';

interface Props {
  backControl?: { to: string; text: string };
  secondaryControls?: SecondaryControl[];
  mainControls?: MainControl[];
  title: ReactNode;
}

export const SEARCH_PORTAL_REF_ID = 'pageHeaderContent';

const Header: FC<Props> = ({ secondaryControls, mainControls, backControl, title }) => {
  const [mobileMenuOpened, openMobileMenu] = useState(false);
  const user = useAppSelector(({ core }) => core.user);
  const { t } = useTranslation();

  const originalUser = user?.originUser;
  const fullName = user?.fullName;

  return (
    <>
      <Media query="mobileTablet">
        <Hamburger isOpen={mobileMenuOpened} toggle={() => openMobileMenu(!mobileMenuOpened)} />
      </Media>
      <HeaderWrapper id="page-header">
        <Primary id={SEARCH_PORTAL_REF_ID}>
          <Media query="mobileTablet">
            {!mobileMenuOpened && (
              <IconButton
                badge={originalUser && <WarnIcon icon="warning" />}
                onClick={() => openMobileMenu(true)}
                icon="menu"
                tooltip={
                  originalUser ? t('You are logged as {{fullName}}', { fullName }) : undefined
                }
              />
            )}
          </Media>

          {backControl && (
            <Media query="desktop">
              <IconButton icon="arrow_back" to={backControl.to} tooltip={backControl.text} />
            </Media>
          )}

          <hr />
        </Primary>

        {title && <StyledHeading>{title}</StyledHeading>}

        <Navigation mainControls={mainControls} secondaryControls={secondaryControls} />
      </HeaderWrapper>
    </>
  );
};

export default memo(Header);
