import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { deleteView, loadProfile } from 'core/actions';
import useUrlMultiState from 'core/hooks/useUrlMultiState';
import { UserView } from 'core/models/UserView';

import { COLUMNS_DEFAULT_VALUE, UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import { getUrlConfiguration } from '../../hooks/useDefaultViewConfiguration';
import { FilterValue } from '../../props';

import { VIEW_URL_KEYS } from './Header';

const useDeleteView = (
  onApplyView: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void,
  userViews: UserView[],
  reloadUserViews: () => void
) => {
  const dispatch = useDispatch();
  const { urlStatesValues: viewConfiguration } = useUrlMultiState(
    VIEW_URL_KEYS,
    COLUMNS_DEFAULT_VALUE
  );

  const activeView = useMemo(() => {
    if (!viewConfiguration?.activeView) {
      return null;
    }

    return (userViews || []).find((it) => it.id === viewConfiguration.activeView);
  }, [viewConfiguration, userViews]);

  return useCallback(
    (viewId: number) => {
      const defaultView = userViews.find((it) => it.default);

      dispatch(
        deleteView(viewId, () => {
          reloadUserViews();

          if (defaultView?.id && defaultView.id === viewId) {
            // If default is removed we have to reload profile
            dispatch(loadProfile());
          }

          if (activeView && activeView?.id === viewId) {
            if (defaultView && defaultView.id !== viewId) {
              const { filters, ...rest } = defaultView.data;
              onApplyView(filters || {}, getUrlConfiguration(rest, defaultView?.id));
            } else {
              onApplyView({}, { hide: {}, activeView: undefined });
            }
          }
        })
      );
    },
    [dispatch, activeView, reloadUserViews, userViews, onApplyView]
  );
};

export default useDeleteView;
