import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { AlertMessage } from 'core/components/Alert';
import { SimpleOption } from 'core/components/DropDown';
import DummyDropdownField from 'core/components/DummyDropdownField';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import { BLACKLISTS_TYPE } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/BlacklistsModal';
import useAthleteOptionsMapping from 'personnel/hooks/useAthleteOptionsMapping';
import useTeamBlacklists from 'personnel/hooks/useTeamBlacklists';
import Athlete, { AthleteDatagrid, ATHLETES_RESOURCE } from 'personnel/models/Athlete';
import BlacklistAthletes from 'personnel/models/BlacklistAthletes';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';
import EntityBlacklistedMessage from 'planning/components/EntityBlacklistedMessage';
import { TestFormData } from 'planning/containers/TestDetailPage/hooks/useTestSchema';
import AthletePickerField from 'planning/containers/TestResultDetailPage/steps/AthleteStep/AthletePickerField';

const AthleteField: FC = () => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<TestFormData>();
  const hasGetPermission = usePermission('athletes:get');

  const includedAthletes: Athlete[] = useIncludeOptions('athletesId') as Athlete[];

  const { athletesId, sportDisciplinesId, sportsId } = values;

  const includedAthlete =
    includedAthletes.length > 0 && includedAthletes[0].id === athletesId
      ? includedAthletes[0]
      : undefined;

  const { data: athletes, reload: loadAthletes } = useResources<AthleteDatagrid>(
    ATHLETES_RESOURCE,
    {
      autoload: !!(!includedAthlete && athletesId),
      params: { active: true },
    }
  );

  const athleteOptionsMapping = useAthleteOptionsMapping();

  const athleteOptions = useMemo(() => {
    const seenIds = new Set();
    const options: SimpleOption[] = [];

    for (const athletesGroup of [includedAthletes, athletes || []]) {
      for (const athlete of athletesGroup) {
        if (seenIds.has(athlete.id)) {
          continue;
        }
        options.push(athleteOptionsMapping(athlete));
        seenIds.add(athlete.id);
      }
    }

    return options;
  }, [athletes, includedAthletes, athleteOptionsMapping]);

  const chosenAthlete = useMemo(() => {
    return includedAthlete || (athletes || []).find((i) => i.id === athletesId);
  }, [athletes, athletesId, includedAthlete]);

  const genderOptions = [
    { name: t('Male'), id: 'm' },
    { name: t('Female'), id: 'f' },
    { name: t('Other'), id: 'x' },
  ];

  const athleteAlertMessage: AlertMessage = useMemo(
    () => ({
      value: t('You have blacklisted the selected Athlete.'),
    }),
    [t]
  );

  const athleteBlacklists: BlacklistAthletes[] = useTeamBlacklists(
    BLACKLISTS_TYPE.ATHLETES,
    athletesId,
    { id: 'athlete', message: athleteAlertMessage }
  ) as BlacklistAthletes[];

  const sportDisciplineAlertMessage: AlertMessage = useMemo(
    () => ({
      value: t("You have blacklisted the Athlete's Sport Discipline."),
    }),
    [t]
  );

  const sportDisciplinesBlacklists: BlacklistSportDisciplines[] = useTeamBlacklists(
    BLACKLISTS_TYPE.SPORT_DISCIPLINES,
    chosenAthlete?.sportDisciplines?.map((s) => s.id), // TODO sportDisciplines do not exist
    {
      id: 'athleteSportDiscipline',
      message: sportDisciplineAlertMessage,
    }
  ) as BlacklistSportDisciplines[];

  const feedbackMsg: string | null = useMemo(() => {
    if (chosenAthlete) {
      if (
        chosenAthlete.sportDisciplines?.length === 0 ||
        (chosenAthlete as AthleteDatagrid).sportDisciplinesId?.length === 0
      ) {
        return t('Athlete details do not contain any Sport Discipline.');
      } else if (
        sportsId &&
        (chosenAthlete.sportDisciplines?.every((s) => s.sportsId !== sportsId) ||
          (chosenAthlete as AthleteDatagrid).sportsId?.every((s) => s !== sportsId))
      ) {
        return t('Athlete details contain different Sports.');
      } else if (
        sportDisciplinesId &&
        (chosenAthlete.sportDisciplines?.every((s) => s.id !== sportDisciplinesId) ||
          (chosenAthlete as AthleteDatagrid).sportDisciplinesId?.every(
            (s) => s !== sportDisciplinesId
          ))
      ) {
        return t('Athlete details contain different Sport Disciplines.');
      }
    }
    return null;
  }, [chosenAthlete, sportDisciplinesId, sportsId, t]);

  return (
    <FullRowCell>
      <Row>
        <Cell desktopColumns={8} phoneColumns={3} tabletColumns={5}>
          <FlexRow>
            <FlexCell block flex={1}>
              <Field
                disabledLink={
                  (chosenAthlete && chosenAthlete.deleted && t('Athlete is deleted')) || undefined
                }
                dummyValue={chosenAthlete?.fullName}
                options={athleteOptions || []}
                label={t('Athlete')}
                name="athletesId"
                fast={false}
                onOpen={() => loadAthletes(false)}
                loading={!athletes}
                onClick
                single
                component={AthletePickerField}
                onChange={(id: number | null, item: SimpleOption | null) => {
                  setValues({
                    ...values,
                    athletesId: id,
                    plannedGender: id ? null : values.plannedGender,
                    poolsId: (item && item.extra && item.extra.poolsId) || values.poolsId,
                  });
                }}
              />
            </FlexCell>
            {hasGetPermission && (
              <FlexCell>
                <IconButton
                  data-cy="athleteLink"
                  to={
                    chosenAthlete?.id ? entities.athlete.urls().detail(chosenAthlete.id) : undefined
                  }
                  icon="link"
                  tooltip={chosenAthlete?.id ? t('Open Athlete detail') : t('No Athlete selected')}
                />
              </FlexCell>
            )}
          </FlexRow>
        </Cell>
        <Cell desktopColumns={4} phoneColumns={1} tabletColumns={3}>
          {chosenAthlete ? (
            <DummyDropdownField
              options={genderOptions}
              value={chosenAthlete.gender || null}
              label={t('Sport Gender')}
            />
          ) : (
            <Field
              fast={false}
              component={FormDropDown}
              options={genderOptions}
              label={t('Sport Gender')}
              name="plannedGender"
              single
            />
          )}
        </Cell>
      </Row>
      <EntityBlacklistedMessage entityName={t('Athlete')} blacklists={athleteBlacklists} />

      <EntityBlacklistedMessage
        entityName={t('Sport Discipline')}
        blacklists={sportDisciplinesBlacklists}
        message={(entityName, userNames) =>
          t("Athlete's {{entityName}} is blacklisted for {{userNames}}.", {
            entityName,
            userNames,
          })
        }
      />

      {feedbackMsg && <FeedbackMessage $type="warning">{feedbackMsg}</FeedbackMessage>}
    </FullRowCell>
  );
};

export default AthleteField;
