import * as Sentry from '@sentry/browser';
import {
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
} from 'ag-grid-community';
import merge from 'lodash/merge';
import { useCallback, useMemo, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import useUrlMultiState from 'core/hooks/useUrlMultiState';
import { UrlOperationValue } from 'core/models/UserView';

import {
  ColumnOperations,
  CustomColumnConfiguration,
} from '../Toolbar/CustomizeViews/tabs/Columns';

export const COLUMNS_DEFAULT_VALUE = {
  width: {},
  pinned: {},
  position: {},
  hide: {},
};
export const COLUMN_KEYS: (keyof ColumnOperations)[] = ['width', 'pinned', 'position', 'hide'];

export type UrlColumnConfiguration = {
  width?: { [colId: string]: UrlOperationValue };
  pinned?: { [colId: string]: UrlOperationValue };
  position?: { [colId: string]: UrlOperationValue };
  hide?: { [colId: string]: UrlOperationValue };
  duplicity?: boolean;
  activeView?: number;
};

const useColumnConfiguration = () => {
  const { urlStatesValues: operationValues, setUrlStatesValues: setOperationValues } =
    useUrlMultiState(COLUMN_KEYS, COLUMNS_DEFAULT_VALUE);

  const columnConfiguration: CustomColumnConfiguration = useMemo(() => {
    return Object.entries(operationValues).reduce(
      (acc, [op, OpValue]) => {
        if (typeof OpValue === 'object' && OpValue !== null && !Array.isArray(OpValue)) {
          Object.entries(OpValue).forEach(([colId, opColValue]) => {
            acc[colId] = {
              ...(acc[colId] || {}),
              [op]: opColValue as string | boolean | number | null | undefined,
            };
          });
        }
        return acc;
      },
      {} as { [key: string]: { [colId: string]: number | string | boolean | null | undefined } }
    );
  }, [operationValues]);

  const uncommittedConfigurationChanges = useRef<CustomColumnConfiguration>({});
  const [commitConfigurationChange] = useDebouncedCallback(() => {
    const merged = merge({}, columnConfiguration, uncommittedConfigurationChanges.current);

    // We save configuration based on operation keys, because we save space in the URL
    const remapToUrlForm: UrlColumnConfiguration = Object.entries(merged).reduce(
      (acc, [colId, colValue]) => {
        COLUMN_KEYS.forEach((operation) => {
          // We remove undefined columns like e.q. bulk actions checkbox, rowActions
          if (operation in colValue && colId && colId !== 'undefined') {
            const operationValue = colValue[operation];
            if (operation === 'hide') {
              acc.hide = {
                ...acc.hide,
                [colId]: Boolean(operationValue),
              };
            } else if (operation === 'duplicity') {
              acc.duplicity = Boolean(operationValue);
            } else {
              acc[operation] = {
                ...(acc[operation] || {}),
                [colId]: operationValue || operationValue === 0 ? operationValue : null,
              };
            }
          }
        });

        return acc;
      },
      {} as UrlColumnConfiguration
    );

    setOperationValues(remapToUrlForm);

    uncommittedConfigurationChanges.current = {};
  }, 1000);

  const onConfigurationChange = useCallback(
    (changes: CustomColumnConfiguration) => {
      merge(uncommittedConfigurationChanges.current, changes);
      commitConfigurationChange();
    },
    [commitConfigurationChange]
  );

  const handleColumnVisible = ({
    visible,
    column,
    columns,
    source,
    ...rest
  }: ColumnVisibleEvent) => {
    // We process only hiding by dragging and manual selection of columns
    if (!['api', 'uiColumnDragged', 'uiColumnMoved'].includes(source)) return;

    const colId = column?.getColId() as string;
    if (!colId) {
      if (Array.isArray(columns) && columns.length > 0) {
        columns.forEach((col) =>
          handleColumnVisible({ visible, column: col, columns: null, source, ...rest })
        );
      } else {
        Sentry.withScope((scope) => {
          scope.setExtra('params', { column, columns, ...rest });

          Sentry.captureMessage(
            'Column configuration could not be saved.',
            Sentry.Severity.Warning
          );
        });
      }
      return;
    }

    onConfigurationChange({ [colId]: { hide: !visible } });
  };

  const handleColumnPinned = ({ pinned, column }: ColumnPinnedEvent) => {
    const colId = column?.getColId() as string;
    const colPinned = pinned as 'left' | 'right' | null;
    onConfigurationChange({ [colId]: { pinned: colPinned } });
  };

  const handleColumnResized = ({ source, finished, column }: ColumnResizedEvent) => {
    // We care only about resize that was caused from the UI
    if (!source.startsWith('ui') || !finished) return;

    const width = column?.getActualWidth();
    const colId = column?.getColId() as string;
    onConfigurationChange({ [colId]: { width } });
  };

  const handleColumnMoved = ({ columnApi }: ColumnMovedEvent) => {
    const columns = columnApi
      .getAllGridColumns()
      .filter((c) => !c.getColDef().lockPosition)
      .reduce((acc, c, index) => {
        acc[c.getColId()] = { position: index };
        return acc;
      }, {} as CustomColumnConfiguration);
    onConfigurationChange(columns);
  };

  return {
    columnConfiguration,
    handleColumnMoved,
    handleColumnPinned,
    handleColumnResized,
    handleColumnVisible,
  } as const;
};

export default useColumnConfiguration;
