import { ColumnApi, GridApi, IDatasource } from 'ag-grid-community';
import { push } from 'connected-react-router';
import deepClone from 'lodash/clone';
import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';
import isEqual from 'lodash/isEqual';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { dataGridSaveEntityParams } from 'core/actions';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import { mergeUrl, parse } from 'core/functions/qs';
import { coercePossibleNumbers } from 'core/hooks/useUrlState';

import { Filter, FilterValue, HandleSortCallback } from '../props';

import { UrlColumnConfiguration } from './useColumnConfiguration';

export const objectPropsNotEqual = (
  firstObj: { [key: string]: any },
  secObj: { [key: string]: any }
) => Object.keys(firstObj).some((key) => !isEqual(firstObj[key], secObj[key]));

const useUrlState = (
  setSelectedRows: (rows: []) => void,
  getDataSource: (
    filters: Record<string, Filter> | undefined,
    values: Record<string, FilterValue>
  ) => IDatasource,
  agGridApiRef: MutableRefObject<GridApi<any> | undefined>,
  agColumnApiRef: MutableRefObject<ColumnApi | undefined>,
  defaultFilterValues: { [id: string]: FilterValue },
  disableViews: boolean,
  filters?: { [id: string]: Filter }
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const alreadyFetchedUrl = useRef<string | undefined>(undefined);

  const saveEntityParams = useCallback(
    (urlParams: string) =>
      dispatch(
        dataGridSaveEntityParams({
          endpoint: location.pathname,
          params: urlParams,
        })
      ),
    [dispatch, location.pathname]
  );

  window.qsParse = parse;

  const urlFilters = JSON.stringify(parse(location.search.substring(1)).filters || {});
  const possibleFilters = JSON.stringify(Object.keys(filters || {}));
  const filterValues = useMemo(() => {
    const persistedFilters = JSON.parse(urlFilters) as { [key: string]: string | string[] | null };
    return Object.entries(persistedFilters || {}).reduce(
      (acc, [id, value]) => {
        const persistedValue = coercePossibleNumbers(value);
        // 0 is falsy but a valid value
        if (persistedValue || persistedValue === 0) {
          acc[id] = persistedValue;
        }
        return acc;
      },
      {} as { [key: string]: FilterValue }
    );
    // Indirect dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [possibleFilters, urlFilters]);

  const reloadData = useCallback(
    (force: boolean = true) => {
      if (force || location.search !== alreadyFetchedUrl.current) {
        alreadyFetchedUrl.current = location.search;
        setSelectedRows([]);
        agGridApiRef.current?.deselectAll();
        agGridApiRef.current?.setDatasource(getDataSource(filters, filterValues));
      }
    },
    [
      agGridApiRef,
      filterValues,
      filters,
      getDataSource,
      setSelectedRows,
      location.search,
      alreadyFetchedUrl,
    ]
  );

  useEffect(() => {
    if (!agGridApiRef.current || !agColumnApiRef.current) return;

    if (
      disableViews ||
      !Object.keys(defaultFilterValues).length ||
      (!!Object.keys(defaultFilterValues).length && !!Object.keys(filterValues).length)
    ) {
      reloadData(false);
    }

    // We react only to filter changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, agGridApiRef.current]);

  const urlSort = parse(location.search.substring(1)).sort as string | undefined;
  const sortState = useMemo(
    () =>
      urlSort
        ? (urlSort.split('.') as [string, 'asc' | 'desc'])
        : typeof urlSort === 'string'
          ? // Means deliberately empty
            null
          : undefined,
    [urlSort]
  );

  const setFilterAndColumnValues = useCallback(
    (
      newFilterValues: Record<string, FilterValue> | undefined,
      columnConfiguration: UrlColumnConfiguration
    ) => {
      // Persist changed settings to the URL
      const filterValues = deepClone(newFilterValues);
      if (filterValues && 'range' in filterValues && isArray(filterValues.range)) {
        filterValues.range = filterValues.range.map((v) =>
          isDate(v) ? transformDateToString(v as any, 'DATE') : v
        );
      }

      const urlWithParams = mergeUrl(
        `${location.pathname}${location.search}`,
        {
          filters: filterValues,
          ...columnConfiguration,
        },
        { shallowMerge: true }
      );

      history.replace(urlWithParams);
      saveEntityParams(new URL(urlWithParams, `http://modoc`).search);
    },
    [history, location.pathname, location.search, saveEntityParams]
  );

  const saveSortState = useCallback(() => {
    if (!agColumnApiRef.current) return;

    // Persist changed settings to the URL
    const sortedColumn = agColumnApiRef.current.getColumnState().find((s) => s.sort);
    const sort = sortedColumn ? `${sortedColumn.colId}.${sortedColumn.sort}` : null;
    const urlWithParams = mergeUrl(
      `${location.pathname}${location.search}`,
      { sort },
      { shallowMerge: true }
    );

    history.push(urlWithParams);
    saveEntityParams(new URL(urlWithParams, `http://modoc`).search);
  }, [agColumnApiRef, history, location.pathname, location.search, saveEntityParams]);

  const handleSort: HandleSortCallback = useCallback(
    (col, colDef) => {
      const sortState = col.getSort();
      const nextSortState = !sortState ? 'asc' : sortState === 'asc' ? 'desc' : null;
      const sort = `${colDef.field}.${nextSortState}`;
      const url = mergeUrl(
        `${location.pathname}${location.search}`,
        { sort },
        { shallowMerge: true }
      );
      dispatch(push(url));
    },
    [dispatch, location.pathname, location.search]
  );

  return {
    filterValues,
    setFilterAndColumnValues,
    sortState,
    saveSortState,
    reloadData,
    handleSort,
  };
};

export default useUrlState;
