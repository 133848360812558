import styled from 'styled-components/macro';

import theme from 'app/theme';
import { FlexCell } from 'core/components/FlexUtils';

export const CommentContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const AlignTopFlexCell = styled(FlexCell)`
  align-self: self-start;
`;

export const AvatarContainer = styled.div`
  display: flex;
  padding-bottom: 0.625rem;
  padding-top: 0.375rem;

  & > div:first-child {
    margin: 0;
    margin-right: 1rem;
    width: 2.625rem;
    font-size: 1rem;
  }

  & > div > div > div {
    padding-top: 0.25rem;
  }
`;

export const PersonalInfo = styled.div`
  display: block;

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${theme.color.baseFontColor};
    margin-bottom: 0;
  }
`;

export const InformationsWrapper = styled.div`
  display: flex;
  font-size: 0.875rem;
  color: ${theme.color.gray400};
`;

export const InlineIconText = styled.span`
  margin-left: 0.625rem;
  display: flex;

  .material-icons {
    margin-top: 0.125rem;
    font-size: 1rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const ActionsWrapper = styled.div`
  margin-left: auto;
`;

export const EditorWrapper = styled.div<{ noTopPadding: boolean }>`
  padding-top: ${(p) => (p.noTopPadding ? '0' : '0.875rem')};

  div.rdw-editor-main {
    height: 8rem;
  }

  .public-DraftStyleDefault-block {
    margin-top: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledMessage = styled.div`
  font-size: 0.875rem;

  .public-DraftStyleDefault-block {
    margin: 0;
  }
`;
