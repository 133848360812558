import { useCallback, useMemo } from 'react';

import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { useGetPermission } from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import Analysis from 'lists/models/Analysis';
import Test, { TestStatus } from 'planning/models/Test';
import { BLOOD_ANALYSES_URL, URINE_ANALYSES_URL } from 'planning/resources/analyses';

type TestKeys = keyof Test;

export type EditableTestFields = { [key in TestStatus]?: TestKeys[] };

export type ViewMode =
  | {
      [key in TestKeys]?: boolean;
    }
  | boolean
  | undefined;

/**
 * Define view mode for the test fields for each test status
 * @returns Callback that takes test status as a single parameter and returns `ViewMode`.
 *          The response from the callback will be `true` if the user does not have
 *          sufficient permission, `undefined` if the `testStatus` is `undefined` or the view mode
 *          for the `testStatus` has not been defined, or an object with field names each mapped to
 *          `false` value
 */
const useGetViewMode = (): ((testStatus?: TestStatus) => ViewMode) => {
  const hasPermission = useGetPermission();

  const { data: blood } = useResources<Analysis>(BLOOD_ANALYSES_URL, { autoload: false });
  const { data: urine } = useResources<Analysis>(URINE_ANALYSES_URL, { autoload: false });

  // TODO: improve support in useIncludeOptions bloodAnalysessId => bloodAnalyses
  const bloodAnalysesInclude = useIncludeOptions('bloodAnalysessId') as Analysis[];
  const urineAnalysesInclude = useIncludeOptions('urineAnalysessId') as Analysis[];

  const otherBloodAnalysesCount = useMemo(
    () =>
      (blood?.length ? blood : bloodAnalysesInclude || []).filter((it) => it.code.startsWith('ADT'))
        .length,
    [blood, bloodAnalysesInclude]
  );

  const otherUrineAnalysesCount = useMemo(
    () =>
      (urine?.length ? urine : urineAnalysesInclude || []).filter((it) => it.code.startsWith('ADT'))
        .length,
    [urine, urineAnalysesInclude]
  );

  return useCallback(
    (testStatus?: TestStatus) => {
      const canEditDetails = hasPermission('tests:patch[actions:details]');
      const canEditStatement = hasPermission('tests:patch[actions:statement]');

      if (!canEditDetails && !canEditStatement) {
        return true;
      }
      if (testStatus === undefined) {
        return undefined;
      }
      // For each test status define which fields should not be in the viewMode
      // `undefined` for testStatus that is not defined in `editableFields`
      let editableFields: EditableTestFields;

      // DCO must be able to edit planned collection date time field
      if (!canEditDetails && canEditStatement) {
        editableFields = {
          ...Object.fromEntries(
            [TestStatus.UNASSIGNED, TestStatus.ASSIGNED].map((testStatus) => [
              testStatus,
              ['plannedAt'],
            ])
          ),
          ...Object.fromEntries(
            [
              TestStatus.IN_PROGRESS,
              TestStatus.COMPLETED,
              TestStatus.CANCELLED,
              TestStatus.UNSUCCESSFUL,
            ].map((testStatus) => [testStatus, []])
          ),
        };
      } else {
        editableFields = {
          ...Object.fromEntries(
            [
              TestStatus.IN_PROGRESS,
              TestStatus.COMPLETED,
              TestStatus.CANCELLED,
              TestStatus.UNSUCCESSFUL,
            ].map((testStatus) => [
              testStatus,
              [
                'federationsId',
                'federationsName',
                'debtorsId',
                'debtorsName',
                'customersId',
                'customersName',
                'testAuthority',
                'resultAuthority',
                'sampleAuthoritiesId',
                'initiatorAuthoritiesId',
                'externalId',
                'tags',
                'bloodAnalyses',
                'bloodLabsId',
                'bloodExpedited',
                'bloodLts',
                'bloodComments',
                'urineAnalyses',
                'urineLabsId',
                'urineExpedited',
                'urineLts',
                'urineComments',
                'costsId',
                'invoicingCodesId',
                'generalComments',
                'invoiceComments',
                'useAdamsAthleteLevel',
                'adamsAthleteLevelsId',
                ...Array.from({ length: otherBloodAnalysesCount }, (_, i) => i)
                  .map((i) => [`otherBloodAnalyses[${i}].id`, `otherBloodAnalyses[${i}].remarks`])
                  .flat(),
                ...Array.from({ length: otherUrineAnalysesCount }, (_, i) => i)
                  .map((i) => [`otherUrineAnalyses[${i}].id`, `otherUrineAnalyses[${i}].remarks`])
                  .flat(),
              ],
            ])
          ),
        };
      }

      return editableFields[testStatus]?.reduce((acc: { [key: string]: false }, key: string) => {
        acc[key] = false; // this can be extended in the future with a whitelist/blacklist prop
        return acc;
      }, {});
    },
    [hasPermission, otherBloodAnalysesCount, otherUrineAnalysesCount]
  );
};

export default useGetViewMode;
