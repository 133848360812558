import { useCallback } from 'react';

import { transformDateTimeToStrings } from 'core/effects/apiCall/dateTimeTransformations';

import { TeamFormData, TeamMemberData } from './useTeamInputMapping';

/**
 * Turns invitedMembers/assignedMembers into:
 * - teamMembers
 * - dcos
 * - bcos
 * - leadDcosId
 */

export const mapMember = (member: TeamMemberData) => ({
  usersId: member.usersId,
  internalComment: member.internalComment,
  statement: member.statement,
  invitations: member.invitations.map((invitation) => ({
    ...invitation,
    createdAt: invitation.createdAt ? transformDateTimeToStrings(invitation.createdAt) : undefined,
  })),
  roles: member.roles,
  status: member.status,
});

const useTeamOutputMapping = () => {
  return useCallback((data: TeamFormData) => {
    // Join assigned members with invited members, group them together

    return {
      teamMembers: [
        ...data.invitedMembers.map((it) => ({ ...mapMember(it) })),
        ...data.assignedMembers.map((it) => ({
          ...mapMember(it),
        })),
      ],
      invitedMembers: undefined,
      assignedMembers: undefined,
      notifyOfTeamUpdates: data.notifyOfTeamUpdates,
      showAthleteToDco: data.showAthleteToDco,
    };
  }, []);
};

export default useTeamOutputMapping;
