import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const FormikContextHelper: FC = () => {
  const context = useFormikContext();

  useEffect(() => {
    window.getFormikContext = () => context;

    return () => {
      delete window.getFormikContext;
    };
  }, [context]);

  return null;
};

export default FormikContextHelper;
