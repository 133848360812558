import styled from 'styled-components/macro';

import theme from 'app/theme';
import { FlexCell } from 'core/components/FlexUtils';

export const Title = styled.h2`
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  margin-right: auto;
  margin-bottom: 0;
  color: ${theme.color.baseFontColor};
`;

export const NoMarginCell = styled(FlexCell)`
  margin: 0;
`;
