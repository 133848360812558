import { useMemo } from 'react';
import * as Yup from 'yup';

/**
 * Address schema
 *
 * @param requireCountry If true, always require country. By default, country is only required when
 * either street, city, zip, state, or address field is filled.
 */
export const useAddressSchema = (requireCountry: boolean = false) => {
  const countriesId = requireCountry
    ? Yup.number().nullable().required().default(null)
    : Yup.number()
        .nullable()
        .default(null)
        .when(['street', 'city', 'zip', 'state', 'address'], {
          is: function () {
            const values = Array.prototype.slice.call(arguments, 0, 5).filter(Boolean);
            return values.length > 0;
          },
          then: Yup.number().nullable().required().default(null),
        })
        .default(null);

  return useMemo(
    () =>
      Yup.object({
        id: Yup.number().nullable().default(null),
        type: Yup.string().required().default('main'),
        street: Yup.string().nullable().default(null),
        address: Yup.string().nullable().default(null),
        city: Yup.string().nullable().default(null),
        zip: Yup.string().nullable().default(null).max(10),
        state: Yup.string().nullable().default(null),
        countriesId,
      }),
    [countriesId]
  );
};

export type UserAddressInput = Yup.TypeOf<ReturnType<typeof useAddressSchema>>;

const useAddressesFieldsSchema = () => {
  const userAddressInput = useAddressSchema();
  return useMemo(() => Yup.array(userAddressInput).ensure().default([]), [userAddressInput]);
};

export default useAddressesFieldsSchema;
