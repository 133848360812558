import { getIn } from 'formik';
import { createContext, FC, memo, ReactNode, useContext, useMemo } from 'react';

import { Entity } from 'core/hooks/useDropdownResource';

/**
 * Shared resource dropdown context
 */
const IncludeResourceContext = createContext<{
  source: { [key: string]: Entity[] };
  mapping: { [key: string]: string };
}>({ source: {}, mapping: {} });

interface Props {
  /**
   * field: options mapping
   * its used to extract data from entity and map in to proper field name
   */
  mapping?: { [key: string]: string };
  /**
   * source of data / Entity
   */
  source?: Entity;
  /**
   * Children that have includes available
   */
  children?: ReactNode;
}

/**
 * Context for sharing entity includes with lazy loaded dropdown resources
 * so when you load entity with includes, just put it here and all lazy dropdowns
 * will use include as default data, and more resources will be loaded onFocus
 *
 * - Why do we do mapping here not in dropdown ?
 *  - dropdown has its own complexity
 *  - we could share more data in future on top instead of mapping in particular fields
 *  (to get proper value user useIncludeOptions hook)
 */
const IncludeResourcesProvider: FC<Props> = ({ children, source, mapping }) => {
  const value = useMemo(
    () => ({
      source: source || {},
      mapping: mapping || {},
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source, JSON.stringify(mapping)]
  );
  return (
    <IncludeResourceContext.Provider value={value}>{children}</IncludeResourceContext.Provider>
  );
};

/**
 * read dropdown options for form field
 *
 * @param {string} fieldName Read resource items
 */
export const useIncludeOptions = (fieldName: string) => {
  const { source, mapping } = useContext(IncludeResourceContext);
  return useMemo(() => {
    let sourceObj = source;
    let name = fieldName;

    if (fieldName.includes('.')) {
      const words = fieldName.split('.');
      name = words[words.length - 1];
      sourceObj = getIn(source, fieldName.split('.').slice(0, -1).join('.')) || sourceObj;
    }

    // countriesId => search for "country" include
    // assigneesId => search for "assignee" include
    // nationalityId => search for "nationality" include
    const singular = name.replace('iesId', 'y').replace('sId', '').replace('yId', 'y');
    // Magic version of singular form
    // because of testAuthority => testsAuthority
    // resultAuthority => resultsAuthority
    const magicSingular = name.replace(/([a-z])([A-Z])/, '$1s$2');

    let val =
      (name in mapping && (mapping[name] ? sourceObj[mapping[name]] : (sourceObj as any))) || // override by mapping
      (singular !== name && singular && sourceObj[singular]) || // try singular
      (magicSingular !== name && sourceObj[magicSingular]) || // try magic singular
      [];

    if (typeof val === 'string' && singular !== name && singular && sourceObj[singular]) {
      val = { id: sourceObj[name], name: sourceObj[singular] };
    }

    return (val && Array.isArray(val) ? val : [val]).filter((i) => i);
  }, [fieldName, source, mapping]);
};

export default memo<typeof IncludeResourcesProvider>(IncludeResourcesProvider);
