import { IGetRowsParams } from 'ag-grid-community';
import { AxiosError, AxiosRequestConfig, AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { ReactNode } from 'react';

import { FileEntityName } from 'app/hooks/useFileEntityLookups';
import { FormData as AddFilesFD } from 'core/containers/AddFilesDialog/useSchema';
import { FormData as FilesBulkUploadFD } from 'core/containers/BulkUploadDialog/useSchema';
import { FormData as SaveViewFD } from 'core/containers/DataGrid/Toolbar/CustomizeViews/useSchema';
import User from 'core/models/CoreUser';

import { SelectedRows } from './containers/DataGrid/props';
import { ApiQueryOptions } from './hooks/useApiCall';
import CoreEvent from './models/CoreEvent';
import { UserView } from './models/UserView';
import { ApiQuery, CoreState, ResetPwdFormData } from './reducer';

export const TYPE_UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';
export const updateAvailable = (payload: boolean) =>
  ({ type: TYPE_UPDATE_AVAILABLE, payload }) as const;
export type UpdateAvailableAction = ReturnType<typeof updateAvailable>;

export const TYPE_I18N_INITIALIZED = 'I18N_INITIALIZED';
export const i18nInitialized = () =>
  ({
    type: TYPE_I18N_INITIALIZED,
  }) as const;
export type I18nInitializedAction = ReturnType<typeof i18nInitialized>;

export const TYPE_CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const changeLanguage = (language: string) =>
  ({
    type: TYPE_CHANGE_LANGUAGE,
    payload: {
      language,
    },
  }) as const;
export type ChangeLanguageAction = ReturnType<typeof changeLanguage>;

export const TYPE_RENEW_SESSION = 'RENEW_SESSION';
export const renewSession = () => ({ type: TYPE_RENEW_SESSION }) as const;
export type RenewSessionAction = ReturnType<typeof renewSession>;

export const TYPE_LOGIN = 'LOGIN';
export const login = (email: string, password: string, passcode?: string) =>
  ({
    type: TYPE_LOGIN,
    payload: {
      email,
      password,
      passcode,
    },
  }) as const;
export type LoginAction = ReturnType<typeof login>;

export const TYPE_GET_WORKSPACE = 'GET_WORKSPACE';
export const getWorkspace = (email: string) =>
  ({
    type: TYPE_GET_WORKSPACE,
    payload: {
      email,
    },
  }) as const;
export type GetWorkspaceAction = ReturnType<typeof getWorkspace>;

export const TYPE_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSuccess = () =>
  ({
    type: TYPE_LOGIN_SUCCESS,
  }) as const;
export type LoginSuccessAction = ReturnType<typeof loginSuccess>;

export const TYPE_LOGIN_ERROR = 'LOGIN_ERROR';
export const loginError = (error: Error) =>
  ({
    type: TYPE_LOGIN_ERROR,
    payload: {
      error,
    },
  }) as const;
export type LoginErrorAction = ReturnType<typeof loginError>;

export const TYPE_LOGIN_AS_USER = 'LOGIN_AS_USER';
export const loginAsUser = (id: number) => ({ type: TYPE_LOGIN_AS_USER, payload: id }) as const;
export type LoginAsUserAction = ReturnType<typeof loginAsUser>;

export const TYPE_LOGOUT = 'LOGOUT';
export const logout = () =>
  ({
    type: TYPE_LOGOUT,
  }) as const;
export type LogoutAction = ReturnType<typeof logout>;

export const TYPE_LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const logoutSuccess = (clearPrevPathname = true) =>
  ({
    type: TYPE_LOGOUT_SUCCESS,
    payload: {
      clearPrevPathname,
    },
  }) as const;
export type LogoutSuccessAction = ReturnType<typeof logoutSuccess>;

export const TYPE_LOGOUT_ERROR = 'LOGOUT_ERROR';
export const logoutError = (error: Error) =>
  ({
    type: TYPE_LOGOUT_ERROR,
    payload: {
      error,
    },
  }) as const;
export type LogoutErrorAction = ReturnType<typeof logoutError>;

export const TYPE_SWITCH_LOGIN = 'SWITCH_LOGIN';
export const switchLogin = (twoFactorMode: boolean) =>
  ({
    type: TYPE_SWITCH_LOGIN,
    payload: {
      twoFactorMode,
    },
  }) as const;
export type SwitchLoginAction = ReturnType<typeof switchLogin>;

export const TYPE_BOOTSTRAPPED = 'BOOTSTRAPPED';
export const bootstrapped = (user: User) =>
  ({
    type: TYPE_BOOTSTRAPPED,
    payload: {
      response: {
        data: user,
      },
    },
  }) as const;
export type BootstrappedAction = ReturnType<typeof bootstrapped>;

export const TYPE_SAVE_PREV_PATHNAME = 'SAVE_PREV_PATHNAME';
export const savePrevPathname = (prevPathname: string) =>
  ({
    type: TYPE_SAVE_PREV_PATHNAME,
    payload: {
      prevPathname,
    },
  }) as const;
export type SavePrevPathnameAction = ReturnType<typeof savePrevPathname>;

export type CustomOperation = {
  fieldName: string;
  operation: 'replace' | 'add' | 'remove' | 'skip';
  type: 'EXTENDED' | 'COMMON';
  description?: string;
};

export const TYPE_API_CALL = 'API_CALL';
/**
 * Create an API call which will be handled by abstraction and will dispatch _SUCCESS and _ERROR actions
 *
 * @todo Move everything from config down to options object.
 * @param {string} actionPrefix Prefix that will be used to dispatch actions with _SUCCESS and _ERROR suffix.
 * @param {string} method HTTP method that should be used, if PATCH is used, previousData are mandatory.
 * @param {string} url URL
 * @param {any} data Data that will be applied as body of the request or URL params for GET requests.
 * @param {Object} config Optional custom config for the axios.
 * @param {boolean} showError Show toast with the error. Enabled by default.
 * @param {Object} previousData Previous data required if method is PATCH to calculate the diff.
 * @param {Object} ignoredPreviousParameters Attributes from previous data that should be ignored in diff.
 * @param {boolean} diffPatch Send diffs when doing PATCH based on the previousData and data.
 * @param {boolean} customOperations custom operations which could not be done with automatic diff calculating.
 */
export const apiCall = (
  actionPrefix: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  url: string,
  data: any = undefined,
  config: RawAxiosRequestConfig | AxiosRequestConfig = {},
  showError = true,
  previousData: any = undefined,
  ignoredPreviousParameters: string[] = [],
  diffPatch: boolean = true,
  customOperations: CustomOperation[] = []
) =>
  ({
    type: TYPE_API_CALL,
    payload: {
      actionPrefix,
      method,
      url,
      data,
      config,
      showError,
      previousData,
      ignoredPreviousParameters,
      diffPatch,
      customOperations,
    },
  }) as const;
export type ApiCallAction = ReturnType<typeof apiCall>;

/**
 * This action don't have set type since they are generated automatically
 */
export const apiCallSuccess = (actionPrefix: string, response: AxiosResponse, endpoint: string) =>
  ({
    type: `${actionPrefix}_SUCCESS`,
    payload: {
      response,
      endpoint,
    },
  }) as const;
export type ApiCallSuccessAction<Type = string, Response = AxiosResponse> = {
  type: Type;
  payload: { response: Response; endpoint: string };
};

/**
 * This action don't have set type since they are generated automatically
 */
export const apiCallError = (
  actionPrefix: string,
  error: AxiosError | Error,
  response?: AxiosResponse | string
) =>
  ({
    type: `${actionPrefix}_ERROR`,
    payload: {
      error,
      response,
    },
  }) as const;

export type ApiCallErrorAction<Type = string, Response = AxiosResponse> = {
  type: Type;
  payload: { response: Response; error: Error | AxiosError };
};

export const PREFIX_FETCH_DATAGRID_ENDPOINT = 'FETCH_DATAGRID_ENDPOINT';
export const TYPE_FETCH_DATAGRID_DATA = 'FETCH_DATAGRID_DATA';
export const fetchDataGridData = (
  endpoint: string,
  agGetRowsParams: IGetRowsParams & {
    successCallback: (rows: any[], filtered: number, total: number | null) => void;
  },
  additionalParams: { [key: string]: any },
  requestId: string
) =>
  ({
    type: TYPE_FETCH_DATAGRID_DATA,
    payload: {
      endpoint,
      agGetRowsParams,
      additionalParams,
      requestId,
    },
  }) as const;
export type fetchDataGridDataAction = ReturnType<typeof fetchDataGridData>;

export const PREFIX_DATAGRID_BULK_DELETE_ENDPOINT = 'DATAGRID_BULK_DELETE_ENDPOINT';
export const TYPE_DATAGRID_BULK_DELETE = 'DATAGRID_BULK_DELETE';
export const datagridBulkDelete = (
  endpoint: string,
  selection: number[],
  successCallback: () => void
) =>
  ({
    type: TYPE_DATAGRID_BULK_DELETE,
    payload: {
      endpoint,
      selection,
      successCallback,
    },
  }) as const;
export type DatagridBulkDeleteAction = ReturnType<typeof datagridBulkDelete>;

export const PREFIX_DATAGRID_BULK_PATCH_ENDPOINT = 'DATAGRID_BULK_PATCH_ENDPOINT';
export const TYPE_DATAGRID_BULK_PATCH = 'DATAGRID_BULK_PATCH';
export const datagridBulkPatch = (
  endpoint: string,
  selection: number[],
  data: any,
  successCallback: () => void
) =>
  ({
    type: TYPE_DATAGRID_BULK_PATCH,
    payload: {
      endpoint,
      selection,
      data,
      successCallback,
    },
  }) as const;
export type DatagridBulkPatchAction = ReturnType<typeof datagridBulkPatch>;

export const TYPE_DATAGRID_SAVE_ENTITY_PARAMS = 'DATAGRID_SAVE_ENTITY_PARAMS';
export type SavedDataGridParams = undefined | { endpoint: string; params: string };
export const dataGridSaveEntityParams = (datagridParams: SavedDataGridParams) =>
  ({
    type: TYPE_DATAGRID_SAVE_ENTITY_PARAMS,
    payload: {
      datagridParams,
    },
  }) as const;
export type dataGridSaveEntityParamsAction = ReturnType<typeof dataGridSaveEntityParams>;

export const TYPE_SET_OPEN_DROPDOWN_ID = 'SET_OPEN_DROPDOWN_ID';
export const setOpenDropdownId = (id?: string) =>
  ({
    type: TYPE_SET_OPEN_DROPDOWN_ID,
    payload: {
      id,
    },
  }) as const;
export type setOpenDropdownIdAction = ReturnType<typeof setOpenDropdownId>;

export const TYPE_RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const restorePassword = (email: string) =>
  ({
    type: TYPE_RESTORE_PASSWORD,
    payload: {
      email,
    },
  }) as const;
export type RestorePasswordAction = ReturnType<typeof restorePassword>;

export const TYPE_RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const restorePasswordSuccess = () =>
  ({
    type: TYPE_RESTORE_PASSWORD_SUCCESS,
  }) as const;
export type RestorePasswordSuccessAction = ReturnType<typeof restorePasswordSuccess>;

export const TYPE_RESTORE_PASSWORD_ERROR = 'RESTORE_PASSWORD_ERROR';
export const restorePasswordError = (error: Error) =>
  ({
    type: TYPE_RESTORE_PASSWORD_ERROR,
    payload: {
      error,
    },
  }) as const;
export type RestorePasswordErrorAction = ReturnType<typeof restorePasswordError>;

export const TYPE_RESTORE_PASSWORD_RESET = 'RESTORE_PASSWORD_RESET';
export const restorePasswordReset = () =>
  ({
    type: TYPE_RESTORE_PASSWORD_RESET,
  }) as const;
export type RestorePasswordResetAction = ReturnType<typeof restorePasswordReset>;

export const TYPE_VALIDATE_CODE = 'VALIDATE_CODE';
export const validateCode = (type: 'reset-password' | 'unlock-account', code: string) =>
  ({
    type: TYPE_VALIDATE_CODE,
    payload: {
      type,
      code,
    },
  }) as const;
export type ValidateCodeAction = ReturnType<typeof validateCode>;

export const TYPE_VALIDATE_CODE_SUCCESS = 'VALIDATE_CODE_SUCCESS';
export const validateCodeSuccess = (code: string) =>
  ({
    type: TYPE_VALIDATE_CODE_SUCCESS,
    payload: {
      code,
    },
  }) as const;
export type ValidateCodeSuccessAction = ReturnType<typeof validateCodeSuccess>;

export const TYPE_VALIDATE_CODE_ERROR = 'VALIDATE_CODE_ERROR';
export const validateCodeError = () =>
  ({
    type: TYPE_VALIDATE_CODE_ERROR,
  }) as const;
export type ValidateCodeErrorAction = ReturnType<typeof validateCodeError>;

export const TYPE_RESET_PASSWORD = 'RESET_PASSWORD';
export const resetPassword = (code: string, data: ResetPwdFormData) =>
  ({
    type: TYPE_RESET_PASSWORD,
    payload: {
      code,
      data,
    },
  }) as const;
export type ResetPasswordAction = ReturnType<typeof resetPassword>;

export const TYPE_RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const resetPasswordSuccess = () =>
  ({
    type: TYPE_RESET_PASSWORD_SUCCESS,
  }) as const;
export type ResetPasswordSuccessAction = ReturnType<typeof resetPasswordSuccess>;

export const TYPE_RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const resetPasswordError = (error: Error) =>
  ({
    type: TYPE_RESET_PASSWORD_ERROR,
    payload: {
      error,
    },
  }) as const;
export type ResetPasswordErrorAction = ReturnType<typeof resetPasswordError>;

export const TYPE_RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';
export const resetPasswordReset = () =>
  ({
    type: TYPE_RESET_PASSWORD_RESET,
  }) as const;
export type ResetPasswordResetAction = ReturnType<typeof resetPasswordReset>;

export const TYPE_ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const activateAccount = (code: string, data: unknown) =>
  ({
    type: TYPE_ACTIVATE_ACCOUNT,
    payload: {
      code,
      data,
    },
  }) as const;
export type ActivateAccountAction = ReturnType<typeof activateAccount>;

export const TYPE_ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';
export const activateAccountSuccess = () =>
  ({
    type: TYPE_ACTIVATE_ACCOUNT_SUCCESS,
  }) as const;
export type ActivateAccountSuccessAction = ReturnType<typeof activateAccountSuccess>;

export const TYPE_ACTIVATE_ACCOUNT_ERROR = 'ACTIVATE_ACCOUNT_ERROR';
export const activateAccountError = (error: Error) =>
  ({
    type: TYPE_ACTIVATE_ACCOUNT_ERROR,
    payload: {
      error,
    },
  }) as const;
export type ActivateAccountErrorAction = ReturnType<typeof activateAccountError>;

export const TYPE_ACTIVATE_ACCOUNT_RESET = 'ACTIVATE_ACCOUNT_RESET';
export const activateAccountReset = () =>
  ({
    type: TYPE_ACTIVATE_ACCOUNT_RESET,
  }) as const;
export type ActivateAccountResetAction = ReturnType<typeof activateAccountReset>;

export const TYPE_UNLOCK_ACCOUNT_RESET = 'UNLOCK_ACCOUNT_RESET';
export const unlockAccountReset = () =>
  ({
    type: TYPE_UNLOCK_ACCOUNT_RESET,
  }) as const;
export type UnlockAccountResetAction = ReturnType<typeof unlockAccountReset>;

export const PREFIX_EXPORT_DATA_ENDPOINT = 'EXPORT_DATA_ENDPOINT';
export const TYPE_EXPORT_DATA = 'EXPORT_DATA';
export const exportData = (
  endpoint: string,
  options: {
    format: string;
    type?: null | string;
    paramsFromFilters?: { [key: string]: any };
    selectedRows?: SelectedRows;
    exportAll: boolean;
    fileName?: string;
  },
  successCallback?: () => void
) =>
  ({
    type: TYPE_EXPORT_DATA,
    payload: {
      endpoint,
      options,
      successCallback,
    },
  }) as const;
export type ExportDataAction = ReturnType<typeof exportData>;

export const TYPE_DOWNLOAD_DATA = 'DOWNLOAD_DATA';
export const downloadData = (endpoint: string, format: string, exportFileName?: string) =>
  ({
    type: TYPE_DOWNLOAD_DATA,
    payload: {
      endpoint,
      format,
      exportFileName,
    },
  }) as const;
export type DownloadDataAction = ReturnType<typeof downloadData>;

export const PREFIX_IMPORT_DATA_ENDPOINT = 'IMPORT_DATA_ENDPOINT';
export const TYPE_IMPORT_DATA = 'IMPORT_DATA';
export const importData = (
  endpoint: string,
  file: File,
  format?: string,
  supportedExtensions?: string[],
  sc?: () => void
) =>
  ({
    type: TYPE_IMPORT_DATA,
    payload: {
      endpoint,
      file,
      format,
      supportedExtensions,
      sc,
    },
  }) as const;
export type ImportDataAction = ReturnType<typeof importData>;

export const TYPE_LOAD_PROFILE = 'LOAD_PROFILE';
export const loadProfile = () => ({ type: TYPE_LOAD_PROFILE }) as const;
export type LoadProfileAction = ReturnType<typeof loadProfile>;

export const TYPE_UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const uploadImage = (imageFile: Blob, url: string, successCallback: () => void) =>
  ({
    type: TYPE_UPLOAD_IMAGE,
    payload: { imageFile, url, successCallback },
  }) as const;
export type UploadImageAction = ReturnType<typeof uploadImage>;

export const TYPE_LOAD_ENTITY = 'LOAD_ENTITY';
export const loadEntity = (endpoint: string) =>
  ({
    type: TYPE_LOAD_ENTITY,
    payload: { endpoint },
  }) as const;
export type LoadEntityAction = ReturnType<typeof loadEntity>;

export const TYPE_LOAD_ENTITY_SUCCESS = 'LOAD_ENTITY_SUCCESS';
export const loadEntitySuccess = (response: AxiosResponse, endpoint: string) =>
  ({
    type: TYPE_LOAD_ENTITY_SUCCESS,
    payload: {
      response,
      endpoint,
    },
  }) as const;
export type LoadEntitySuccessAction = ReturnType<typeof loadEntitySuccess>;

export const TYPE_UNLOAD_ENTITY = 'UNLOAD_ENTITY';
export const unloadEntity = () =>
  ({
    type: TYPE_UNLOAD_ENTITY,
    payload: {},
  }) as const;
export type UnloadEntityAction = ReturnType<typeof unloadEntity>;

export interface EntityRequest<D> {
  uploadables?: { [key: string]: File | File[] };
  forceMultipart?: boolean;
  endpoint: string;
  id?: number;
  data?: D;
  successCallback?: () => void;
}

export const TYPE_CREATE_ENTITY = 'CREATE_ENTITY';
export const createEntity = <WriteRequestData>(
  req: EntityRequest<WriteRequestData>,
  listUrl: string,
  detailUrl: (id: number) => string
) =>
  ({
    type: TYPE_CREATE_ENTITY,
    payload: { req, listUrl, detailUrl },
  }) as const;
export type CreateEntityAction = ReturnType<typeof createEntity>;

export const TYPE_DELETE_ENTITY = 'DELETE_ENTITY';
export const deleteEntity = (id: number, endpoint: string, successCallback?: () => void) =>
  ({
    type: TYPE_DELETE_ENTITY,
    payload: { id, endpoint, successCallback },
  }) as const;
export type DeleteEntityAction = ReturnType<typeof deleteEntity>;

export const TYPE_EDIT_ENTITY = 'EDIT_ENTITY';
export const editEntity = <WriteRequestData extends {}>(
  req: EntityRequest<WriteRequestData>,
  prevEntity: WriteRequestData
) =>
  ({
    type: TYPE_EDIT_ENTITY,
    payload: {
      req,
      prevEntity,
    },
  }) as const;
export type EditEntityAction = ReturnType<typeof editEntity>;

export const TYPE_BEGIN_EDIT_ENTITY = 'BEGIN_EDIT_ENTITY';
/**
 * Mark as Entity-In-Progress
 */
export const beginEditEntity = () => ({ type: TYPE_BEGIN_EDIT_ENTITY }) as const;
export type BeginEditEntityAction = ReturnType<typeof beginEditEntity>;

export const TYPE_FINISH_EDIT_ENTITY = 'FINISH_EDIT_ENTITY';
/**
 * Finish Entity-In-Progress
 */
export const finishEditEntity = () => ({ type: TYPE_FINISH_EDIT_ENTITY }) as const;
export type FinishEditEntityAction = ReturnType<typeof finishEditEntity>;

export const TYPE_SUBSCRIBE_2FA = 'SUBSCRIBE_2FA';
export const subscribe2FA = () => ({ type: TYPE_SUBSCRIBE_2FA }) as const;
export type Subscribe2FAAction = ReturnType<typeof subscribe2FA>;

export const TYPE_CLEAR_2FA = 'CLEAR_2FA';
export const clear2FaSecret = () => ({ type: TYPE_CLEAR_2FA }) as const;
export type Clear2FAAction = ReturnType<typeof clear2FaSecret>;

export const TYPE_VERIFY_2FA = 'VERIFY_2FA';
export const verify2FA = (code: string, successCallback?: () => void) =>
  ({ type: TYPE_VERIFY_2FA, payload: { code, successCallback } }) as const;
export type Verify2FAAcion = ReturnType<typeof verify2FA>;

export const TYPE_UNSUBSCRIBE_2FA = 'UNSUBSCRIBE_2FA';
export const unsubscribe2FA = () => ({ type: TYPE_UNSUBSCRIBE_2FA }) as const;
export type Unsubscribe2FAAction = ReturnType<typeof unsubscribe2FA>;

export const TYPE_SET_TAB_ERRORS = 'SET_TAB_ERRORS';
export const setTabErrors = (errors: string[]) =>
  ({
    type: TYPE_SET_TAB_ERRORS,
    payload: {
      errors,
    },
  }) as const;
export type SetTabErrorsAction = ReturnType<typeof setTabErrors>;

export const TYPE_SAVE_FILE_DOCUMENT = 'SAVE_FILE_DOCUMENT';
export const saveFileDocument = (
  formData: AddFilesFD,
  prevFileDetail?: AddFilesFD,
  fileId?: number,
  successCallback?: () => void,
  createUrl?: string,
  updateUrl?: string
) =>
  ({
    type: TYPE_SAVE_FILE_DOCUMENT,
    payload: { formData, prevFileDetail, fileId, createUrl, updateUrl, successCallback },
  }) as const;
export type SaveFileDocumentAction = ReturnType<typeof saveFileDocument>;

export const TYPE_DELETE_FILE = 'DELETE_FILE';
export const deleteFile = (fileId: number, successCallback: () => void, url?: string) =>
  ({
    type: TYPE_DELETE_FILE,
    payload: { fileId, successCallback, url },
  }) as const;
export type DeleteFileAction = ReturnType<typeof deleteFile>;

export const TYPE_SAVE_DIALOG_ENTITY = 'SAVE_DIALOG_ENTITY';
export const saveDialogEntity = <DialogEntityFd extends {}>(
  endpoint: string,
  formData: DialogEntityFd,
  prevFormData?: DialogEntityFd,
  successCallback?: () => void,
  successMessage?: ReactNode
) =>
  ({
    type: TYPE_SAVE_DIALOG_ENTITY,
    payload: { formData, prevFormData, endpoint, successCallback, successMessage },
  }) as const;
export type SaveDialogEntityAction = ReturnType<typeof saveDialogEntity>;

export const TYPE_DELETE_DIALOG_ENTITY = 'DELETE_DIALOG_ENTITY';
export const deleteDialogEntity = (endpoint: string, successCallback?: () => void) =>
  ({
    type: TYPE_DELETE_DIALOG_ENTITY,
    payload: { endpoint, successCallback },
  }) as const;
export type DeleteDialogEntityAction = ReturnType<typeof deleteDialogEntity>;

export const TYPE_NOTIFICATION_MARK_READ = 'NOTIFICATION_MARK_READ';
// notificationId === undefined -? mark all as read : mark One as read
export const notificationMarkRead = (notificationId?: number) =>
  ({
    type: TYPE_NOTIFICATION_MARK_READ,
    payload: { notificationId },
  }) as const;
export type NotificationMarkReadAction = ReturnType<typeof notificationMarkRead>;

export const TYPE_SOCKET_ON = 'SOCKET_ON';
export const socketOn = () => ({ type: TYPE_SOCKET_ON }) as const;
export type SocketOnAction = ReturnType<typeof socketOn>;

export const TYPE_SOCKET_OFF = 'SOCKET_OFF';
export const socketOff = () => ({ type: TYPE_SOCKET_OFF }) as const;
export type SocketOffAction = ReturnType<typeof socketOff>;

export const TYPE_SOCKET_START = 'SOCKET_START';
export const socketStart = () => ({ type: TYPE_SOCKET_START }) as const;
export type SocketStartAction = ReturnType<typeof socketStart>;

export const TYPE_SOCKET_RESTART = 'SOCKET_RESTART';
export const socketRestart = () => ({ type: TYPE_SOCKET_RESTART }) as const;
export type SocketRestartAction = ReturnType<typeof socketRestart>;

export const TYPE_SOCKET_MESSAGE = 'SOCKET_MESSAGE';
export const socketMessage = (
  eventName: 'notification' | 'versionUpdate' | 'sessionExpired' | 'sessionDeleted',
  payload: CoreEvent | { version: string }
) => ({ type: TYPE_SOCKET_MESSAGE, payload: { eventName, payload } }) as const;
export type SocketMessageAction = ReturnType<typeof socketMessage>;

export const TYPE_SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
export const setNotificationsCount = (count: number) =>
  ({ type: TYPE_SET_NOTIFICATIONS_COUNT, payload: count }) as const;
export type SetNotificationsCountAction = ReturnType<typeof setNotificationsCount>;

export const TYPE_SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const setNotifications = (payload: CoreState['notifications']) =>
  ({ type: TYPE_SET_NOTIFICATIONS, payload }) as const;
export type SetNotificationsAction = ReturnType<typeof setNotifications>;

export const TYPE_GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const getNotifications = () => ({ type: TYPE_GET_NOTIFICATIONS }) as const;
export type GetNotificationsAction = ReturnType<typeof getNotifications>;

export const TYPE_REPORT_SEND = 'REPORT_SEND';
export const reportSend = (payload: { type: string; [key: string]: any }) =>
  ({ type: TYPE_REPORT_SEND, payload }) as const;
export type ReportSendAction = ReturnType<typeof reportSend>;

export const TYPE_BULK_DOWNLOAD = 'BULK_DOWNLOAD';
export const bulkDownload = (
  fileTypesIds: number[],
  selection: number[],
  endpoint: string,
  successCallback?: () => void
) =>
  ({
    type: TYPE_BULK_DOWNLOAD,
    payload: {
      fileTypesIds,
      selection,
      endpoint,
      successCallback,
    },
  }) as const;
export type BulkDownloadAction = ReturnType<typeof bulkDownload>;

export const TYPE_BULK_UPLOAD_FILES = 'BULK_UPLOAD_FILES';
export const bulkUploadFiles = (
  formData: FilesBulkUploadFD,
  entityName: FileEntityName,
  endpoint: string,
  successCallback?: () => void
) =>
  ({
    type: TYPE_BULK_UPLOAD_FILES,
    payload: {
      formData,
      entityName,
      endpoint,
      successCallback,
    },
  }) as const;
export type BulkUploadFilesAction = ReturnType<typeof bulkUploadFiles>;

export const TYPE_INSTALL_APPLICATION = 'INSTALL_APPLICATION';
export const installApplication = (platform: string, version: string, appType: string = '') =>
  ({
    type: TYPE_INSTALL_APPLICATION,
    payload: { appType, platform, version },
  }) as const;
export type InstallApplicationAction = ReturnType<typeof installApplication>;

export const TYPE_GENERATE_PDF_MANUALLY = 'GENERATE_PDF_MANUALLY';
export const generatePdfManually = (endpoint: string) =>
  ({
    type: TYPE_GENERATE_PDF_MANUALLY,
    payload: { endpoint },
  }) as const;
export type GeneratePdfManuallyAction = ReturnType<typeof generatePdfManually>;

export const TYPE_GENERATE_FILE = 'GENERATE_FILE';
export const generateFile = (endpoint: string, body: any, successCallback?: () => void) =>
  ({
    type: TYPE_GENERATE_FILE,
    payload: { endpoint, body, successCallback },
  }) as const;
export type GenerateFileAction = ReturnType<typeof generateFile>;

export const TYPE_SEND_EMAIL = 'SEND_EMAIL';
export const sendEmail = (values: any, endpoint: string, successCallback: () => void) =>
  ({
    type: TYPE_SEND_EMAIL,
    payload: { endpoint, values, successCallback },
  }) as const;
export type SendEmailAction = ReturnType<typeof sendEmail>;

export const TYPE_FAVORITE_VIEW = 'FAVORITE_VIEW';
export const favoriteView = (viewsId: number, favorite: boolean, successCallback: () => void) =>
  ({
    type: TYPE_FAVORITE_VIEW,
    payload: { viewsId, favorite, successCallback },
  }) as const;
export type FavoriteViewAction = ReturnType<typeof favoriteView>;

export const PREFIX_SAVE_VIEW = 'SAVE_VIEW';
export const TYPE_SAVE_VIEW = 'SAVE_VIEW';
export const saveView = (
  data: SaveViewFD & {
    entity: string;
    data: UserView['data'] | null;
  },
  id?: number,
  previousData?: SaveViewFD & {
    entity: string;
    data: null;
  },
  successCallback?: (userView?: UserView) => void
) =>
  ({
    type: TYPE_SAVE_VIEW,
    payload: { previousData, data, successCallback, id },
  }) as const;
export type SaveViewAction = ReturnType<typeof saveView>;

export const PREFIX_DELETE_VIEW = 'DELETE_VIEW';
export const TYPE_DELETE_VIEW = 'DELETE_VIEW';
export const deleteView = (id: number, successCallback: (newId?: number) => void) =>
  ({
    type: TYPE_DELETE_VIEW,
    payload: { id, successCallback },
  }) as const;
export type DeleteViewAction = ReturnType<typeof deleteView>;

export const TYPE_COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD';
export const copyToClipboard = (clipText: string) =>
  ({
    type: TYPE_COPY_TO_CLIPBOARD,
    payload: { clipText },
  }) as const;
export type CopyToClipboardAction = ReturnType<typeof copyToClipboard>;

export const TYPE_API_QUERY = 'API_QUERY';
export const apiQuery = (urlOrId: string, options: ApiQueryOptions) =>
  ({
    type: TYPE_API_QUERY,
    payload: { urlOrId, options },
  }) as const;
export type ApiQueryAction = ReturnType<typeof apiQuery>;

export const TYPE_FIRE_API_QUERY = 'FIRE_API_QUERY';
export const fireApiQuery = (id: string, clear: boolean = false) =>
  ({ type: TYPE_FIRE_API_QUERY, id, clear }) as const;
export type FireApiQueryAction = ReturnType<typeof fireApiQuery>;

export const TYPE_API_QUERY_RESULT = 'API_QUERY_RESULT';
export const apiQueryResult = (id: string, response: ApiQuery) =>
  ({ type: TYPE_API_QUERY_RESULT, id, payload: response }) as const;
export type ApiQueryResultAction = ReturnType<typeof apiQueryResult>;

export const TYPE_RELOAD_API_QUERY = 'RELOAD_API_QUERY';
export const reloadApiQuery = (idOrIds: string | string[], clear: boolean = false) =>
  ({ type: TYPE_RELOAD_API_QUERY, clear, idOrIds }) as const;
export type ReloadApiQueryAction = ReturnType<typeof reloadApiQuery>;

export const TYPE_CLEAR_API_QUERY = 'CLEAR_API_QUERY';
export const clearApiQuery = (id: string) =>
  ({
    type: TYPE_CLEAR_API_QUERY,
    id,
  }) as const;
export type ClearApiQueryAction = ReturnType<typeof clearApiQuery>;

export const TYPE_SAVE_WATCHERS = 'SAVE_WATCHERS';
export const saveWatchers = (
  endpoint: string,
  watcherIds: number[],
  showSuccess: boolean,
  successCallback: (() => void) | undefined,
  entityIds?: number[]
) =>
  ({
    type: TYPE_SAVE_WATCHERS,
    payload: { endpoint, watcherIds, successCallback, showSuccess, entityIds },
  }) as const;
export type SaveWatchersAction = ReturnType<typeof saveWatchers>;

export type CoreActions =
  | UpdateAvailableAction
  | I18nInitializedAction
  | ChangeLanguageAction
  | LoginAction
  | LoginSuccessAction
  | LoginErrorAction
  | LogoutAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | SwitchLoginAction
  | LoginAsUserAction
  | BootstrappedAction
  | SavePrevPathnameAction
  | ApiCallAction
  | fetchDataGridDataAction
  | DatagridBulkDeleteAction
  | DatagridBulkPatchAction
  | dataGridSaveEntityParamsAction
  | setOpenDropdownIdAction
  | RestorePasswordAction
  | RestorePasswordSuccessAction
  | RestorePasswordErrorAction
  | RestorePasswordResetAction
  | ValidateCodeAction
  | ValidateCodeSuccessAction
  | ValidateCodeErrorAction
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordErrorAction
  | ResetPasswordResetAction
  | ActivateAccountAction
  | ActivateAccountSuccessAction
  | ActivateAccountErrorAction
  | ActivateAccountResetAction
  | UnlockAccountResetAction
  | ExportDataAction
  | ImportDataAction
  | DownloadDataAction
  | LoadProfileAction
  | UploadImageAction
  | LoadEntityAction
  | LoadEntitySuccessAction
  | UnloadEntityAction
  | CreateEntityAction
  | EditEntityAction
  | DeleteEntityAction
  | BeginEditEntityAction
  | FinishEditEntityAction
  | Subscribe2FAAction
  | Unsubscribe2FAAction
  | Verify2FAAcion
  | Clear2FAAction
  | SetTabErrorsAction
  | SaveFileDocumentAction
  | DeleteFileAction
  | NotificationMarkReadAction
  | SocketStartAction
  | SocketRestartAction
  | SocketOnAction
  | SocketOffAction
  | SocketMessageAction
  | SetNotificationsCountAction
  | SetNotificationsAction
  | GetNotificationsAction
  | DeleteFileAction
  | DownloadDataAction
  | ReportSendAction
  | BulkDownloadAction
  | InstallApplicationAction
  | GeneratePdfManuallyAction
  | GenerateFileAction
  | SendEmailAction
  | SaveViewAction
  | DeleteViewAction
  | BulkUploadFilesAction
  | RenewSessionAction
  | SaveDialogEntityAction
  | DeleteDialogEntityAction
  | CopyToClipboardAction
  | ApiQueryAction
  | ApiQueryResultAction
  | ClearApiQueryAction
  | FireApiQueryAction
  | ReloadApiQueryAction
  | GetWorkspaceAction;
