import { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EntityKey } from 'app/entity';
import { deleteDialogEntity } from 'core/actions';
import ContextMenu from 'core/components/ContextMenu';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import Modal from 'core/components/Modal';
import Tooltip from 'core/components/Tooltip';
import getFakeId from 'core/functions/getFakeId';
import useAppSelector from 'core/hooks/useAppSelector';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import CommentModel from 'core/models/Comment';

import {
  ActionsWrapper,
  AlignTopFlexCell,
  CommentContainer,
  HeaderWrapper,
  InformationsWrapper,
  InlineIconText,
  PersonalInfo,
} from '../styled';
import { CommentsUrls } from '../useCommentsUrls';

import CommentForm from './CommentForm';
import Message from './Message';
import RestrictedRoleNames from './RestrictedRoleNames';
import useCanSeeRestrictedVisibility, {
  useCanDeleteAllComments,
} from './useCanSeeRestrictedVisiblity';
import UserAvatar from './UserAvatar';

type Props = {
  write?: boolean;
  toggleWrite: () => void;
  reload: () => void;
  record?: CommentModel;
  urls: CommentsUrls;
  linkedEntityId: number;
  linkedEntity: EntityKey;
};

enum DIALOG_OPEN {
  NONE,
  DELETE,
}

const Comment: FC<Props> = ({
  write,
  toggleWrite,
  reload,
  record,
  urls,
  linkedEntityId,
  linkedEntity,
}) => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const [dialogOpen, setDialogOpen] = useState<DIALOG_OPEN>(DIALOG_OPEN.NONE);
  const dispatch = useDispatch();
  const isAdding = !record;
  const canSeeRestrictedVisibility = useCanSeeRestrictedVisibility(linkedEntity);

  const deleteAllComments = useCanDeleteAllComments(linkedEntity);

  const currentUser = useAppSelector(({ core }) => core.user);
  const deleteAllowed = record && (currentUser?.id === record.createdBy || deleteAllComments);
  const editAllowed = record && currentUser?.id === record.createdBy;
  const actionsAllowed = deleteAllowed || editAllowed;

  const dispatchDelete = useCallback(
    () =>
      record &&
      dispatch(
        deleteDialogEntity(urls.commentsDetail(linkedEntityId, record.id), () => {
          reload();
        })
      ),
    [dispatch, record, linkedEntityId, urls, reload]
  );

  return (
    <CommentContainer>
      <FullRowCell>
        <FlexRow>
          <AlignTopFlexCell>
            {currentUser && (
              <UserAvatar
                name={record?.creatorName || currentUser?.fullName}
                storageKey={record ? record.creatorStorageKey : currentUser.avatarStorageKey}
                usersId={record ? record?.createdBy : currentUser.id}
              />
            )}
          </AlignTopFlexCell>
          <FlexCell block flex={1}>
            <HeaderWrapper>
              {record && (
                <PersonalInfo>
                  {!isAdding && <h3>{record?.creatorName}</h3>}

                  <InformationsWrapper>
                    <span>{formatDateTime(record.createdAt, 'DATETIME_SHORT')}</span>
                    {record.modifiedAt && (
                      <Tooltip content={formatDateTime(record.modifiedAt, 'DATETIME_SHORT')}>
                        <InlineIconText>{t('Edited')}</InlineIconText>
                      </Tooltip>
                    )}

                    {record && canSeeRestrictedVisibility && (
                      <RestrictedRoleNames
                        urls={urls}
                        restrictedRoleIds={record?.restrictToRoles || []}
                      />
                    )}
                  </InformationsWrapper>
                </PersonalInfo>
              )}

              {!write && actionsAllowed && (
                <ActionsWrapper>
                  <ContextMenu
                    menuItems={[
                      ...(editAllowed
                        ? [{ text: t('Edit'), key: 'edit', onClick: toggleWrite }]
                        : []),
                      ...(deleteAllowed
                        ? [
                            {
                              text: t('Delete'),
                              key: 'delete',
                              onClick: () => setDialogOpen(DIALOG_OPEN.DELETE),
                            },
                          ]
                        : []),
                    ]}
                    menuId={`comment-actions-${record?.id || getFakeId()}`}
                  >
                    <IconButton icon="more_horiz" tooltip={t('More')} />
                  </ContextMenu>
                </ActionsWrapper>
              )}
            </HeaderWrapper>

            {write && (
              <CommentForm
                linkedEntityId={linkedEntityId}
                linkedEntity={linkedEntity}
                reload={reload}
                toggleWrite={toggleWrite}
                urls={urls}
                record={record}
              />
            )}

            {!write && <Message text={record?.text || ''} />}
          </FlexCell>
        </FlexRow>
      </FullRowCell>

      <Modal
        ariaLabel={t('Deletion confirmation dialog')}
        title={t('Delete Comment')}
        open={dialogOpen === DIALOG_OPEN.DELETE}
        onClose={() => setDialogOpen(DIALOG_OPEN.NONE)}
        onConfirm={dispatchDelete}
        onCancel={() => setDialogOpen(DIALOG_OPEN.NONE)}
      >
        {t('Are you sure you want to permanently delete this Comment?')}
      </Modal>
    </CommentContainer>
  );
};

export default memo(Comment);
