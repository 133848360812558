import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { entities } from 'app/entity';
import theme from 'app/theme';
import { useIcons } from 'common/columns/useAthleteColumn';
import { AlertMessage } from 'core/components/Alert';
import { FlexColumn, FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';
import Spacer from 'core/components/Spacer';
import Text from 'core/components/Text';
import Avatar from 'core/containers/Avatar';
import { BadgeCellRenderer } from 'core/containers/DataGrid/FastCellRenderers';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import { getDisabilityName } from 'personnel/containers/AthletesPage/disabilityName';
import { BLACKLISTS_TYPE } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/BlacklistsModal';
import useTeamBlacklists from 'personnel/hooks/useTeamBlacklists';
import Athlete from 'personnel/models/Athlete';
import BlacklistAthletes from 'personnel/models/BlacklistAthletes';
import EntityBlacklistedMessage from 'planning/components/EntityBlacklistedMessage';

const HeaderItem = ({ title, value }: { title: string; value?: string | null }) => (
  <FlexRow spacing=".10rem">
    <Text $fontSize=".9rem" $color={theme.color.textSecondary} $marginRight=".25rem">
      {title}:
    </Text>
    <Text $fontSize=".9rem" $color={theme.color.textSecondary} $fontWeight="600">
      {value ? value : '-'}
    </Text>
  </FlexRow>
);

export const Header = ({ athleteDetail }: { athleteDetail: Athlete }) => {
  const icons = useIcons();
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const disabilityName = getDisabilityName(athleteDetail.disabilitiesId);

  const athleteAlertMessage: AlertMessage = useMemo(
    () => ({
      value: t('You have blacklisted the selected Athlete.'),
    }),
    [t]
  );

  const blacklist = useTeamBlacklists(BLACKLISTS_TYPE.ATHLETES, athleteDetail?.id, {
    id: 'athlete',
    message: athleteAlertMessage,
  }) as BlacklistAthletes[];

  const disabilityText = useMemo(() => {
    const text = [];

    if (athleteDetail?.disabilitiesId) {
      text.push(disabilityName);
    }

    if (athleteDetail?.disabilityComment) {
      text.push(athleteDetail.disabilityComment);
    }

    return text.length > 0 ? text.join(', ') : '-';
  }, [disabilityName, athleteDetail]);

  return (
    <>
      <FlexRow verticalAlign="start">
        <Avatar
          edit={false}
          size="big"
          endpoint={entities.user.api().avatar(athleteDetail?.id || 1)}
          title={athleteDetail?.fullName}
          withTooltip={false}
          noMargin
        />
        <FlexColumn verticalAlign="right">
          <Spacer $marginLeft="1.5rem">
            <Text $fontSize="1.12rem">
              <FlexRow>
                {athleteDetail?.dateOfBirth ? (
                  <>
                    {icons(
                      athleteDetail.gender,
                      DateTime.fromJSDate(athleteDetail.dateOfBirth) || DateTime.now(),
                      DateTime.now()
                    )}
                    <Spacer $marginRight="0.3rem" />
                  </>
                ) : null}
                {athleteDetail.fullName}
              </FlexRow>
            </Text>
            {athleteDetail.mastersId !== null ? (
              <Spacer $marginBottom=".6rem">
                <BadgeCellRenderer color={theme.color['gray300']} title={t('Revision')} />
              </Spacer>
            ) : null}
            <HeaderItem title={t('ADAMS ID')} value={athleteDetail?.adamsId} />
            <HeaderItem
              title={t('DoB')}
              value={
                athleteDetail?.dateOfBirth
                  ? formatDateTime(athleteDetail?.dateOfBirth, 'DATE_SHORT')
                  : '-'
              }
            />
            <HeaderItem
              title={t('Nationality')}
              value={
                // @ts-ignore
                athleteDetail?.nationality?.nationality
                  ? // @ts-ignore
                    athleteDetail.nationality.nationality
                  : '-'
              }
            />
            <HeaderItem title={t('Impairment')} value={disabilityText} />
          </Spacer>
        </FlexColumn>
        <Link
          to={entities.athlete.urls().detail(athleteDetail?.id)}
          target="_blank"
          rel="noreferrer"
        >
          <MaterialIcon icon="launch" />
        </Link>
      </FlexRow>
      <EntityBlacklistedMessage entityName={t('Athlete')} blacklists={blacklist} />
    </>
  );
};
