import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import { SimpleOption } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import FormChipsField from 'core/components/FormChipsField';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import StyledButtonWrapper from 'core/components/StyledButtonWrapper';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import File from 'core/models/FileAttachment';

import { RECIPIENTS, EmailType, RecipientParams } from '..';
import { getRecipientRequiredParamName } from '../helpers';
import useRecipientOptionHelper from '../useRecipientOptionHelper';
import useSchema, { MAX_EMAILS_VALIDATION, FormData } from '../useSchema';
export interface Props {
  onClose: () => void;
  saveInProgress: boolean;
  emailTypes: EmailType[];
  recipientParams?: RecipientParams;
}

const Fieldset: FC<Props> = ({ onClose, saveInProgress, emailTypes, recipientParams }) => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const { values, setValues } = useFormikContext<FormData>();
  const { emailType, withRecipients, recipients } = values;
  const schema = useSchema();
  // TODO: Remove this helper from FE Core: https://cannypack.atlassian.net/browse/MODFE-1780
  const recipientOptionHelper = useRecipientOptionHelper();

  const emailTypeOptions = useMemo(
    () => emailTypes.map((it) => ({ id: it.code, name: it.title })),
    [emailTypes]
  );

  const choosedEmailTypesSettings: EmailType | undefined = useMemo(() => {
    return emailTypes?.find((it) => it.code === emailType);
  }, [emailType, emailTypes]);

  const recipientsOptions: SimpleOption[] = useMemo(() => {
    if (!choosedEmailTypesSettings) {
      return [];
    }

    return [
      ...(choosedEmailTypesSettings.customRecipients || []).map((it) => {
        // validate here if recipient has required params in props
        const requiredParamName = getRecipientRequiredParamName(it);
        const requiredParamsObject = recipientParams && recipientParams.get(it);
        if (
          requiredParamName &&
          (!requiredParamsObject || !requiredParamsObject.hasOwnProperty(requiredParamName))
        ) {
          throw new Error(`Email dialog must contains required params: ${requiredParamName}`);
        }
        return recipientOptionHelper(it);
      }),
      ...(choosedEmailTypesSettings.otherEmailsDisabled
        ? []
        : [recipientOptionHelper(RECIPIENTS.OTHER)]),
    ];
  }, [choosedEmailTypesSettings, recipientOptionHelper, recipientParams]);

  const attachmentsReducer = useCallback(
    (list: File[]) => {
      return list.map((item) => {
        return {
          id: item.id,
          name: item.metadata.fileName,
          secondary: `${item.fileTypeName} (${formatDateTime(
            DateTime.fromJSDate(item.createdAt!),
            'DATETIME_SHORT_WITH_SECONDS'
          )})`,
        };
      });
    },
    [formatDateTime]
  );

  const withOtherEmails = useMemo(() => {
    return recipients && recipients.includes(RECIPIENTS.OTHER);
  }, [recipients]);

  return (
    <Row>
      <FullRowCell>
        <Field
          viewMode={emailTypeOptions.length === 1}
          name="emailType"
          options={emailTypeOptions}
          component={FormDropDown}
          label={t('Email Type')}
          fast={false}
          single
          onChange={(newEmailType: string) => {
            const choosedEmailType = emailTypes.find((it) => it.code === newEmailType);

            setValues(
              schema.cast({
                emailType: newEmailType,
                withRecipients: Boolean(
                  choosedEmailType?.customRecipients?.length ||
                    !choosedEmailType?.otherEmailsDisabled
                ),
              })
            );
          }}
        />
      </FullRowCell>

      {choosedEmailTypesSettings && (
        <>
          {withRecipients && (
            <>
              <FullRowCell>
                <Field
                  options={recipientsOptions}
                  label={t('Recipients') + ' *'}
                  component={FormDropDown}
                  name="recipients"
                  fast={false}
                />
              </FullRowCell>

              {withOtherEmails && (
                <FullRowCell>
                  <Field
                    helperText={t(
                      'Enter up to {{max}}, to confirm single value use Enter button. You can also paste multiple emails from clipboard delimited by empty space',
                      {
                        max: MAX_EMAILS_VALIDATION,
                      }
                    )}
                    label={t('Other Recipients Email Address(es)') + ' *'}
                    component={FormChipsField}
                    name="emails"
                    fast={false}
                  />
                </FullRowCell>
              )}
            </>
          )}

          {choosedEmailTypesSettings.attachmentsEndpoint && (
            <FullRowCell>
              <Field
                name={`filesId`}
                component={ResourceFormDropdown}
                resource={choosedEmailTypesSettings.attachmentsEndpoint}
                label={t('Attachments')}
                fast={false}
                resourceReducer={attachmentsReducer}
              />
            </FullRowCell>
          )}

          {choosedEmailTypesSettings.withAdditionalMessage && (
            <FullRowCell>
              <Field
                label={t('Additional message')}
                component={FormTextfield}
                name="customText"
                fast={false}
                textarea
                rows={4}
              />
            </FullRowCell>
          )}
        </>
      )}

      <FullRowCell>
        <StyledButtonWrapper>
          <div />
          <div>
            <Button disabled={saveInProgress} onClick={onClose} type="button" text={t('Cancel')} />
            <Button disabled={saveInProgress} type="submit" text={t('Send')} />
          </div>
        </StyledButtonWrapper>
      </FullRowCell>
    </Row>
  );
};

export default Fieldset;
