import * as Sentry from '@sentry/browser';
import { ColDef } from 'ag-grid-community';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DropDown from 'core/components/DropDown';
import { HalfRowCell } from 'core/components/GridCell';
import Switch from 'core/components/Switch';

import { ModalSubtitle } from '../../../styled';

export type ColumnOperations = {
  hide?: boolean;
  width?: number;
  pinned?: 'left' | 'right' | null;
  position?: number;
  duplicity?: boolean;
};

export type CustomColumnConfiguration = {
  [colId: string]: ColumnOperations;
};

interface Props {
  customSettings?: ReactNode;
  columns: ColDef<any>[];
  visibleColumns: string[];
  onVisibleColumnsChange: (visibleColumns: string[]) => void;
  highlightColumnName?: string;
  highlightDuplicate: boolean;
  setHighlightDuplicate: (value: boolean) => void;
}

const Columns: FC<Props> = ({
  columns,
  visibleColumns,
  customSettings,
  highlightColumnName,
  highlightDuplicate,
  setHighlightDuplicate,
  onVisibleColumnsChange,
}) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      columns
        .filter((it) => !!it.field && !it.lockPinned)
        .map((c) => ({
          id: c.field as string,
          name: c.headerName as string,
        })),
    [columns]
  );

  return (
    <>
      <HalfRowCell>
        <ModalSubtitle bottomMargin>{t('Visible columns')}</ModalSubtitle>
        <DropDown
          onChange={(ids) => onVisibleColumnsChange(ids as string[])}
          options={options}
          value={visibleColumns}
          mode="inline"
          searching={false}
          height={window.innerHeight}
        />
      </HalfRowCell>

      <HalfRowCell>
        {customSettings}

        {/* Render only if at least one setting is available */}
        {highlightColumnName ? (
          <>
            <ModalSubtitle bottomMargin>{t('Additional functionality')}</ModalSubtitle>
            <Switch
              id="gridSettingHighlight"
              name="gridSettingHighlight"
              label={t('Highlight rows with duplicate {{column}}.', {
                column: highlightColumnName,
              })}
              // Pass and set value
              onChange={setHighlightDuplicate}
              value={highlightDuplicate}
            />
          </>
        ) : null}
      </HalfRowCell>
    </>
  );
};

export function customizeUsingConfiguration<E>(
  columnDefs: ColDef<E>[],
  columnConfiguration?: CustomColumnConfiguration
): ColDef<E>[] {
  if (!columnConfiguration) {
    return columnDefs;
  }

  // Since we start with column definition, we won't include columns we have removed in some revision
  return (
    columnDefs.map((col, index) => {
      const field = col.field || col.colId;
      if (!field) {
        Sentry.withScope((scope) => {
          scope.setExtra('column', col);
          Sentry.captureMessage('Column without ID found.', Sentry.Severity.Warning);
        });
        return [col, index];
      }
      const colConf = columnConfiguration[field as keyof typeof columnConfiguration];
      if (!colConf) return [col, index];

      const settings = [
        {
          ...col,
          hide: 'hide' in colConf ? colConf.hide : col.hide,
          width: 'width' in colConf ? colConf.width : col.width,
          pinned: 'pinned' in colConf ? colConf.pinned : col.pinned || null,
          suppressSizeToFit: 'width' in colConf,
        } as ColDef<any>,
        'position' in colConf && typeof colConf.position === 'number' ? colConf.position : index,
      ];

      return settings;
    }) as [ColDef<E>, number][]
  )
    .sort(([, a], [, b]) => (a as number) - (b as number))
    .map(([col, _]) => col as ColDef<E>);
}

export default Columns;
