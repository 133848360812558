import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { AccreditationsRole } from 'app/models/Accreditation';
import { MainControl } from 'core/components/PageHeader/Navigation';
import { ChildrenProps } from 'core/containers/FormDetailPage';
import useWatchersAction from 'core/containers/Watchers/useWatchersAction';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useSetModal } from 'core/hooks/useModal';
import { useGetPermission } from 'core/hooks/usePermission';
import { InvitationModal } from 'planning/components/InvitationStatementModal';
import Mission from 'planning/models/Mission';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import { MissionModal } from './useMissionModal';
import { MissionRequestModel } from './useMissionOutputMapping';
import { MissionFormData } from './useMissionSchema';

export default function useGetMainControls() {
  const advancedAssignmentEnabled = useBoolClientOption('enableAdvancedDcoAssignment');
  const myId = useAppSelector((s) => s.core.user!.id);
  const setModal = useSetModal();
  const { t } = useTranslation();
  const hasPermission = useGetPermission();
  const watchersIcon = useWatchersAction('mission');

  return useCallback(
    (props: ChildrenProps<Mission, MissionFormData, MissionRequestModel>): MainControl[] => {
      const { entityData, entitySaveInProgress, mode } = props;

      const hasAssignedDcos = !!(entityData?.teamMembers || []).find(
        (it) =>
          it.status === TeamMemberStatus.CONFIRMED &&
          (it.roles || []).includes(AccreditationsRole.DCO)
      );

      const isEdit = mode === 'edit';

      const controls: MainControl[] = [
        {
          permission: 'missions:patch',
          hidden:
            !hasPermission('missions:patch[scope:all]') &&
            !hasPermission('missions:patch[actions:statement'),
          form: 'detailForm',
          text: entitySaveInProgress ? t('Saving...') : t('Save'),
          type: 'submit',
          icon: 'save',
          key: 'save',
          disabled: entitySaveInProgress,
        },
      ];

      if (!isEdit || !entityData) {
        return controls;
      }
      controls.push({
        permission: 'tests:create',
        text: t('Create new test(s)'),
        items: [
          {
            key: 'new-single-test',
            icon: 'add',
            text: t('Single Test'),
            to: entities.test.urls().createFromMission(entityData.id),
          },
          'SEPARATOR',
          {
            key: 'new-athlete',
            icon: 'add',
            text: t('By Athlete'),
            onClick: () => setModal(MissionModal.BULK_CREATE),
          },
          {
            key: 'new-gender',
            icon: 'add',
            text: t('By Gender'),
            onClick: () => setModal(MissionModal.CREATE_TEST_BY_GENDER),
          },
        ],
        icon: 'add',
        key: 'new',
      });

      if (entityData && hasAssignedDcos) {
        controls.push({
          permission: 'missions:patch[scope:all]',
          key: 'outboundCommunication',
          icon: 'alternate_email',
          text: t('Outbound Communication'),
          onClick: () => setModal(MissionModal.EMAILS),
        });
      }

      if (
        advancedAssignmentEnabled &&
        entityData?.teamMembers?.some(
          (m) => m.usersId === myId && m.status === TeamMemberStatus.AWAITING
        )
      ) {
        controls.push({
          permission: 'missions:patch[actions:statement]',
          key: 'accept',
          type: 'button',
          icon: 'check_circle_outline',
          text: t('Accept'),
          onClick: () => setModal(InvitationModal.ACCEPT),
        });
        controls.push({
          permission: 'missions:patch[actions:statement]',
          key: 'decline',
          type: 'button',
          icon: 'cancel_circle',
          text: t('Decline'),
          onClick: () => setModal(InvitationModal.DECLINE),
        });
      }

      controls.push(watchersIcon);

      return controls;
    },
    [advancedAssignmentEnabled, hasPermission, myId, setModal, t, watchersIcon]
  );
}
