import getResourcesUrl from 'core/functions/getResourcesUrl';
import Base from 'core/models/Base';
import { definition, getStandardApi, getStandardUrls } from 'core/module';

import CostDetail, { CostGrid } from './models/Cost';
import { DatagridDCOInvoice, DCOInvoice, DCOInvoiceInput } from './models/DCOInvoice';
import { DatagridPaymentPlan, PaymentPlan, PaymentPlanInput } from './models/PaymentPlan';

type T = (m: string) => string;

export const financeEntities = {
  cost: {
    ...definition<CostGrid, CostDetail>('costs', {
      name: (t: T) => t('Cost'),
      listName: (t: T) => t('Costs'),
    }),
    urls: () => ({
      ...getStandardUrls('costs'),
      createFromMission: (id: number) => getResourcesUrl('/costs/create', { missionsId: id }),
      createFromTests: (ids: Array<number>) => getResourcesUrl('/costs/create', { testIds: ids }),
    }),
    api: () => ({
      ...getStandardApi('costs'),
      import: (id: number | string) => `/costs/import/${id}`,
    }),
  },
  dcoInvoice: {
    ...definition<DatagridDCOInvoice, DCOInvoice, DCOInvoiceInput>('dco-invoices', {
      name: (t: T) => t('DCO Invoice'),
      listName: (t: T) => t('DCO Invoices'),
      permission: 'dcoInvoices',
    }),
    urls: (usersId: number | string) => ({
      ...getStandardUrls(`dco-invoices/${usersId}`),
      list: '/dco-Invoices',
    }),
    api: () => ({
      ...getStandardApi('dco-invoices'),
      bulkDownload: '/dco-invoices/files/bulkDownload',
      downloadPdf: (id: number) => `/dco-invoices/${id}/pdf`,
    }),
  },
  paymentPlan: {
    ...definition<DatagridPaymentPlan, PaymentPlan, PaymentPlanInput>('payment-plans', {
      name: (t: T) => t('Payment Plan'),
      listName: (t: T) => t('Payment Plans'),
      permission: 'paymentPlans',
    }),
    api: () => ({
      ...getStandardApi('payment-plans'),
      usersList: (paymentPlansId?: number | string) =>
        paymentPlansId ? `/payment-plans/${paymentPlansId}/users` : `/payment-plans/users`,
      usersBulk: (paymentPlansId: number | string) => `/payment-plans/${paymentPlansId}/users/bulk`,
      userDetail: (paymentPlansId: number | string, id: number | string) =>
        `/payment-plans/${paymentPlansId}/users/${id}`,
    }),
  },
  attachment: definition<Base>('attachments', {
    name: (t: T) => t('Attachment'),
    listName: (t: T) => t('Attachments'),
  }),
} as const;

export type K = keyof typeof financeEntities;
