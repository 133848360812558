import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import { FilterValue } from 'core/containers/DataGrid';
import useUrlMultiState from 'core/hooks/useUrlMultiState';
import { UserView } from 'core/models/UserView';

import { COLUMNS_DEFAULT_VALUE, UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import { getUrlConfiguration } from '../../hooks/useDefaultViewConfiguration';
import { VIEW_URL_KEYS } from '../CustomizeViews/Header';

import { BadgeWrapper, ToolbarBadge, Wrapper } from './styled';

interface Props {
  onApplyView: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void;
  userViews: UserView[];
}

const FavoriteViews: FC<Props> = ({ onApplyView, userViews }) => {
  const { t } = useTranslation();

  const { urlStatesValues: viewConfiguration } = useUrlMultiState(
    VIEW_URL_KEYS,
    COLUMNS_DEFAULT_VALUE
  );

  const favoriteViews = useMemo(() => {
    const favorites = (userViews || [])
      .filter((it) => it.favorite || it.default)
      .sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (a.default) {
          return -1;
        }
        if (b.default) {
          return 1;
        }

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      .map((it) => ({
        id: it.id,
        default: it.default,
        name: it.default ? t('Default') : it.name,
        onClick: () => {
          const { filters, ...rest } = it.data || {};
          onApplyView({ ...filters }, getUrlConfiguration(rest, it.id));
        },
      }));

    if (!favorites.length || !favorites[0].default) {
      favorites.unshift({
        id: -1,
        default: true,
        name: t('Default'),
        onClick: () => {
          // useDefaultViewConfiguration will fill URL with GRID defaults, when there is empty hide prop
          onApplyView({}, { hide: {}, activeView: undefined });
        },
      });
    }

    return favorites;
  }, [userViews, onApplyView, t]);

  return (
    <Wrapper>
      <BadgeWrapper data-cy="favorite-views">
        {favoriteViews.map((view) => {
          let isActive =
            (view.default && !viewConfiguration?.activeView) ||
            (viewConfiguration?.activeView &&
              Number(viewConfiguration.activeView) === Number(view.id));

          return (
            <ToolbarBadge
              key={view.id}
              onClick={view.onClick}
              variant={isActive ? 'primary' : 'gray100'}
              color={isActive ? undefined : theme.color.baseFontColor}
            >
              {view.name}
            </ToolbarBadge>
          );
        })}
      </BadgeWrapper>
    </Wrapper>
  );
};

export default FavoriteViews;
