import { setIn } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import FormFieldset from 'core/components/Form/FormFieldset';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import FormSwitch from 'core/components/FormSwitch';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import IconButton from 'core/components/IconButton';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { useResources } from 'core/hooks/useResource';
import { useLaboratoryDropdownReducer } from 'lists/containers/LaboratoriesPage/useLaboratoryDropdownReducer';
import Analysis from 'lists/models/Analysis';
import { URINE_ANALYSES_URL } from 'planning/resources/analyses';

import { AnalysesFieldsData } from '../useAnalysesFieldsSchema';

import OtherUrineRow from './OtherUrineRow';
import UrineSamples from './UrineSamples';

interface Props {
  hideNonAnalysesFields?: boolean;
  onRequestEditAnalyses?: () => void;
}

const UrineFields: FC<Props> = ({ hideNonAnalysesFields, onRequestEditAnalyses }) => {
  const { t } = useTranslation();
  const labsReducer = useLaboratoryDropdownReducer();

  // TODO: improve support in useIncludeOptions urineAnalysessId => urineAnalyses
  const urineAnalysesInclude = useIncludeOptions('urineAnalysessId') as Analysis[];

  const { data: urineAnalyses, reload: loadAnalyses } = useResources<Analysis>(URINE_ANALYSES_URL, {
    autoload: false,
  });

  const chosenUrineAnalyses: number[] = useFormFieldValue('urineAnalyses');

  const { otherUrineAnalysesOptions, urineAnalysesOptions } = useMemo(() => {
    const analyses: Analysis[] = [];
    const otherUrineAnalyses: Analysis[] = [];

    (urineAnalyses?.length ? urineAnalyses : urineAnalysesInclude || []).forEach((it) => {
      if (it.code.startsWith('ADT')) {
        otherUrineAnalyses.push(it);
      } else {
        analyses.push(it);
      }
    });

    return {
      otherUrineAnalysesOptions: otherUrineAnalyses.map(({ id, shortName: name }) => ({
        id,
        name,
      })) as Option[],
      urineAnalysesOptions: analyses
        .filter((it) => (it.isSelectable && it.active) || chosenUrineAnalyses.includes(it.id))
        .map(({ id, shortName: name, fullName: secondary }) => ({ id, name, secondary }) as Option)
        .concat(['SEPARATOR' as Option, { id: 'other', name: t('Other') }]) as Option[],
    };
  }, [urineAnalyses, t, chosenUrineAnalyses, urineAnalysesInclude]);

  const decorateValues = useCallback(
    (name: keyof AnalysesFieldsData | undefined, value: any, values: AnalysesFieldsData) => {
      if (name === 'urineAnalyses') {
        const next = new Set(value || []);
        const prev = new Set((values.urineAnalyses as any[]) || []);
        if (prev.has('other') && !next.has('other')) {
          values.otherUrineAnalyses = [];
        } else if (!prev.has('other') && next.has('other') && otherUrineAnalysesOptions[0]) {
          // @ts-ignore
          values.otherUrineAnalyses = [{ id: otherUrineAnalysesOptions[0].id, remarks: null }];
        }
      }

      if (name) {
        return setIn(values, name, value);
      }

      return values;
    },
    [otherUrineAnalysesOptions]
  );

  return (
    <FormFieldset<AnalysesFieldsData> onChange={decorateValues}>
      <Row>
        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Field
            endAdornment={
              onRequestEditAnalyses && (
                <IconButton onClick={onRequestEditAnalyses} type="button" icon="edit" />
              )
            }
            options={urineAnalysesOptions}
            label={t('Urine Analyses')}
            component={FormDropDown}
            name="urineAnalyses"
            sortOptions={false}
            id="urineAnalyses"
            fast={false}
            onOpen={() => {
              if (!urineAnalyses?.length) {
                loadAnalyses();
              }
            }}
          />
        </Cell>

        <OtherUrineRow options={otherUrineAnalysesOptions} name="otherUrineAnalyses" />

        <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
          <Field
            dummyPlaceholder={UrineSamples}
            component={UrineSamples}
            name="urineAnalyses"
            diffMode={false}
          />
        </Cell>

        {!hideNonAnalysesFields && (
          <>
            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                component={ResourceFormDropdown}
                label={t('Laboratory')}
                name="urineLabsId"
                id="urineLabsId"
                resource="labs"
                single
                fast
                resourceReducer={labsReducer}
              />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                component={FormSwitch}
                label={t('Expedited Analysis')}
                name="urineExpedited"
                id="urineExpedited"
                fast
              />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                component={FormSwitch}
                label={t('Long-Term Storage')}
                name="urineLts"
                id="urineLts"
                fast
              />
            </Cell>

            <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
              <Field
                label={t('Analysis Remarks')}
                component={FormTextfield}
                name="urineComments"
                textarea
              />
            </Cell>
          </>
        )}
      </Row>
    </FormFieldset>
  );
};

export default UrineFields;
