import { useTranslation } from 'react-i18next';

import { DataCardItem } from '../props';

import { CheckboxIconButton } from './styled';
import { BulkActionsLogic } from './useBulkActions';

interface Props<Item extends DataCardItem> {
  id?: number | string;
  logic: BulkActionsLogic<Item>;
}

const Checkbox = <Item extends DataCardItem>({ id, logic }: Props<Item>) => {
  const { t } = useTranslation();
  const icon = logic.getIcon(id);

  return (
    <CheckboxIconButton
      role="checkbox"
      aria-checked={icon === 'indeterminate_check_box' ? 'mixed' : icon === 'check_box'}
      aria-label={id ? t('Toggle selection of row') : t('Toggle selection of all items')}
      icon={logic.getIcon(id)}
      onClick={() => (id ? logic.onCheckboxClick(id) : logic.onHeaderCheckboxClick())}
    />
  );
};

export default Checkbox;
