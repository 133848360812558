import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import { apiCall, beginEditEntity, reloadApiQuery, loadEntitySuccess } from 'core/actions';
import { finishEditEntity } from 'core/actions';
import createDiff from 'core/effects/apiCall/createDiff';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { stringify } from 'core/functions/qs';
import showUpdatedRecordsMessage from 'core/functions/showUpdatedRecordsMessage';
import { t } from 'core/i18n';
import {
  LoadMissionAction,
  TYPE_LOAD_MISSION,
  EditMissionAction,
  TYPE_EDIT_MISSION,
  BulkEditMissionAction,
  TYPE_BULK_EDIT_MISSION,
  ApplyMissionToTestsAction,
  TYPE_APPLY_MISSION_TO_TESTS,
} from 'planning/actions';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';
import { DISALLOWED_TEST_CHANGE_FIELDS } from 'planning/containers/MissionDetailPage';
import Test from 'planning/models/Test';

export function* loadMissionSaga(action: LoadMissionAction) {
  yield put(apiCall(TYPE_LOAD_MISSION, 'GET', `/missions/${action.payload.id}`, {}));
}

export function* editMissionSaga(action: EditMissionAction) {
  const { prevData, formData, id, successCallback } = action.payload;

  yield put(beginEditEntity());

  yield put(
    apiCall(TYPE_EDIT_MISSION, 'PATCH', `/missions/${id}`, formData, {}, true, prevData, [])
  );

  const { success } = yield race({
    success: take(`${TYPE_EDIT_MISSION}_SUCCESS`),
    error: take(`${TYPE_EDIT_MISSION}_ERROR`),
  });

  yield put(finishEditEntity());

  if (success) {
    toast.success(t('Successfully edited'));
    yield put(loadEntitySuccess(success.payload.response, `/missions/${id}`));

    if (formData.assigneesId !== prevData.assigneesId) {
      // Refresh watchers
      yield put(reloadApiQuery(getResourcesUrl(`/missions/${id}/watchers`)));
    }

    if (successCallback) {
      // Invitation status cannot be copied
      const newData = {
        ...formData,
        teamMembers: formData.teamMembers?.map((member: TeamMemberData) => ({
          ...member,
          invitations: [],
        })),
      };
      const oldData = {
        ...prevData,
        teamMembers: prevData.teamMembers?.map((member: TeamMemberData) => ({
          ...member,
          invitations: [],
        })),
      };

      const diff = createDiff(newData, oldData, []);
      successCallback(diff);
    }
  }
}

export function* bulkEditMission(action: BulkEditMissionAction) {
  const {
    selection: params,
    formData,
    initials,
    outputMapping,
    customOperations,
    successCallback,
  } = action.payload;

  const skipOperations = (customOperations || []).filter((it) => it.operation === 'skip');

  if (skipOperations?.length) {
    params.patchOpSkip = skipOperations.map((it) => it.fieldName);
  }

  yield put(
    apiCall(
      TYPE_BULK_EDIT_MISSION,
      'PATCH',
      `/missions/bulk`,
      outputMapping(formData),
      {
        params,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      },
      true,
      outputMapping({
        ...initials,
      }),
      [],
      true,
      customOperations
    )
  );
  const { success } = yield race({
    success: take(`${TYPE_BULK_EDIT_MISSION}_SUCCESS`),
    error: take(`${TYPE_BULK_EDIT_MISSION}_ERROR`),
  });

  if (success) {
    showUpdatedRecordsMessage(success.payload.response);

    yield put({
      type: `${TYPE_BULK_EDIT_MISSION}_SUCCESS`,
      payload: success.payload,
    });
    successCallback && successCallback();
  }
}

export function* applyMissionToTestsSaga(action: ApplyMissionToTestsAction) {
  const { missionsId, customOperations } = action.payload;

  const endpoint = `/missions/tests?${stringify({
    missionsId,
  })}`;
  yield put(apiCall('load_tests_for_mission', 'GET', endpoint, {}));

  const { success, error } = yield race({
    success: take(`load_tests_for_mission_SUCCESS`),
    error: take(`load_tests_for_mission_ERROR`),
  });

  if (success) {
    const tests: Test[] = success.payload.response.data;

    if (!tests.length) {
      toast.info(t("Mission doesn't have assigned tests"));
      return;
    }

    const prevDateFrom = action.payload.prevData.dateFrom as unknown as string;
    const newDateFrom = action.payload.newData.dateFrom as unknown as string;
    const newPublishAt = DateTime.fromISO(newDateFrom, { setZone: true }).minus({ day: 1 });

    const next = {
      ...action.payload.newData,
      publishAt:
        newDateFrom !== prevDateFrom ? transformDateToString(newPublishAt, 'DATETIME') : null,
      ...Object.fromEntries(DISALLOWED_TEST_CHANGE_FIELDS.map((it) => [it, undefined])),
    };

    const prev = {
      ...action.payload.prevData,
      publishAt: null,
      ...Object.fromEntries(DISALLOWED_TEST_CHANGE_FIELDS.map((it) => [it, undefined])),
    };

    // if there is no federation => ignore this change bcs tests has required federation but missions not
    // https://cannypack.atlassian.net/browse/MODFE-734
    if (!next.federationsId && !next.federationsName) {
      // @ts-ignore
      delete next.federationsId;
      // @ts-ignore
      delete next.federationsName;
      // @ts-ignore
      delete prev.federationsId;
      // @ts-ignore
      delete prev.federationsName;
    }

    const params: { id: number[]; patchOpSkip?: string[] } = {
      id: tests.map((i) => i.id),
    };

    const skipOperations = (customOperations || []).filter((it) => it.operation === 'skip');

    if (skipOperations?.length) {
      params.patchOpSkip = skipOperations.map((it) => it.fieldName);
    }

    yield put(
      apiCall(
        TYPE_APPLY_MISSION_TO_TESTS,
        'PATCH',
        `/tests/bulk/`,
        next,
        {
          params,
          paramsSerializer: (params) =>
            stringify(params, {
              encodeValuesOnly: true,
            }),
        },
        true,
        prev,
        []
      )
    );

    const { ok } = yield race({
      ok: take(`${TYPE_APPLY_MISSION_TO_TESTS}_SUCCESS`),
      error: take(`${TYPE_APPLY_MISSION_TO_TESTS}_ERROR`),
    });

    if (ok) {
      toast.success(
        t('{{count}} associated test(s) were updated successfully', {
          count: tests.length,
        })
      );
    }
  } else if (error) {
    toast.error(t('Loading associated tests failed'));
  }

  action.payload.onSettled?.();
}
