import { ColDef } from 'ag-grid-community';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from 'core/components/Form';
import { Row } from 'core/components/Grid';
import { CustomFilter, Filter, FilterValue } from 'core/containers/DataGrid';

import { UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import useDatagridUserViews from '../../hooks/useDatagridUserViews';

import Header from './Header';
import { NoPaddingGrid, StyledModal } from './styled';
import Columns, { CustomColumnConfiguration } from './tabs/Columns';
import Details from './tabs/Details';
import Filters from './tabs/Filters';
import useDeleteView from './useDeleteView';
import useSaveView from './useSaveView';
import useSchema from './useSchema';
import { ViewTabs } from './useViewTabs';

interface Props {
  open: boolean;
  filters: { [id: string]: Filter };
  filterValues: { [key: string]: FilterValue };
  defaultFilterValues: { [key: string]: FilterValue };
  endpoint: string;
  onClose: () => void;
  onFiltersAndColumnsChange: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void;
  columnConfiguration: CustomColumnConfiguration;
  columnDefs: ColDef<any>[];
  highlightDuplicateRowsBy?: string;
  customSettings?: ReactNode;
  disableSettings: boolean;
  defaultTab?: ViewTabs;
  onOpenAllViews: () => void;
  viewConfiguration: UrlColumnConfiguration;
}

const CustomizeViews: FC<Props> = ({
  open,
  filters,
  filterValues: committedFilterValues,
  defaultFilterValues,
  endpoint,
  onClose,
  onFiltersAndColumnsChange,
  columnConfiguration,
  columnDefs,
  highlightDuplicateRowsBy,
  customSettings,
  disableSettings,
  defaultTab,
  onOpenAllViews,
  viewConfiguration,
}) => {
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState(committedFilterValues);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<ViewTabs>(ViewTabs.FILTERS);

  useEffect(() => {
    if (defaultTab) {
      setActiveTab(defaultTab);
    }
  }, [defaultTab]);

  const columns: ColDef<any>[] = useMemo(
    () => columnDefs.filter((c) => !!c.field && !c.lockPinned), // We use lockPinned for indicating not allowed fields for advanced functionality
    [columnDefs]
  );

  const hideColumns: { [colId: string]: boolean } = useMemo(
    () =>
      Object.fromEntries(
        columnDefs
          .filter((c) => !!c.field && !c.lockPinned)
          .map((c) => [c.field, !visibleColumns.includes(c.field!)])
      ),
    [columnDefs, visibleColumns]
  );

  const getDefaultVisibleColumns: () => string[] = useCallback(
    () =>
      columns
        .filter((c) => {
          const colConf = columnConfiguration[c.field as string];
          return colConf && colConf.hide !== undefined ? colConf.hide !== true : c.hide !== true;
        })
        .map((c) => c.field as string),
    [columns, columnConfiguration]
  );

  useEffect(() => setVisibleColumns(getDefaultVisibleColumns()), [getDefaultVisibleColumns]);

  const [highlightDuplicate, setHighlightDuplicate] = useState<boolean>(
    viewConfiguration?.duplicity || false
  );

  const highlightColumnName = useMemo(() => {
    const column = columnDefs.find((c) => highlightDuplicateRowsBy === c.field);
    return column ? column.headerName : undefined;
  }, [highlightDuplicateRowsBy, columnDefs]);

  useEffect(() => {
    setHighlightDuplicate(!!viewConfiguration?.duplicity);
  }, [viewConfiguration?.duplicity]);

  useEffect(() => {
    // Make sure changes from parent are also reflected in filters
    setFilterValues(committedFilterValues);
  }, [committedFilterValues]);

  const handleFilterValueChange = useCallback(
    (id: string, val: FilterValue, autoCommit = false) => {
      if (val === undefined) throw new Error('Filter value cannot be undefined');
      const newValues = { ...filterValues, [id]: val };
      setFilterValues(newValues);
      autoCommit && onFiltersAndColumnsChange(newValues, hideColumns);
    },
    [onFiltersAndColumnsChange, filterValues, hideColumns]
  );

  const filterList = useMemo(() => Object.keys(filters), [filters]);

  const configuredFilters = useMemo(
    () => (filterList ? new Set(filterList) : undefined),
    [filterList]
  );
  const [userViews, reloadUserViews] = useDatagridUserViews(endpoint, configuredFilters);

  const activeView = useMemo(() => {
    return userViews.find(
      (view) =>
        viewConfiguration?.activeView && Number(viewConfiguration.activeView) === Number(view.id)
    );
  }, [userViews, viewConfiguration.activeView]);

  const onConfirm = useCallback(
    () =>
      onFiltersAndColumnsChange(Object.keys(filterValues).length === 0 ? undefined : filterValues, {
        ...(viewConfiguration || {}),
        hide: hideColumns,
        duplicity: highlightDuplicate,
      }),
    [filterValues, onFiltersAndColumnsChange, highlightDuplicate, hideColumns, viewConfiguration]
  );

  const handleClose = useCallback(() => {
    setActiveTab(ViewTabs.FILTERS);
    onClose();
  }, [onClose]);

  const onApplyView = useCallback(
    (
      filters: { [key: string]: FilterValue } | undefined,
      viewConfiguration: UrlColumnConfiguration
    ) => {
      onFiltersAndColumnsChange(filters, viewConfiguration);
    },
    [onFiltersAndColumnsChange]
  );

  const schema = useSchema();
  const initialValues = schema.cast(activeView ? activeView : {}, { stripUnknown: true });
  const onSave = useSaveView(endpoint, onApplyView, userViews, reloadUserViews, () => onClose());
  const onDelete = useDeleteView(onApplyView, userViews, reloadUserViews);

  const onClone = useCallback(() => {
    if (activeView) {
      onApplyView(filterValues || {}, { activeView: undefined });
    }
  }, [activeView, onApplyView, filterValues]);

  const customFilters = Object.entries(filters).filter(
    ([, filter]) => filter.type === 'custom'
  ) as Array<[string, CustomFilter]>;

  const customFilterElements = customFilters.map(
    ([id, filter]) =>
      'component' in filter &&
      filter.component(
        (value: FilterValue) => handleFilterValueChange(id, value, filter.autoCommit),
        filterValues[id] !== undefined ? filterValues[id] : defaultFilterValues[id]
      )
  );

  return (
    <>
      <StyledModal
        ariaLabel={t('Grid views dialog')}
        open={open}
        onClose={handleClose}
        onClear={
          activeTab === ViewTabs.DETAILS
            ? undefined
            : () => {
                if (activeView) {
                  const { filters, hide } = activeView.data || {};
                  setFilterValues({ ...filters });
                  setVisibleColumns([
                    ...(hide
                      ? Object.keys(hide).filter((columnKey) => !hide[columnKey])
                      : getDefaultVisibleColumns()),
                  ]);
                } else {
                  setFilterValues({ ...defaultFilterValues });
                  setVisibleColumns(getDefaultVisibleColumns());
                }
              }
        }
        onCancel={() => {
          setFilterValues(committedFilterValues);
          setVisibleColumns(getDefaultVisibleColumns());
        }}
        onConfirm={onConfirm}
        confirmButton={activeTab === ViewTabs.DETAILS ? null : undefined}
        autoResponsiveWidth={false}
        mountOnEnter={false}
        unmountOnExit={false}
      >
        <NoPaddingGrid>
          <Header
            activeTab={activeTab}
            activeView={activeView || null}
            setActiveTab={setActiveTab}
            disableSettings={disableSettings}
            onDelete={onDelete}
            onClone={onClone}
            reloadUserViews={reloadUserViews}
            onOpenAllViews={onOpenAllViews}
          />

          <Form
            id="viewForm"
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => onSave(values, hideColumns, filterValues)}
            enableReinitialize
          >
            <Row>
              {activeTab === ViewTabs.FILTERS && (
                <Filters
                  defaultFilterValues={defaultFilterValues}
                  filterValues={filterValues}
                  filters={filters}
                  onFilterValueChange={handleFilterValueChange}
                  isModalOpen={open}
                  onConfirm={onConfirm}
                />
              )}
              {activeTab === ViewTabs.GRID_SETTINGS && !disableSettings && (
                <Columns
                  highlightDuplicate={highlightDuplicate}
                  highlightColumnName={highlightColumnName}
                  setHighlightDuplicate={(value) => setHighlightDuplicate(value)}
                  customSettings={customSettings}
                  columns={columns}
                  visibleColumns={visibleColumns}
                  onVisibleColumnsChange={(columns) => setVisibleColumns(columns)}
                />
              )}
              {activeTab === ViewTabs.DETAILS && <Details activeView={activeView || null} />}
            </Row>
          </Form>
        </NoPaddingGrid>
      </StyledModal>

      {customFilterElements}
    </>
  );
};

export default CustomizeViews;
