import { FC, useCallback, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Glyph from 'app/assets/icons/Glyph';
import Logo from 'app/assets/icons/Logo';
import { loginAsUser, logout } from 'core/actions';
import ContextMenu from 'core/components/ContextMenu';
import IconButton from 'core/components/IconButton';
import Tooltip from 'core/components/Tooltip';
import WarnIcon from 'core/components/WarnIcon';
import getEnv from 'core/functions/getEnv';
import useAppSelector from 'core/hooks/useAppSelector';

import { Wrap, LogoWrap, StyledLink, StyledHeader } from './styled';

interface Props {
  link?: string;
  collapse: boolean;
}

const Header: FC<Props> = ({ link, collapse }) => {
  const { fullName, originUser } = useAppSelector((s) => s.core.user!);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const items: any[] = useMemo(
    () => [
      {
        text: t('Switch Back to {{name}}', { name: originUser?.fullName }),
        onClick: () => dispatch(loginAsUser(originUser?.id || 0)),
        key: 'switch_login_as_back',
        icon: 'supervisor_account',
      },
      { key: 'logout', text: t('Logout'), onClick: handleLogout, icon: 'logout' },
    ],
    [dispatch, handleLogout, originUser?.fullName, originUser?.id, t]
  );

  return (
    <StyledHeader>
      <Wrap collapse={collapse}>
        <Tooltip
          content={t('version {{version}}', { version: getEnv('VERSION') })}
          placement="right"
        >
          <StyledLink to={link || '/'} data-cy="logo">
            <LogoWrap>{collapse ? <Glyph /> : <Logo />}</LogoWrap>
          </StyledLink>
        </Tooltip>
      </Wrap>

      {!collapse && (
        <>
          {originUser ? (
            <ContextMenu menuItems={items} menuId="sidebarFooter">
              <IconButton
                tooltip={originUser ? t('You are logged as {{fullName}}', { fullName }) : undefined}
                badge={originUser && <WarnIcon icon="warning" />}
                icon="settings"
              />
            </ContextMenu>
          ) : (
            <IconButton icon="logout" tooltip={t('Logout')} onClick={handleLogout} />
          )}
        </>
      )}
    </StyledHeader>
  );
};

export default memo(Header);
