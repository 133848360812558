import { ApiCallSuccessAction, ApiCallErrorAction } from 'core/actions';

import { PlanningActions } from './actions';
import Mission from './models/Mission';
import Sample from './models/Sample';
import TeamMember from './models/TeamMember';
import { TestResult } from './models/TestResult';

export interface TestAssignmentDiff {
  teamMembers: TeamMember[];
}

export interface PlanningState {
  testResult?: { loading: boolean; resultId: number; data?: TestResult };
  missionBulkEditInProgress?: boolean;
  testBulkEditInProgress?: boolean;
  orderItemBulkEditInProgress?: boolean; // TODO: add one bulkEditInProgress field and use for tests, missions, orders
  missionDetail?: Mission;
  sampleDetail?: Sample;
  dialogSaveInProgress?: boolean;
  testAssignmentDiff?: TestAssignmentDiff;
}

const initialState: PlanningState = {
  missionDetail: undefined,
  sampleDetail: undefined,
  dialogSaveInProgress: false,
};

export default function reducer(
  state = initialState,
  action:
    | PlanningActions
    | ApiCallSuccessAction<'LOAD_MISSION_SUCCESS'>
    | ApiCallErrorAction<'LOAD_MISSION_ERROR'>
    | ApiCallSuccessAction<'LOAD_ROLES_SUCCESS'>
    | ApiCallSuccessAction<'LOAD_SAMPLE_SUCCESS'>
    | ApiCallErrorAction<'LOAD_SAMPLE_ERROR'>
    | ApiCallSuccessAction<'CREATE_COC_SUCCESS'>
    | ApiCallErrorAction<'CREATE_COC_ERROR'>
    | ApiCallSuccessAction<'LOAD_COC_SUCCESS'>
    | ApiCallErrorAction<'LOAD_COC_ERROR'>
    | ApiCallSuccessAction<'BULK_EDIT_MISSION_SUCCESS'>
    | ApiCallErrorAction<'BULK_EDIT_MISSION_ERROR'>
    | ApiCallSuccessAction<'INDIVIDUAL_BULK_EDIT_TEST_SUCCESS'>
    | ApiCallErrorAction<'INDIVIDUAL_BULK_EDIT_TEST_ERROR'>
    | ApiCallSuccessAction<'BULK_EDIT_TEST_SUCCESS'>
    | ApiCallErrorAction<'BULK_EDIT_TEST_ERROR'>
    | ApiCallSuccessAction<'BULK_EDIT_ORDER_ITEM_SUCCESS'>
    | ApiCallErrorAction<'BULK_EDIT_ORDER_ITEM_ERROR'>
    | ApiCallSuccessAction<'APPLY_MISSION_TO_TESTS_SUCCESS'>
    | ApiCallErrorAction<'APPLY_MISSION_TO_TESTS_ERROR'>
    | ApiCallSuccessAction<'TEST_BULK_CREATE_SUCCESS'>
    | ApiCallErrorAction<'TEST_BULK_CREATE_ERROR'>
    | ApiCallSuccessAction<'ADD_MEMBER_STATEMENT_SUCCESS'>
    | ApiCallErrorAction<'ADD_MEMBER_STATEMENT_ERROR'>
    | ApiCallSuccessAction<'SAVE_TEST_ASSIGNMENT_DIFF_SUCCESS'>
    | ApiCallErrorAction<'SAVE_TEST_ASSIGNMENT_DIFF_ERROR'>
    | ApiCallSuccessAction<'ABORT_TEST_SUCCESS'>
    | ApiCallErrorAction<'ABORT_TEST_ERROR'>
): PlanningState {
  switch (action.type) {
    case 'LOAD_MISSION':
    case 'UNLOAD_MISSION':
      return {
        ...state,
        missionDetail: undefined,
      };

    case 'LOAD_MISSION_SUCCESS':
      return {
        ...state,
        missionDetail: action.payload.response.data,
      };

    case 'LOAD_SAMPLE':
    case 'UNLOAD_SAMPLE':
      return {
        ...state,
        sampleDetail: undefined,
      };
    case 'LOAD_SAMPLE_SUCCESS':
      return {
        ...state,
        sampleDetail: action.payload.response.data,
      };

    case 'TEST_ASSIGNMENT_CHANGE':
      return {
        ...state,
        testAssignmentDiff: action.payload.assignmentDiff,
      };

    case 'TEST_ASSIGNMENT_DIFF_RESOLVED':
      return {
        ...state,
        testAssignmentDiff: undefined,
      };

    case 'BULK_EDIT_MISSION':
      return {
        ...state,
        missionBulkEditInProgress: true,
      };

    case 'BULK_EDIT_MISSION_SUCCESS':
    case 'BULK_EDIT_MISSION_ERROR':
      return {
        ...state,
        missionBulkEditInProgress: undefined,
      };

    case 'BULK_EDIT_TEST':
    case 'INDIVIDUAL_BULK_EDIT_TEST':
      return {
        ...state,
        testBulkEditInProgress: true,
      };

    case 'BULK_EDIT_TEST_SUCCESS':
    case 'BULK_EDIT_TEST_ERROR':
    case 'INDIVIDUAL_BULK_EDIT_TEST_SUCCESS':
    case 'INDIVIDUAL_BULK_EDIT_TEST_ERROR':
      return {
        ...state,
        testBulkEditInProgress: undefined,
      };

    case 'BULK_EDIT_ORDER_ITEM':
      return {
        ...state,
        orderItemBulkEditInProgress: true,
      };

    case 'BULK_EDIT_ORDER_ITEM_SUCCESS':
    case 'BULK_EDIT_ORDER_ITEM_ERROR':
      return {
        ...state,
        orderItemBulkEditInProgress: undefined,
      };

    case 'APPLY_MISSION_TO_TESTS':
    case 'TEST_BULK_CREATE':
    case 'ADD_MEMBER_STATEMENT':
    case 'SAVE_TEST_ASSIGNMENT_DIFF':
    case 'ABORT_TEST':
      return {
        ...state,
        dialogSaveInProgress: true,
      };

    case `TEST_BULK_CREATE_SUCCESS`:
    case `TEST_BULK_CREATE_ERROR`:
    case `APPLY_MISSION_TO_TESTS_SUCCESS`:
    case `APPLY_MISSION_TO_TESTS_ERROR`:
    case 'ADD_MEMBER_STATEMENT_SUCCESS':
    case 'ADD_MEMBER_STATEMENT_ERROR':
    case 'SAVE_TEST_ASSIGNMENT_DIFF_SUCCESS':
    case 'SAVE_TEST_ASSIGNMENT_DIFF_ERROR':
    case 'ABORT_TEST_SUCCESS':
    case 'ABORT_TEST_ERROR':
      return {
        ...state,
        dialogSaveInProgress: false,
      };

    default:
      return state;
  }
}
