import { TeamMemberStatus } from 'planning/models/TeamMember';

export const TYPE_ADD_MEMBER_STATEMENT = 'ADD_MEMBER_STATEMENT';
export const addMemberStatement = (
  entity: 'missions' | 'tests',
  id: number,
  data: { status: TeamMemberStatus; statement: null | string }
) =>
  ({
    type: TYPE_ADD_MEMBER_STATEMENT,
    payload: {
      entity,
      id,
      data,
    },
  }) as const;
export type AddMemberStatement = ReturnType<typeof addMemberStatement>;

export type TeamMembersActions = AddMemberStatement;
