import { useMemo } from 'react';

import User from 'app/models/User';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import { stringify } from 'core/functions/qs';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useResources } from 'core/hooks/useResource';
import { useHumanReadableAvailability } from 'personnel/hooks/useHumanReadableAvailability';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';

const useUserAvailabilities = (userIds: number[]) => {
  const dateRange = useFormFieldValue('dateRange') as { from: Date; to: Date } | null;
  // Date range can apparently come form different sources
  const dateFrom: Date | undefined = (dateRange &&
    (Array.isArray(dateRange) ? dateRange[0] : dateRange.from)) as Date | undefined;
  const dateTo: Date | undefined = (dateRange &&
    (Array.isArray(dateRange) ? dateRange[1] : dateRange.to)) as Date | undefined;

  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');

  const userConditions =
    userIds.length > 0 && dateFrom && dateTo
      ? stringify({
          // Keep the list of ids same if nothing new was added
          // This will stop unnecessary reloading
          id: userIds,
          availableFrom: transformDateToString(dateFrom, 'DATE'),
          availableTo: transformDateToString(dateTo, 'DATE'),
        })
      : undefined;
  const { data: availabilityCategories } = useResources<AvailabilityCategory>(
    'profile/availabilities/categories',
    {
      autoload: availabilitiesEnabled,
    }
  );
  const { data: users } = useResources<User>(`users?${userConditions}`, {
    autoload: !!userConditions,
  });

  const getHumanReadableAvailability = useHumanReadableAvailability(availabilityCategories);
  const availabilities = useMemo(
    () =>
      users?.reduce(
        (acc, user) => {
          acc[user.id] = getHumanReadableAvailability(user.availabilities);

          return acc;
        },
        {} as { [usersId: number]: string }
      ) || {},
    [getHumanReadableAvailability, users]
  );

  return availabilities;
};

export default useUserAvailabilities;
