import { FC, memo } from 'react';

import Comments from 'core/containers/Comments';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';

const MissionCommentsTab: FC = () => {
  const { id } = useDetail();

  if (!id) {
    return null;
  }
  return <Comments entity="mission" entityId={id!} />;
};

export default memo(MissionCommentsTab);
