import styled from 'styled-components/macro';

import theme from 'app/theme';
import Modal from 'core/components/Modal';

export const StyledModal = styled(Modal)`
  padding: 0;

  &[data-reach-dialog-overlay] > div[role='dialog'] {
    max-height: 100%;
    max-width: 700px;

    ${theme.mq.tablet} {
      width: 48rem;
    }

    @media (max-width: ${theme.breakpoints.mobile + 'rem'}) {
      width: 100%;

      & > div:first-of-type {
        padding: 0;
      }
    }
  }
`;
