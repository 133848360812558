import { ColDef, ICellRendererParams, RowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import theme from 'app/theme';
import { favoriteView } from 'core/actions';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import MaterialIcon from 'core/components/MaterialIcon';
import Modal from 'core/components/Modal';
import useAppSelector from 'core/hooks/useAppSelector';
import { getCustomUserAvatarUrl } from 'core/models/CoreProfile';
import { UserView } from 'core/models/UserView';

import IconButtonCellRenderer from '../../CellRenderers/IconButtonCellRenderer';
import RowActionsCellRenderer from '../../CellRenderers/RowActionsCellRenderer';
import EmptyOverlay from '../../EmptyOverlay';
import { AvatarCellRenderer, BadgeCellRenderer } from '../../FastCellRenderers';
import { UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import { getUrlConfiguration } from '../../hooks/useDefaultViewConfiguration';
import { FilterValue, RowAction } from '../../props';
import { Title } from '../CustomizeViews/Header/styled';
import { NoPaddingGrid } from '../CustomizeViews/styled';
import useDeleteView from '../CustomizeViews/useDeleteView';
import { ToolbarButton } from '../styled';

import { ButtonsWrapper, SpaceLeft, StyledAgGridWrapper, StyledModal } from './styled';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  userViews: UserView[];
  reloadUserViews: () => void;
  onApplyView: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void;
  onOpenEditView: () => void;
  viewConfiguration: UrlColumnConfiguration;
}

const ListViews: FC<Props> = ({
  userViews,
  reloadUserViews,
  onApplyView,
  onOpenEditView,
  open,
  setOpen,
  viewConfiguration,
}) => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState<number | null>(null);
  const dispatch = useDispatch();
  const onDelete = useDeleteView(onApplyView, userViews, reloadUserViews);
  const usersId = useAppSelector((s) => s.core.user!.id);

  const moreActions: RowAction<UserView>[] = useMemo(() => {
    return [
      {
        key: 'editView',
        text: () => t('Edit'),
        onClick: (view: UserView) => {
          const { filters, ...rest } = view.data || {};
          onApplyView({ ...filters }, getUrlConfiguration(rest, view.id));
          onOpenEditView();
        },
      },
      {
        key: 'deleteView',
        onClick: (view: UserView) => setConfirmDelete(view.id),
        disabled: (view: UserView) => usersId !== view.createdBy,
        text: () => t('Delete'),
      },
    ];
  }, [t, usersId, onApplyView, onOpenEditView]);

  const applyView = useCallback(
    (view: UserView) => {
      const { filters, ...rest } = view.data || {};
      onApplyView({ ...filters }, getUrlConfiguration(rest, view.id));
      setOpen(false);
    },
    [onApplyView, setOpen]
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: t('Owner'),
        field: 'ownerName',
        width: 60,
        minWidth: 60,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: 'avatarCellRenderer',
        cellStyle: { cursor: 'pointer' },
        cellRendererParams: {
          title: (view: UserView) => view.ownerName,
          containsImg: (view: UserView) => !!view.avatarStorageKey,
          url: (view: UserView) => getCustomUserAvatarUrl(view.createdBy, view.avatarStorageKey),
        },
        onCellClicked: ({ data }: { data: UserView }) => {
          applyView(data);
        },
      },
      {
        headerName: t('Name'),
        field: 'name',
        sort: 'asc',
        cellStyle: { cursor: 'pointer' },
        cellRenderer: (params: ICellRendererParams) => {
          const data: UserView = params.data;
          const isActive = data.id === Number(viewConfiguration.activeView);
          return data?.default || isActive ? (
            <>
              {data.name}
              {isActive && (
                <SpaceLeft>
                  <BadgeCellRenderer color={theme.color.primary} title={t('Active')} />
                </SpaceLeft>
              )}
              {!!data?.default && (
                <SpaceLeft>
                  <BadgeCellRenderer color={theme.color.gray300} title={t('Default')} />
                </SpaceLeft>
              )}
            </>
          ) : (
            data.name
          );
        },
        onCellClicked: ({ data }: { data: UserView }) => {
          applyView(data);
        },
      },
      {
        headerName: t('Favorite'),
        sortable: false,
        headerValueGetter: () => '',
        field: 'createdBy',
        minWidth: 64,
        maxWidth: 64,
        suppressSizeToFit: true,
        cellRenderer: 'iconButtonCellRenderer',
        cellStyle: { cursor: 'pointer' },
        cellRendererParams: {
          tooltip: t('Favorite'),
          icon: (view: UserView) => (view.favorite ? 'star' : 'star_outline'),
          onClick: (view: UserView) => {
            dispatch(favoriteView(view.id, !Boolean(view.favorite), () => reloadUserViews()));
          },
          disabled: (view: UserView) => !!view.default,
        },
      },
      {
        headerName: t('More'),
        sortable: false,
        headerValueGetter: () => '',
        field: 'createdBy',
        minWidth: 64,
        maxWidth: 64,
        cellStyle: { cursor: 'pointer' },
        cellClass: 'rowActions',
        cellRenderer: (row: RowNode) => (
          <RowActionsCellRenderer
            rowId={row.id || row.rowIndex || 'row'}
            data={row.data}
            rowActions={moreActions}
            setConfirmationModal={setConfirmDelete}
          />
        ),
        suppressSizeToFit: true,
      },
    ],
    [t, dispatch, reloadUserViews, moreActions, applyView, viewConfiguration.activeView]
  );

  return (
    <>
      <ButtonsWrapper>
        <ToolbarButton
          onClick={() => setOpen(true)}
          type="button"
          text={t('Views')}
          icon={<MaterialIcon icon="storage" />}
        />
      </ButtonsWrapper>

      <StyledModal
        ariaLabel={t('List views dialog')}
        open={open}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        confirmButton={null}
        autoResponsiveWidth={false}
      >
        <NoPaddingGrid>
          <Row>
            <FullRowCell>
              <Title>{t('Saved Views')}</Title>
            </FullRowCell>

            <FullRowCell>
              <StyledAgGridWrapper fullHeight>
                {userViews && userViews.length ? (
                  <AgGridReact
                    domLayout="autoHeight"
                    defaultColDef={{ sortable: true }}
                    columnDefs={columnDefs}
                    suppressHorizontalScroll
                    suppressNoRowsOverlay
                    rowData={userViews || []}
                    onGridReady={({ api }) => {
                      api.sizeColumnsToFit();
                    }}
                    components={{
                      iconButtonCellRenderer: IconButtonCellRenderer,
                      avatarCellRenderer: AvatarCellRenderer,
                    }}
                    enableCellTextSelection
                    ensureDomOrder
                  />
                ) : (
                  <EmptyOverlay
                    title={t('There are no items yet')}
                    subTitle={t('Create new views')}
                    padding="0 3rem 3rem 3rem"
                  />
                )}
              </StyledAgGridWrapper>
            </FullRowCell>
          </Row>
        </NoPaddingGrid>
      </StyledModal>

      <Modal
        ariaLabel={t('Deletion Confirmation Dialog')}
        onClose={() => setConfirmDelete(null)}
        title={t('Delete Selected View')}
        onConfirm={() => confirmDelete && onDelete(confirmDelete)}
        open={!!confirmDelete}
      >
        {t('Are you sure you want to permanently delete this View?')}
      </Modal>
    </>
  );
};

export default ListViews;
