interface EnvVariables {
  API_URL: string;
  SOCKET_URL: string;
  VERSION: string;
  NAME: string;
  SENTRY_DSN: string;
  LOGROCKET_ID: string;
}

// These values come from the bundle - the value is substituted during the build
const bundledEnv = process.env;

// These envs can't be overridden as we would make way too easy for attackers during XSS
const protectedEnvs: Set<keyof EnvVariables> = new Set([
  'API_URL',
  'SENTRY_DSN',
  'LOGROCKET_ID',
] as const);

function getEnv<T extends keyof EnvVariables>(name: T): EnvVariables[T] | undefined {
  const key = `REACT_APP_${name}`;

  // We support overrides on the window object to allow for overrides e.g. via cypress
  const override = (window as any).env?.[key];
  if (override && !(name in protectedEnvs)) {
    return override;
  }

  return bundledEnv[key];
}

export default getEnv;
