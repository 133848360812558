import { ICellRendererParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';
import Text from 'core/components/Text';
import PopoverCellRenderer from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer';
import { ColumnDef } from 'core/containers/DataGrid/props';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import { Gender, GenderEnum } from 'core/models/CoreUser';
import { useNotAdultIcon } from 'personnel/containers/AthletesPage/useNotAdultIcon';

type OptionMapperType = {
  gender?: string;
  dateOfBirth?: string;
  name?: string;
  id?: string;
  dateFrom?: string;
};

const optionMapperDefaultValues = {
  name: 'athleteName',
  id: 'athletesId',
  gender: 'athleteGender',
  dateOfBirth: 'athleteDateOfBirth',
  dateFrom: 'dateFrom',
} as const;

export const useAthleteColumn = () => {
  const { t } = useTranslation();
  const getAthletePopoverContent = useGetAthletePopoverContent();
  const getIcons = useIcons();
  const getAthleteName = useAthleteName();

  return useCallback(
    (
      name: string = t('Athlete'),
      field: string = 'athleteName',
      dataKeys?: OptionMapperType,
      noPopover: boolean = false
    ): ColumnDef => {
      const keys = { ...optionMapperDefaultValues, ...dataKeys };

      return {
        headerName: name,
        field: field,
        sortable: true,
        resizable: true,
        minWidth: 170,

        cellRenderer: (params: ICellRendererParams) => {
          const name = params.data[keys.name] || getAthleteName(params);
          const when = params.data[keys.dateFrom]
            ? DateTime.fromJSDate(params.data[keys.dateFrom])
            : undefined;
          const age = params.data[keys.dateOfBirth]
            ? DateTime.fromJSDate(params.data[keys.dateOfBirth])
            : undefined;

          const cellContent = name ? (
            <FlexRow>
              {getIcons(params.data[keys.gender], age, when)}
              <Text>{name}</Text>
            </FlexRow>
          ) : undefined;

          if (noPopover) return <FlexRow>{cellContent}</FlexRow>;

          const options = {
            title: params.data[keys.name],
            link: params.data[keys.id] ? `/athletes/${params.data[keys.id]}/edit` : '',
            items: getAthletePopoverContent(params),
          };

          return <PopoverCellRenderer options={options}>{cellContent}</PopoverCellRenderer>;
        },
      };
    },
    [t, getAthletePopoverContent, getIcons, getAthleteName]
  );
};

const useGetAthletePopoverContent = () => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const genderMap = useMemo(
    () => ({
      m: t('Male'),
      f: t('Female'),
      x: t('Other'),
    }),
    [t]
  );

  const fields = useCallback(
    (params: ICellRendererParams) => [
      {
        label: t('Gender'),
        value: genderMap[params.data.athleteGender as keyof typeof genderMap],
      },
      {
        label: t('Date of Birth'),
        value: params.data?.athleteDateOfBirth
          ? formatDateTime(params.data?.athleteDateOfBirth, 'DATE_SHORT')
          : undefined,
      },
      {
        label: t('Nationality'),
        value: params.data?.athleteNationality,
      },
      {
        label: t('External ID'),
        value: params.data?.athletesExternalId,
      },
      {
        label: t('ADAMS ID'),
        value: params.data?.athletesAdamsId,
      },
      {
        label: t('Internal comments'),
        value: params.data?.athletesInternalComments,
      },
    ],
    [t, genderMap, formatDateTime]
  );

  return fields;
};

const useAthleteName = () => {
  const { t } = useTranslation();

  return useCallback(
    (params: ICellRendererParams) => {
      if (params.data.athleteName) {
        return params.data.athleteName as string;
      } else if (params.data.race && params.data.place) {
        return `${params.data.race}/${params.data.place}`;
      }
      return t('Unknown') as string;
    },
    [t]
  );
};

export const useIcons = () => {
  const getNotAdultIcon = useNotAdultIcon();

  return useCallback(
    (gender?: Gender, dateOfBirth?: DateTime, when: DateTime = DateTime.now()) => {
      const icons = [];

      if (gender === GenderEnum.MALE) {
        icons.push(<MaterialIcon icon="male" size={1} key="male" />);
      }

      if (gender === GenderEnum.FEMALE) {
        icons.push(<MaterialIcon icon="female" size={1} key="female" />);
      }

      if (gender === GenderEnum.OTHER) {
        icons.push(<MaterialIcon icon="gender" size={1} key="gender" />);
      }

      const notAdultIcon = getNotAdultIcon(dateOfBirth, when);
      if (notAdultIcon) {
        icons.push(notAdultIcon);
      }

      return icons;
    },
    [getNotAdultIcon]
  );
};
