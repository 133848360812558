import { FC, Fragment, ReactNode, memo } from 'react';
import { Helmet } from 'react-helmet-async';

import Header from 'core/components/PageHeader';
import { MainControl, SecondaryControl } from 'core/components/PageHeader/Navigation';
import getEnv from 'core/functions/getEnv';
import { useLocale } from 'core/i18n/useLocale';

import ErrorBoundary from '../ErrorBoundary';

import { PageBody, PageContent } from './styled';

interface Props {
  backControl?: { to: string; text: string };
  secondaryControls?: SecondaryControl[];
  mainControls?: MainControl[];
  hideTitle?: boolean;
  title?: ReactNode;
  documentTitle?: string;
  children?: ReactNode;
}

const Page: FC<Props> = ({ children, hideTitle, title, documentTitle, ...rest }) => {
  const locale = useLocale();

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{ lang: locale }}
        title={`${documentTitle || title} | ${getEnv('NAME')}`}
      />
      <PageContent data-cy="page" data-title={title} id="page-content">
        <Header title={hideTitle ? undefined : title} {...rest} />
        <PageBody>
          <ErrorBoundary>{children}</ErrorBoundary>
        </PageBody>
      </PageContent>
    </Fragment>
  );
};

export default memo(Page);
