import { getIn, FieldProps } from 'formik';
import { FC } from 'react';

import IconButton from 'core/components/IconButton';

import { AnalysesFieldsData } from '../useAnalysesFieldsSchema';

const RemoveButton: FC<FieldProps<AnalysesFieldsData['otherBloodAnalyses'][0]>> = ({
  field,
  form,
}) => {
  const filledOther = (getIn(form.values, 'otherBloodAnalyses') ||
    []) as AnalysesFieldsData['otherBloodAnalyses'];
  const id = field.value?.id;

  return (
    <IconButton
      type="button"
      icon="close"
      onClick={() => {
        form.setFieldValue(
          'otherBloodAnalyses',
          (filledOther || []).filter((analysis) => analysis.id !== id)
        );
      }}
    />
  );
};

export default RemoveButton;
