import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusColor } from 'app/theme';
import { DCOInvoiceStatus } from 'finance/models/DCOInvoice';

export const useGetInvoiceStatusColor = () =>
  useCallback((entity?: { status: DCOInvoiceStatus }): StatusColor => {
    switch (entity?.status) {
      case DCOInvoiceStatus.SUBMITTED:
        return 'cyan';

      case DCOInvoiceStatus.ACCEPTED:
        return 'green';

      case DCOInvoiceStatus.DECLINED:
        return 'red';

      case DCOInvoiceStatus.DRAFT:
        return 'orange';

      default:
        return 'gray';
    }
  }, []);

export const useGetInvoiceStatusName = () => {
  const { t } = useTranslation();

  return useCallback(
    (entity?: { status: DCOInvoiceStatus }) => {
      switch (entity?.status) {
        case DCOInvoiceStatus.SUBMITTED:
          return t('Submitted');

        case DCOInvoiceStatus.ACCEPTED:
          return t('Accepted');

        case DCOInvoiceStatus.DECLINED:
          return t('Declined');

        default:
          return t('Draft');
      }
    },
    [t]
  );
};
