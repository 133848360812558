import { SliderProps } from 'rc-slider/lib/Slider';
import { FC } from 'react';
import 'rc-slider/assets/index.css';

import MaterialIcon from 'core/components/MaterialIcon';

import {
  StyledSliderWrapper,
  StyledLeftIconWrapper,
  StyledRightIconWrapper,
  StyledSlider,
} from './styled';

interface OwnProps {
  leftIcon?: string;
  rightIcon?: string;
}

type Props = OwnProps & SliderProps<number>;

const Slider: FC<Props> = ({ leftIcon, rightIcon, ...rest }) => {
  return (
    <StyledSliderWrapper>
      {leftIcon && (
        <StyledLeftIconWrapper>
          <MaterialIcon icon={leftIcon} />
        </StyledLeftIconWrapper>
      )}
      <StyledSlider {...rest} />
      {rightIcon && (
        <StyledRightIconWrapper>
          <MaterialIcon icon={rightIcon} />
        </StyledRightIconWrapper>
      )}
    </StyledSliderWrapper>
  );
};

export default Slider;
