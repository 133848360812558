import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { getFileEntityKey, useFileEntityLookupByName } from 'app/hooks/useFileEntityLookups';
import DummyField from 'core/components/DummyField';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import getFormattedBytesResult from 'core/functions/getFormattedBytesResult';
import usePermission, { useGetPermission } from 'core/hooks/usePermission';
import FileAttachment from 'core/models/FileAttachment';

import { useCanRestrictVisibility } from '../AddFilesDialog/useRestrictVisibilityPermissions';
import { FormData } from '../AddFilesDialog/useSchema';
import RestrictedVisibility from '../Comments/Comment/CommentForm/RestrictedVisibility';

interface Props {
  downloadFile: (id: number, fileName: string) => void;
  fileDetail: FileAttachment;
  hideAssignTo?: boolean;
  unrestrictedRoleIds?: number[];
}

const Fieldset: FC<Props> = ({ downloadFile, fileDetail, hideAssignTo, unrestrictedRoleIds }) => {
  const { t } = useTranslation();
  const hasPermission = useGetPermission();
  const actualLookup = useFileEntityLookupByName(fileDetail.entityName);
  const entityKey = getFileEntityKey(fileDetail.entityName);
  const canRestrictVisibility = useCanRestrictVisibility(entityKey, 'patch');
  const canSeeRoles = usePermission('roles:find');
  const { setFieldValue, values } = useFormikContext<FormData>();

  const canSeeEntityDetail = useMemo(
    () => !!entityKey && hasPermission(`${entities[entityKey].permission}:get`),
    [entityKey, hasPermission]
  );

  const onRoleRestrictionChange = (newValue: number[] | null) =>
    setFieldValue('restrictToRoles', newValue);

  return (
    <>
      <FullRowCell>
        <DummyField
          label={getFormattedBytesResult(fileDetail.metadata.fileSize)}
          value={fileDetail.metadata.fileName}
          startIcon="insert_drive_file"
          reverseTitleOrder
          endAdornment={
            <IconButton
              type="button"
              tooltip={t('Download File')}
              onClick={() => downloadFile(fileDetail.id, fileDetail.metadata.fileName)}
              icon={'save_alt'}
            />
          }
        />
      </FullRowCell>

      <FullRowCell>
        <FlexRow>
          <FlexCell flex={1}>
            <DummyField
              startIcon="file_present"
              label={t('File Type')}
              value={fileDetail.fileTypeName}
            />
          </FlexCell>

          {canRestrictVisibility && canSeeRoles && (
            <FlexCell>
              <RestrictedVisibility
                restrictToRoles={(values.restrictToRoles || []) as number[]}
                unrestrictedRoleIds={unrestrictedRoleIds}
                onChangeRoles={onRoleRestrictionChange}
              />
            </FlexCell>
          )}
        </FlexRow>
      </FullRowCell>

      {!hideAssignTo && actualLookup && entityKey && (
        <FullRowCell>
          <DummyField
            label={t('Assigned To')}
            link={
              canSeeEntityDetail
                ? // bcs some urls requires parent ID so we have to extract those from type, or we have to believe that they will be never used https://cannypack.atlassian.net/browse/FEC-366
                  entities[entityKey]
                    .urls(fileDetail.relatedEntityId || '1')
                    .detail(fileDetail.entityId)
                : undefined
            }
            value={`${actualLookup.label} ${fileDetail.entityIdentifier || fileDetail.entityId}`}
            startIcon="list"
          />
        </FullRowCell>
      )}

      {fileDetail.creatorName && (
        <FullRowCell>
          <DummyField label={t('Creator')} value={fileDetail.creatorName} startIcon="person" />
        </FullRowCell>
      )}

      <FullRowCell>
        <Field
          component={FormTextfield}
          label={t('File Description')}
          name="description"
          textarea
          rows={5}
        />
      </FullRowCell>
    </>
  );
};

export default Fieldset;
