import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataCardAction, DataCardItem } from '../props';

export type BulkActionsLogic<Item extends DataCardItem> = {
  onCheckboxClick: (id: string | number) => void;
  onHeaderCheckboxClick: () => void;
  getIcon: (id?: string | number) => string;
  getSelectedItems: () => Item[];
};

const useBulkActions = <Item extends DataCardItem>(
  actions: DataCardAction<Item>[],
  items: Item[],
  onRemove?: (item: Item | Item[]) => void
) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<Set<string | number>>(new Set());
  useEffect(() => {
    // Remove items can't be selected
    setSelection((selection) => {
      const itemsSet = new Set(items.map((i) => i.id));
      const newSelection = new Set(selection);
      selection.forEach((selected) => !itemsSet.has(selected) && newSelection.delete(selected));

      return newSelection;
    });
  }, [items]);

  const bulkActions = useMemo(() => {
    const bulk = actions.filter((action) => typeof action.bulkIcon === 'string');

    // Remove is moved to actions if available
    if (bulk.length > 0 && onRemove) {
      bulk.push({
        id: 'delete',
        title: t('Remove'),
        onClick: onRemove,
        bulkIcon: 'close',
      });
    }

    if (bulk.length > 0) {
      return bulk;
    }
  }, [actions, onRemove, t]);
  const logic = useMemo<BulkActionsLogic<Item>>(
    () => ({
      onCheckboxClick: (id: string | number) => {
        setSelection((selection) => {
          const newSelection = new Set(selection);
          newSelection.delete(id) || newSelection.add(id);
          return newSelection;
        });
      },
      onHeaderCheckboxClick: () => {
        setSelection((selection) =>
          selection.size === 0 ? new Set(items.map((i) => i.id)) : new Set()
        );
      },
      getIcon: (id?: string | number) => {
        if (id) {
          return selection.has(id) ? 'check_box' : 'check_box_outline_blank';
        } else {
          return selection.size === items.length
            ? 'check_box'
            : selection.size === 0
              ? 'check_box_outline_blank'
              : 'indeterminate_check_box';
        }
      },
      getSelectedItems: () => items.filter((i) => selection.has(i.id)),
    }),
    [items, selection]
  );

  return [bulkActions, selection, logic] as const;
};

export default useBulkActions;
