import styled from 'styled-components/macro';

import { invisiblePrint } from 'core/components/PrintOnly/styled';

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :nth-child(2) {
    margin-right: -0.5rem;
  }
`;

export const FilterButton = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  ${invisiblePrint}
`;
