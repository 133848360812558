import styled from 'styled-components/macro';

import Badge from 'core/components/Badge';

export const ToolbarBadge = styled(Badge)`
  margin-right: 0.438rem;
  padding: 0.438rem 0.625rem;
  cursor: pointer;
  text-transform: capitalize;
`;

export const Wrapper = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  display: flex;
  width: 100%;
`;

export const BadgeWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  margin: auto;
  margin-left: 0.438rem;
  flex: 1;
`;
