import BulkActionsHeader from './BulkActions';
import useBulkActions from './BulkActions/useBulkActions';
import FieldContent from './FieldContent';
import Props, { DataCardItem } from './props';
import { Card, ExtrasContainer, Footer, HeaderContainer, TopSpacing } from './styled';
import TableContent from './TableContent';

const DataCard = <Item extends DataCardItem>(props: Props<Item>) => {
  const {
    type = 'field',
    title,
    actions = [],
    items,
    onRemove,
    header,
    offset,
    footer = null,
    headerIcons = null,
  } = props;
  const [bulkActions, selection, bulkLogic] = useBulkActions(actions, props.items, onRemove);
  const showHeader = Boolean(bulkActions?.length || !!headerIcons || header || title);

  return (
    <Card>
      {!showHeader && <TopSpacing />}

      {showHeader && (
        <HeaderContainer>
          <BulkActionsHeader
            title={title}
            displayCheckbox={!header && items.length > 0}
            actions={bulkActions}
            selection={selection}
            logic={bulkLogic}
          />
          {headerIcons && <ExtrasContainer>{headerIcons}</ExtrasContainer>}
        </HeaderContainer>
      )}

      {type === 'field' ? (
        <FieldContent
          {...props}
          bulkActions={bulkActions}
          bulkLogic={bulkLogic}
          selection={selection}
          offset={offset}
          items={items}
        />
      ) : (
        <TableContent
          {...props}
          bulkActions={bulkActions}
          bulkLogic={bulkLogic}
          selection={selection}
          offset={offset}
          items={items}
        />
      )}

      {footer && <Footer>{footer}</Footer>}
    </Card>
  );
};

export default DataCard;
