import { FC, MouseEventHandler } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

import {
  StyledIcon,
  StyledLink,
  StyledHref,
  StyledMenuText,
  StyledMenuItem,
  StyledMenuItemDisabled,
} from './styled';

interface Props {
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  active?: boolean;
  icon?: string;
  hoverIcon?: string;
  text: string;
  to?: string | { url: string; target: string };
  id?: string;
  title?: string;
}

const MenuItem: FC<Props> = ({
  id,
  title,
  disabled,
  onClick,
  onMouseDown,
  active,
  text,
  icon,
  hoverIcon,
  to,
}) => {
  const menuItemContent = (
    <>
      {icon && (
        <StyledIcon className="menuSubItemIcon">
          <MaterialIcon icon={icon} />
        </StyledIcon>
      )}
      {hoverIcon && !disabled && (
        <StyledIcon className="menuSubItemHoverIcon">
          <MaterialIcon icon={hoverIcon} />
        </StyledIcon>
      )}
      <StyledMenuText>{text}</StyledMenuText>
    </>
  );

  const el = disabled ? (
    <StyledMenuItemDisabled
      id={to ? undefined : id}
      role={to ? undefined : 'option'}
      aria-disabled="true"
      title={title}
    >
      {menuItemContent}
    </StyledMenuItemDisabled>
  ) : (
    <StyledMenuItem
      id={to ? undefined : id}
      role={to ? undefined : 'option'}
      active={active}
      onClick={onClick}
      onMouseDown={onMouseDown}
      title={title}
      hasHoverIcon={!!hoverIcon}
    >
      {menuItemContent}
    </StyledMenuItem>
  );

  if (to) {
    if (typeof to === 'object' && to !== null) {
      // If it's object
      if (to.url.startsWith('/')) {
        return (
          <StyledLink id={id} role="option" target={to.target} to={to.url} disabled={disabled}>
            {el}
          </StyledLink>
        );
      }
      return (
        <StyledHref href={to.url} target={to.target} disabled={disabled}>
          {el}
        </StyledHref>
      );
    }

    return (
      <StyledLink id={id} role="option" to={to} disabled={disabled}>
        {el}
      </StyledLink>
    );
  }

  return el;
};

export default MenuItem;
