import styled from 'styled-components/macro';

import theme from 'app/theme';

import { hidePrint } from '../PrintOnly/styled';

export const StyledContent = styled.div`
  box-shadow: 0 -0.3125rem 0.375rem 0.25rem var(--mdc-theme-secondary);
  background: ${theme.color.white};
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 2.8125rem;
  overflow: hidden;
  overflow-x: auto;
  padding: 1.5rem;
  flex-shrink: 0;
  display: flex;
  width: 100%;
  z-index: 99;
  position: sticky;
  top: 0px;

  ${hidePrint};
`;

export const MetaGroup = styled.div`
  flex-direction: row;
  margin-left: auto;
  display: flex;
  align-items: center;

  & > * {
    display: flex;
    align-items: center;
  }
`;

export const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Text = styled.div`
  color: var(--mdc-theme-secondary);
  font-size: 0.875rem;
`;

export const BadgeWrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
`;
